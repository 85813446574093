import React, { useEffect, useState, useReducer, useMemo } from 'react'
import {
  Toggle, Dropdown, Slider, Link, Icon,
  Button, Input, ButtonGroup,
  List,
  Checkbox,
} from '@storaensods/seeds-react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import _ from 'lodash'

import CVPApi from '../../../services/cvp'
import { AIModel, Dataset, DatasetStatus, Solution, SolutionStatus, SolutionType } from '../../../types'
import { Title, FileField, CreatableSelect, Table } from '../../../components'
import { MILLS } from '../../../config'
import UploadService from '../../../services/upload'
import moment from 'moment'

type Props = {
  register: any
  watch: any
  errors: any
  control: any
  setValue: any
  getValues: any
  solution: Solution
  selectedModel?: AIModel
}

enum DataSelectionMethod {
  LOCAL = 'local',
  IMAGE_BANK = 'imagebank',
  CVAT = 'cvat',
  FROM_BASED_MODEL = 'from_based_model',
}

export default (props: Props) => {
  const { register, watch, errors, control, solution, setValue, selectedModel, getValues } = props
  const [dataSelection, setDataSelection]: [DataSelectionMethod, any] = useState<DataSelectionMethod>(DataSelectionMethod.LOCAL)
  const [datasets, setDatasets]: [Dataset[], any] = useState([])
  const [selectedDataset, setSelectedDataset]: [Dataset | undefined, any] = useState<Dataset | undefined>(undefined)
  const [classIds, setClassIds]: [number[], any] = useState([0, 1])
  const classNameControls = useFieldArray({ control, name: 'classes' })
  const fileControls = useFieldArray({ control, name: 'files' })
  const [taskOptions] = useState(() => [
    { label: 'All tasks', value: 'all' },
    ...Object.values(SolutionType).map((type) => ({ label: type, value: type})),
  ])
  // const [statusOptions] = useState(() => ['status', ...Object.values(SolutionStatus)])
  const [millOptions] = useState(() => [
    { label: 'All mills', value: 'all' },
    ...Object.entries(MILLS).map(([value, label]) => ({ label, value })),
  ])
  const [imageBankFilter, setImageBankFilter]: [any, any] = useState({
    mill: millOptions[0].value,
    task: taskOptions[0].value,
  })

  const fetchDatasets = async () => {
    const ds = await CVPApi.fetchDatasets({
      params: {
        status: DatasetStatus.UPLOADED,
        ...(
          !CVPApi.isPlatformAdmin ? {} : {
            user_id: CVPApi.user_id,
          }
        ),
        ..._.pickBy(
          Object.assign({}, ...Object.keys(imageBankFilter).map((filterKey) =>({
            [filterKey]: imageBankFilter[filterKey] === 'all' ? undefined : imageBankFilter[filterKey]
          })))
        ),
      }
    })
    setSelectedDataset(undefined)
    setDatasets(ds)
  }
  useEffect(() => {
    fetchDatasets()
  }, [imageBankFilter])

  useEffect(() => {
    if (selectedModel?.data_path) {
      setDataSelection(DataSelectionMethod.FROM_BASED_MODEL)
    }
  }, [])

  return (
    <>
      <Controller
        as={
          <input type='hidden' />
        }
        defaultValue={solution?.id}
        rules={{ required: true }}
        name="solution_id"
        control={control}
      />
      <div className="row se-mt-2xl">
        <div className="col-md-12">
          <Title>
            <h6>Define datasets' structure:</h6>
          </Title>
          <p>Please define here the class to be classified in the solution.</p>
          <p>
            A minimum of 10 images are need each class to start the training. However, often the
            training will require many more images to provide good model.
          </p>
        </div>
      </div>

      <>
        {dataSelection}
        <div className="row se-mb-md">
          <div className="col-md-10">
            <div className="d-flex">
              <ButtonGroup>
                {
                  selectedModel?.data_path && (
                    <Button
                      isActive={dataSelection === DataSelectionMethod.FROM_BASED_MODEL}
                      onClick={() => setDataSelection(DataSelectionMethod.FROM_BASED_MODEL)}
                    >
                      Base dataset
                    </Button>
                  )
                }
                <Button
                  isActive={dataSelection === DataSelectionMethod.LOCAL}
                  onClick={() => setDataSelection(DataSelectionMethod.LOCAL)}
                >
                  My computer
                </Button>
                <Button
                  isActive={dataSelection === DataSelectionMethod.IMAGE_BANK}
                  onClick={() => {
                    fetchDatasets()
                    setDataSelection(DataSelectionMethod.IMAGE_BANK)
                    }}
                  >
                    Image bank
                  </Button>
                  {/* <Button
                    isActive={dataSelection === DataSelectionMethod.CVAT}
                    onClick={() => setDataSelection(DataSelectionMethod.CVAT)}
                  >
                    CVAT
                  </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </div>

          {
            dataSelection === DataSelectionMethod.FROM_BASED_MODEL && selectedModel?.data_path && (
              <div className="row se-mb-md">
                <div className="col-md-5">
                  <div className="d-flex">
                    <p className="align-self-center se-mb-0">Use original model's dataset</p>
                    <div style={{ display: 'none' }}>
                      <Controller
                        as={<Input value={selectedModel?.data_path} />}
                        // rules={{ required: true }}
                        defaultValue={selectedModel?.data_path}
                        name={`data_path`}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {
            <div style={{ display: dataSelection === DataSelectionMethod.LOCAL ? 'block' : 'none' }}>
              {[SolutionType.OBJECT_DETECTION, SolutionType.INSTANCE_SEGMENTATION, SolutionType.SEMANTIC_SEGMENTATION].indexOf(solution.type) >= 0 && (
                <>
                  <div className="row">
                    <div className="col-md-5">
                      <p className="align-self-center se-mb-md">Upload data from local computer</p>
                      <div className="se-input-container">
                        <Controller
                          as={
                            <input name='classes[0]' type='hidden' />
                          }
                          defaultValue='annotations'
                          // rules={{ required: true }}
                          name={`classes[0]`}
                          control={control}
                        />
                        <Controller
                          as={
                            <FileField
                              name={`files[0]`}
                              // ref={register({ required: true })}
                              // multiple
                              accept=".json"
                              valid={!errors?.annotations}
                              helpText={
                                errors?.annotations && errors.annotations.type === 'required'
                                  ? 'Please select annotation file (.json). This field is required'
                                  : 'Please select annotation file (.json)'
                              }
                            />
                          }
                          // rules={{ required: true }}
                          name={`files[0]`}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row se-mt-md">
                    <div className="col-md-5">
                      <div className="se-input-container">
                        <Controller
                          as={
                            <input name='classes[1]' type='hidden' value='images' />
                          }
                          defaultValue='images'
                          // rules={{ required: true }}
                          name={`classes[1]`}
                          control={control}
                        />
                        <Controller
                          as={
                            <FileField
                              name={`files[1]`}
                              // ref={register({ required: true })}
                              multiple
                              accept="image/*,.avi,.mp4"
                              valid={!errors?.images}
                              helpText={
                                errors?.images && errors.images.type === 'required'
                                  ? 'Please select images. This field is required'
                                  : 'Please select images'
                              }
                            />
                          }
                          // rules={{ required: true }}
                          name={`files[1]`}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {solution.type === SolutionType.CLASSIFICATION && (
                selectedModel && selectedModel.classes ?
                  (selectedModel && selectedModel?.classes || []).map((cls: string, index: number) => {
                    return (
                      <div key={`files-${index}-${dataSelection}`} className="row se-mb-md">
                        <div className="col-md-5">
                          <div className="se-input-container">
                            <div style={{ display: 'none' }}>
                              <Controller
                                as={<Input value={cls} />}
                                rules={{ required: true }}
                                defaultValue={cls}
                                name={`classes[${index}]`}
                                placeholder="Class name"
                                control={control}
                              />
                            </div>
                            <label htmlFor={`files${index}`} className="se-label se-label--md">
                              {cls}:{' '}
                            </label>
                            <Controller
                              as={
                                <FileField
                                  name={`files[${index}]`}
                                  // ref={register({ required: true })}
                                  multiple
                                  accept="image/*,.avi,.mp4"
                                  valid={!errors?.files}
                                  helpText={
                                    errors?.files && errors.files[index]?.type === 'required'
                                      ? 'This field is required'
                                      : ''
                                  }
                                />
                              }
                              key={`files-${index}-${dataSelection}`}
                              rules={{ required: dataSelection === DataSelectionMethod.LOCAL }}
                              name={`files[${index}]`}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :
                  <>
                    {classIds.map((id) => (
                      <div className="row se-mb-md" key={id}>
                        <div className="col-md-5">
                          <Controller
                            as={<Input />}
                            // rules={{ required: true }}
                            name={`classes[${id}]`}
                            placeholder="Class name"
                            invalid={errors?.classes ? true : undefined}
                            helpText={
                              errors?.classes && errors.classes[id]?.type === 'required'
                                ? 'This field is required'
                                : ''
                            }
                            control={control}
                          />
                        </div>
                        <div className="col-md-5">
                          <div className="se-input-container">
                            <Controller
                              as={
                                <FileField
                                  name={`files[${id}]`}
                                  // ref={register({ required: true })}
                                  multiple
                                  accept="image/*,.avi,.mp4"
                                  valid={!errors?.files}
                                  helpText={
                                    errors?.files && errors.files[id]?.type === 'required'
                                      ? 'This field is required'
                                      : ''
                                  }
                                />
                              }
                              // rules={{ required: true }}
                              name={`files[${id}]`}
                              control={control}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <Button
                            flat
                            type="negative"
                            // size="sm"
                            onClick={() => {
                              classNameControls.remove(id)
                              fileControls.remove(id)
                              setClassIds(classIds.filter((x) => x !== id))
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-md-5">
                        <Button
                          flat
                          // size="sm"
                          type="primary"
                          onClick={() => setClassIds([...classIds, Math.max(...classIds) + 1])}
                        >
                          Add class
                        </Button>
                      </div>
                    </div>
                  </>
              )}
            </div>
          }

          {
            dataSelection === DataSelectionMethod.IMAGE_BANK && (
              <div
                style={{
                  height: '400px',
                  overflow: 'scroll',
                }}
              >
                <div className="row se-pb-md">
                  <div className="col-md-12 col-sm-12">
                    <p className="align-self-center se-mb-0">CVP cloud data storage</p>
                  </div>
                </div>
                <div className="row se-pb-xl">
                  <div className="col-md-4 col-sm-12">
                    <Dropdown
                      options={taskOptions} defaultValue={taskOptions[0]}
                      name="task"
                      value={imageBankFilter.task}
                      onSelect={(val: any) => {
                        setImageBankFilter(
                          Object.assign({}, imageBankFilter, {
                            task: val.value
                          })
                        )
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <Dropdown
                      options={millOptions} defaultValue={millOptions[0]}
                      name="mill"
                      value={imageBankFilter.mill}
                      onSelect={(val: any) => {
                        setImageBankFilter(
                          Object.assign({}, imageBankFilter, {
                            mill: val.value
                          })
                        )
                      }}
                    />
                  </div>
                </div>
                {/* <input type='hidden' name='data_path' value={selectedDataset?.path} /> */}
                <Controller
                  as={
                    <input type='hidden' name='data_path' value={selectedDataset?.path} />
                  }
                  defaultValue={selectedDataset?.path}
                  value={selectedDataset?.path}
                  name="data_path"
                  control={control}
                />
                <Table
                  headers={[
                    ['', 10],
                    ['Description', 150],
                    ['Solution', 150],
                    ['Task', 100],
                    ['Mill', 100],
                    ['Owner', 100],
                    ['Created At', 150],
                  ]}
                  dataKey={'id'}
                  content={
                    datasets
                    // .filter(
                    //   (ds: Dataset) => (
                    //     ds?.user?.email === CVPApi.email
                    //     // && ds.is_public === true
                    //   )
                    // )
                    .sort((a, b) => b.id - a.id)
                    .map((dataset) => {
                      return {
                        content: [
                          <div key={dataset.id} className="se-checkbox se-checkbox--md">
                            <input type="checkbox" checked={selectedDataset?.id === dataset.id}  />
                            <label>
                              <span className="se-checkbox-label"></span>
                            </label>
                          </div>,
                          dataset.description,
                          dataset?.solution?.name,
                          dataset.task,
                          dataset.mill,
                          // moment(dataset.created_at).fromNow(),
                          (dataset?.user?.email || '').split('@')[0],
                          moment.utc(dataset.created_at).format("L LT"),
                        ],
                        id: dataset.id,
                        dataset,
                      }
                    })
                  }
                  onItemClick={(event: any, item: any) => {
                    setValue('data_path', item.dataset.path, { shouldValidate: true })
                    setSelectedDataset(item.dataset)
                  }}
                />
              </div>
            )
          }
      </>
    </>
  )
}
