import matter from 'gray-matter'
import KBData, { PagesData } from '../kb'

let FILES: any = {}
let PAGES: any = {}

KBData.keys().forEach((filename: string) => {
  FILES[filename] = KBData(filename)
})

PagesData.keys().forEach((filename: string) => {
  PAGES[filename] = PagesData(filename)
})

export interface Post {
  slug: string
  raw: string
  matter: {
    content: string
    data: {
      title: string
      description: string
      image: string
    }
    updatedAt: any
    excerpt: string
  }
}

export class KBService {
  public fetchPosts = async (): Promise<Post[]> => {
    return ((
      await Promise.all(
        Object.keys(FILES).map(async (file: string) => {
          const slugMatches: string[] | null = /\.\/([^\.]+).md/g.exec(file)
          if (!slugMatches || slugMatches.length < 2) {
            return undefined
          }
          const slug: string = slugMatches[1]
          try {
            const text = await (await fetch(FILES[file])).text()
            return {
              slug,
              raw: text,
              matter: matter(text) as any,
            }
          } catch (error) {
            return undefined
          }
        }),
      )
    ).filter(Boolean) as unknown) as Post[]
  }

  public fetchPost = async (slug: string): Promise<Post | undefined> => {
    try {
      const text = await (await fetch(FILES[`./${slug}.md`])).text()
      return {
        slug,
        raw: text,
        matter: matter(text) as any,
      }
    } catch (error) {
      return undefined
    }
  }

  public fetchPage = async (slug: string): Promise<Post | undefined> => {
    try {
      const text = await (await fetch(PAGES[`./${slug}.md`])).text()
      return {
        slug,
        raw: text,
        matter: matter(text) as any,
      }
    } catch (error) {
      return undefined
    }
  }
}

const kb = new KBService()

export default kb
