import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import * as Pages from './containers'
import Routes from './routes'
import requireAuthentication from './lib/requireAuthentication'

import './styles/vendor.sass'
import './styles/global.sass'

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.Root} component={Pages.Root} />
        <Redirect exact from={Routes.Login} to={Routes.Library} />
        {/* <Route exact path={Routes.Login} component={Pages.Login} /> */}

        <Route exact path={Routes.Home} component={requireAuthentication(Pages.Home)} />
        {/* Library screen */}
        <Route exact path={Routes.Library} component={requireAuthentication(Pages.Library)} />
        {/* solution pages */}
        <Route
          exact
          path={Routes.SolutionNew}
          component={requireAuthentication(Pages.SolutionNew)}
        />
        <Route
          exact
          path={Routes.SolutionOnboard}
          component={requireAuthentication(Pages.SolutionOnboard)}
        />
        <Redirect exact from={Routes.SolutionRoot} to={Routes.SolutionOverview} />
        <Route
          exact
          path={Routes.SolutionOverview}
          component={requireAuthentication(Pages.SolutionOverview)}
        />
        <Route
          exact
          path={Routes.SolutionInstruction}
          component={requireAuthentication(Pages.SolutionInstruction)}
        />
        <Route
          exact
          path={Routes.SolutionTest}
          component={requireAuthentication(Pages.SolutionTest)}
        />
        <Route
          exact
          path={Routes.SolutionDeploy}
          component={requireAuthentication(Pages.SolutionDeploy)}
        />
        <Route
          exact
          path={Routes.SolutionDevelop}
          component={requireAuthentication(Pages.SolutionDevelop)}
        />
        <Route
          exact
          path={Routes.SolutionTrain}
          component={requireAuthentication(Pages.SolutionTrain)}
        />
        <Route
          exact
          path={Routes.SolutionDiscussion}
          component={requireAuthentication(Pages.SolutionDiscussion)}
        />
        <Route
          exact
          path={Routes.SolutionEdit}
          component={requireAuthentication(Pages.SolutionEdit)}
        />
        <Route
          exact
          path={Routes.SolutionExternalFinalize}
          component={requireAuthentication(Pages.SolutionExternalFinalize)}
        />
        <Route
          exact
          path={Routes.SolutionExternalTrainingFinalize}
          component={requireAuthentication(Pages.SolutionExternalTrainingFinalize)}
        />
        {/* model pages */}
        <Route
          exact
          path={Routes.AIModelDetail}
          component={requireAuthentication(Pages.AIModelDetail)}
        />

        {/* dataset pages */}
        <Route
          exact
          path={Routes.DatasetAnnotateWithCVAT}
          component={requireAuthentication(Pages.DatasetAnnotateWithCVAT)}
        />
        <Route
          exact
          path={Routes.DatasetDetail}
          component={requireAuthentication(Pages.DatasetDetail)}
        />

        {/* KnowledgeBase pages */}
        <Route
          exact
          path={Routes.KnowledgeBase}
          component={requireAuthentication(Pages.KnowledgeBase)}
        />
        <Route exact path={Routes.KBNew} component={requireAuthentication(Pages.KBNew)} />
        <Route exact path={Routes.KBPost} component={requireAuthentication(Pages.KBPost)} />
        <Route exact path={Routes.KBEdit} component={requireAuthentication(Pages.KBEdit)} />

        <Route exact path={Routes.Admin} component={requireAuthentication(Pages.Admin)} />
      </Switch>
    </BrowserRouter>
  )
}
