import React from "react";
// import videojs from "video.js";
// import "video.js/dist/video-js.css";

export const VideoJS = (props: any) => {

  const videoRef: any = React.useRef(null);
  const playerRef: any = React.useRef(null);
  const { options, onReady } = props;

  // React.useEffect(() => {
  //   // make sure Video.js player is only initialized once
  //   if (!playerRef.current) {
  //     const videoElement = videoRef.current;
  //     if (!videoElement) return;

  //     const player = playerRef.current = videojs(videoElement, options, () => {
  //       onReady && onReady(player);
  //     });
  //   } else {
  //     // you can update player here [update player through props]
  //     // const player = playerRef.current;
  //     // player.autoplay(options.autoplay);
  //     // player.src(options.sources);
  //   }
  // }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  // React.useEffect(() => {
  //   return () => {
  //     if (playerRef?.current) {
  //       playerRef.current.dispose();
  //       playerRef.current = null;
  //     }
  //   };
  // }, []);

  return (
    <div data-vjs-player>
      {/* <video ref={videoRef} className="video-js vjs-big-play-centered" /> */}
      <video
        ref={videoRef}
        // className="video-js vjs-big-play-centered"
        controls
        width={options.width} height={options.height}
      >
        <source src={options.sources[0]?.src} />
      </video>
    </div>
  );
}

export default VideoJS;
