import React, { useEffect, useReducer, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  Button, Dropdown, Notification, Link, Card,
  Icon,
  Spinner,
  RoundProgressIndicator,
} from '@storaensods/seeds-react'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'
import _ from 'lodash'

import SolutionPageLayout from './Base'
import { Title, FileField } from '../../components'
import CVPApi from '../../services/cvp'
import { Solution, AIModel, AIModelStatus, SolutionType, TrainingStepStatus } from '../../types'
import UploadService from '../../services/upload'
import { downloadBlob, downloadLink, nonFailedModels, onlyAvailableModels, onlyDeployedModels, TrainingAction, trainingReducer } from './actions'
import { COLORS, DEFAULT_DECIMAL_PLACES } from '../../config'
import { resizeImage } from '../../lib/utils'

const getImageDimensions = function(imgFile: string): Promise<{ width: number, height: number}> {
  return new Promise (function (resolved, rejected) {
    var i = new Image()
    i.onload = function(){
      resolved({width: i.width, height: i.height})
    };
    i.src = imgFile
  })
}

export default () => {
  const history = useHistory()
  const { register, handleSubmit, watch, errors, control } = useForm()
  const match: any = useRouteMatch('/library/:slug/:page')
  const { slug, page } = match?.params

  const [models, setModels]: [AIModel[], any] = useState([])
  const [result, setResult]: [
    ({ [key: string]: any, file_name: string } | string)[],
    any,
  ] = useState([])
  const [previewFiles, setPreviewFiles]: [{ [key: string]: any }, any] = useState({})
  const [fileMetadata, setFileMetadata]: [{ [key: string]: any }, any] = useState({})
  const [testing, setTesting]: [boolean, any] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [{ steps, submitting, errorMessages, uploadingFiles, totalFiles }, dispatch]: [
    any,
    any,
  ] = useReducer(trainingReducer, {
    steps: [
      { message: 'Preparing test data to send', status: TrainingStepStatus.INIT },
      { message: 'Running inference', status: TrainingStepStatus.INIT },
    ],
    submitting: false,
    errorMessages: [],
  })


  const initUploadFiles = (files: FileList) => {
    setResult([])
  }

  const onSubmit = async (data: any) => {
    // no available model to test
    const { model, file }: { model: any; file: FileList } = data
    if (!model?.value) return

    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: true })
    setTesting(true)
    setErrorMessage('')
    setResult([])

    // read all images to show preview
    let _previewFiles: { [key: string]: any } = {}
    let _fileMetadata: { [key: string]: any } = {}
    try {
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 0,
        status: TrainingStepStatus.LOADING,
      })
      console.log('converting to base64')
      await Promise.all(
        Array.from(file).map((f: File) => {
          return new Promise((resolve, reject) => {
            const fr = new FileReader()
            fr.onload = async (e) => {
              if (f.name.indexOf('.mp4') < 0 && f.name.indexOf('.avi') < 0) {
                // resizeImage((e?.target?.result as string), 800, 800).then((result) => {
                //   _previewFiles[f.name] = result
                //   resolve(true)
                // })
                _previewFiles[f.name] = e?.target?.result
                resolve(true)
              } else {
                _previewFiles[f.name] = e?.target?.result
                resolve(true)
              }
            }
            fr.readAsDataURL(f)
          })
        }),
      )
      console.log('getting metadata')
      await Promise.all(
        Object.keys(_previewFiles).map((k) => {
          return new Promise((resolve, reject) => {
            if (k.indexOf('.mp4') < 0 && k.indexOf('.avi') < 0) {
              getImageDimensions(_previewFiles[k]).then((dim) => {
                _fileMetadata[k] = {
                  ...dim
                }
                resolve(true)
              })
            } else{
              resolve(true)
            }
          })
        }),
      )
      console.log(_fileMetadata)
      setFileMetadata(_fileMetadata)
      setPreviewFiles(_previewFiles)
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 0,
        status: TrainingStepStatus.DONE,
      })
    } catch (error) {
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 0,
        status: TrainingStepStatus.ERROR,
      })
      dispatch({ type: TrainingAction.UPDATE_ERROR_MESSAGES, messages: ['Error processing the images before testing.'] })
      dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
      setErrorMessage('Error processing the images before testing.')
      setTesting(false)
      return
    }

    // console.log('_preview', _previewFiles)

    // Run testing
    console.log('running testing')
    try {
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 1,
        status: TrainingStepStatus.LOADING,
      })
      const resp = await CVPApi.runInference(slug, model.value, {
        payload: {
          // image: file,
          videos: Object.keys(_previewFiles).filter(k => k.indexOf('.mp4') >= 0 || k.indexOf('.avi') >= 0).map((k) => _previewFiles[k].split(',')[1]),
          images: Object.keys(_previewFiles).filter(k => k.indexOf('.mp4') < 0 && k.indexOf('.avi') < 0).map((k) => _previewFiles[k].split(',')[1]),
        },
        // formData: true
      })
      setResult(resp)
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 1,
        status: TrainingStepStatus.DONE,
      })
    } catch (error) {
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 1,
        status: TrainingStepStatus.ERROR,
      })
      dispatch({ type: TrainingAction.UPDATE_ERROR_MESSAGES, messages: [error.message] })
      dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
      setErrorMessage(error.message)
    }

    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
    setTesting(false)
  }

  const downloadTestImage = (model_id: number) => {
    CVPApi.downloadTestImage(slug, model_id)
      .then((response: any) => {
        console.log('downloading')
        if (typeof response === "string") {
          downloadLink(response)
        } else {
          downloadBlob(
            response["blob"],
            response["filename"]
          )
        }
      })
      .catch((error: Error) => {
        // setErrorMessage(error.message)
      })
      .finally(() => {
        // setDownloading(false)
      })
  }
  // cdm
  useEffect(() => {
    CVPApi.fetchAIModelBySolution(slug)
      .then((resp) => {
        setModels(
          resp.filter(
            (m) => [AIModelStatus.DEPLOYED, AIModelStatus.COMPLETED].indexOf(m.status) >= 0,
          ),
        )
      })
      .catch((error: Error) => {
        // TODO: show error
        console.error(error)
      })
  }, [])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const currentModel = models.find((m) => watch('model')?.value === m.id)
    const deployedModels = onlyDeployedModels(models)
    const groupedDeployedModels = _.groupBy(deployedModels, 'mill')
    return (
      <React.Fragment>
        {nonFailedModels(models).length === 0 ? (
          <p>
            There is no available models to test. Train the first model at{' '}
            <Link
              style={{ display: 'inline' }}
              href={`/library/${slug}/${solution?.is_external ? 'register' : 'train'}`}
            >
              solution training page
            </Link>
            .
          </p>
        ) : (
          onlyDeployedModels(models).length === 0 && (
            <p>
              There is no available models to test. Check the statuses of the current models at{' '}
              <Link style={{ display: 'inline' }} href={`/library/${slug}/overview`}>
                solution overview
              </Link>
              .
            </p>
          )
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>
            <h6>Input:</h6>{' '}
          </Title>
          <div className="row se-mb-md">
            <div className="col-md-5">
              <Controller
                as={
                  <Dropdown
                    label="Select model"
                    options={Object.keys(groupedDeployedModels).map((mill) => ({
                      type: 'group',
                      name: mill,
                      items: groupedDeployedModels[
                        mill
                      ].map(
                        (m: {
                          id: string | number
                          name: string
                          version: string
                          mill?: string
                          created_at: string
                        }) => ({
                          value: m.id,
                          label: <div className="se-row-item">
                            <div className="se-row-placeholder">{m.id}</div>
                            <div className="se-row-content">
                              <span className="se-row-content--left">
                                <strong>
                                  {m.name}
                                </strong> - ver {m.version}
                              </span>
                              <span className="se-row-content--right">
                                {`${moment.utc(m.created_at).format("L LT")}`}
                              </span>
                            </div>
                          </div>,
                        }),
                      ),
                    }))}
                  />
                }
                onChangeName="onSelect"
                rules={{ required: true }}
                name="model"
                control={control}
              />
              {errors.model ? (
                <small className="se-form-help se-form-help--invalid">
                  {errors.model?.type === 'required' && 'This field is required'}
                </small>
              ) : (
                currentModel && (
                  <small className="se-form-help">
                    <strong>Name:</strong> {currentModel.name}
                    <br />
                    <strong>Description:</strong> {currentModel.description}
                    <br />
                    <strong>Mill:</strong> {currentModel.mill}
                    <br />
                    {
                      Object.keys(currentModel.final_metrics).map((metric_key: string) => (
                        <>
                          <strong>{metric_key}:</strong>{' '}
                          {currentModel.final_metrics[metric_key]?.toFixed(DEFAULT_DECIMAL_PLACES)}
                          <br />
                        </>
                      ))
                    }
                    <strong>Test image:</strong>{' '}
                    <a href="#" onClick={() => downloadTestImage(currentModel.id)}>download</a>
                  </small>
                )
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <label htmlFor="file" className="se-label se-label--md">
                Upload images or videos:{' '}
              </label>
              <div className="se-input-container">
                <Controller
                  as={
                    <FileField
                      name="file"
                      // ref={register({ required: true })}
                      multiple
                      accept="image/jpg,image/jpeg,image/png,.mp4,.avi"
                      // accept='image/*,.avi,.mp4'
                      onChange={initUploadFiles}
                      valid={!errors.file}
                      helpText={
                        !errors.file
                          ? 'Only jpeg, jpg, png, mp4 and avi are now supported. Choose up to 10 files with maximum 100MB in total.'
                          : errors.file?.type === 'required'
                          ? 'This field is required'
                          : ''
                      }
                    />
                  }
                  name="file"
                  rules={{ required: true }}
                  control={control}
                />
              </div>
            </div>
          </div>
          <Button className="se-mt-md" isLoading={submitting} onClick={handleSubmit(onSubmit)}>
            Test
          </Button>
          <br />

          {submitting && (
            <div className='row se-mt-md'>
              <div className="col col-md-2">
                <RoundProgressIndicator
                  height={75}
                  mode="regular"
                  step={
                    steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE) >= 0 ?
                    steps.length - steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE)
                    : 0
                  }
                  steps={steps.length}
                />
              </div>
              <div className="col">
                {steps.map((step: any, stepIndex: number) => (
                  <p key={stepIndex}>
                    {step.status === TrainingStepStatus.LOADING && (
                      <Spinner className="training-spinner" />
                    )}
                    {step.status === TrainingStepStatus.INIT && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.ERROR && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.DONE && <Icon>check</Icon>} {step.message}{' '}
                    {stepIndex === 0 &&
                      uploadingFiles &&
                      totalFiles &&
                      `:${uploadingFiles}/${totalFiles}`}
                  </p>
                ))}
              </div>
            </div>
          )}

          {errorMessages.length > 0 && (
            <React.Fragment>
              <div className="se-pb-md"></div>
              <Notification
                onClose={() => dispatch({ type: TrainingAction.RESET_ERROR })}
                type="negative"
              >
                {errorMessages.map((m: string, index: number) => (
                  <p key={index}>{m}</p>
                ))}
              </Notification>
            </React.Fragment>
          )}

          {result.length > 0 && (
            <div className="se-mt-3xl">
              <Title>
                <h6>Output:</h6>
              </Title>
              <div className="row">
                {result.map((img: any, index: number) => {
                  const imgName = Object.keys(previewFiles)[index]
                  const previewImg = previewFiles[imgName]
                  let width: number, height: number
                  if (img.resize_shape) {
                    width = img.resize_shape[1]
                    height = img.resize_shape[0]
                  } else {
                    width = fileMetadata[imgName]?.width
                    height = fileMetadata[imgName]?.height
                  }

                  if (!img.image) return null

                  // object detection, segmentation, classfication
                  return (
                    <>
                      <div key={`image-${index}`} className="col-xs-12 col-md-6 col-lg-6 se-pb-2md">
                        <div style={{ position: 'relative' }}>
                          <img src={`data:image;base64,${img.image}`} style={{ width: '100%'}} />
                          {
                            [SolutionType.OBJECT_DETECTION, SolutionType.INSTANCE_SEGMENTATION].indexOf(solution.type) >= 0 && img.boxes && img.boxes.length > 0 && (
                              img.boxes.map((box: number[], boxIndex: number) => (
                                <div
                                  key={boxIndex}
                                  className={'annotation-box-holder'}
                                  style={{
                                    top: `${box[1]/height * 100}%`,
                                    left: `${box[0]/width * 100}%`,
                                    width: `${(box[2] - box[0])/width * 100}%`,
                                    height: `${(box[3] - box[1])/height * 100}%`,
                                    // borderColor: `rgb(${img.colors[img.labels[boxIndex]].join(', ')})`
                                    borderColor: `rgb(${COLORS[boxIndex].join(', ')})`
                                  }}
                                >
                                  <p>
                                    {/* {(img.scores[boxIndex] * 100).toFixed(3)} */}
                                    {boxIndex + 1}
                                  </p>
                                </div>
                              ))
                            )
                          }
                        </div>
                      </div>
                      <div key={`box-${index}`} className="col-xs-12 col-md-6 col-lg-6 se-pb-2md">
                        <Card
                          actions={[]}
                          title="The model detects"
                        >
                          {
                            [SolutionType.OBJECT_DETECTION, SolutionType.INSTANCE_SEGMENTATION].indexOf(solution.type) >= 0 && img.boxes.length === 0 && (
                              <p>
                                No object detected
                              </p>
                            )
                          }
                          {
                            [SolutionType.OBJECT_DETECTION, SolutionType.INSTANCE_SEGMENTATION].indexOf(solution.type) >= 0 && img.boxes.length > 0 && (
                              img.boxes.map((box: number[], boxIndex: number) => (
                                <p style={{ marginBottom: '3px' }}>
                                  <span style={{ verticalAlign: 'middle'}}>{boxIndex + 1}.{' '}</span>
                                  <div
                                    className="se-tag se-tag--primary"
                                    style={{
                                      backgroundColor: `rgb(${COLORS[boxIndex].join(', ')})`,
                                      minHeight: '1rem',
                                      minWidth: '1.5rem',
                                      verticalAlign: 'middle',
                                      marginRight: '3px',
                                      border: '1px solid #D6D9DF',
                                    }}
                                  >
                                    {' '}
                                  </div>{' '}{ img.labels[boxIndex] }{', '}
                                  <b>{(img.scores[boxIndex] * 100).toFixed(3)}%</b>
                                </p>
                              ))
                            )
                          }
                          {
                            solution.type === SolutionType.CLASSIFICATION && (
                              <p>
                                {`Label: ${img.labels[0]}, probability: ${(img.scores[0] * 100).toFixed(3)}`}
                              </p>
                            )
                          }
                          {
                            solution.type === SolutionType.SEMANTIC_SEGMENTATION && Object.keys(img.colors || {}).map((label: string) => (
                              <p>
                                <div
                                  className="se-tag se-tag--primary"
                                  style={{
                                    backgroundColor: `rgb(${img.colors[label].join(', ')})`,
                                    minHeight: '1rem',
                                    minWidth: '1.5rem',
                                    verticalAlign: 'middle',
                                    marginRight: '3px',
                                    border: '1px solid #D6D9DF',
                                  }}
                                >
                                  {' '}
                                </div>{' '}{ label }{', '}
                                <b>{
                                  img?.area_percentages?.[label] && (
                                    `${img.area_percentages[label].toFixed(3)}%`
                                  )
                                }</b>
                              </p>
                            ))
                          }
                        </Card>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )}
        </form>
      </React.Fragment>
    )
  }

  return <SolutionPageLayout description="Test model with your data." renderer={contentRenderer} />
}
