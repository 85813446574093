import React, { useEffect, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Input,
  Breadcrumbs,
  Link,
  Notification,
  Button,
  Tile,
  Icon,
} from '@storaensods/seeds-react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { useForm, Controller } from 'react-hook-form'

import { MainTabbedPage, WysiwygEditor, Title, FileField } from '../../components'

import CVPApi from '../../services/cvp'
import UploadService from '../../services/upload'
import { Solution, AIModel, AIModelStatus, SolutionStatus, Post } from '../../types'

import './styles.sass'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/kb/:slug/edit')
  const { slug } = match?.params
  const { register, handleSubmit, watch, errors, control } = useForm()
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting]: [boolean, any] = useState(false)
  const [post, setPost]: [Post | undefined, any] = useState()

  const onSubmit = async (data: any) => {
    setSubmitting(true)
    const { feature_image } = data
    let image_path = undefined

    // upload image
    if (feature_image) {
      try {
        const uploadService = await getUploadService()
        const uploadResp = await uploadService.upload(
          `${uploadService.blobPath}/${feature_image[0].name}`,
          feature_image[0],
        )
        image_path = `https://${uploadService.accountName}.blob.core.windows.net/${uploadService.container}/${uploadService.blobPath}/${feature_image[0].name}`
      } catch (error) {
        setErrorMessage(error.message)
      }
    }

    CVPApi.updatePost(slug, {
      payload: {
        ...data,
        feature_image: image_path,
      },
    })
      .then((post: Post) => {
        setPost(post)
        history.push(`/kb/${post.slug}/edit`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const getUploadService = (): Promise<UploadService> => {
    return CVPApi.requestPostImageUpload().then((uploadRequest) => {
      return new UploadService(
        uploadRequest.token,
        uploadRequest.account_name,
        uploadRequest.container_name,
        uploadRequest.blob_path,
      )
    })
  }

  // cdm
  useEffect(() => {
    CVPApi.fetchPost(slug)
      .then((post: Post) => {
        setPost(post)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
    getUploadService()
  }, [])

  return (
    <MainTabbedPage activeItem="Knowledge Center">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg se-kb-post">
        <VerticalMenuItem href="#" isActive label={<h6 className="se-mb-0">Edit post</h6>} />
        {post && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row se-mb-md se-mt-2xl">
              <div className="col-md-5">
                <Title>
                  <h6 className="se-mb-0">Title:</h6>
                </Title>
                <div className="row se-mb-md">
                  <div className="col-md-6">
                    <Controller
                      as={<Input />}
                      rules={{ required: true }}
                      name="title"
                      placeholder="Post title"
                      defaultValue={post.title}
                      invalid={errors.title ? true : undefined}
                      helpText={errors.title?.type === 'required' ? 'This field is required' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <Title>
                  <h6 className="se-mb-0">Feature image:</h6>
                </Title>
                <div className="row">
                  <div className="col-md-5">
                    {post?.feature_image && (
                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                      <img src={post.feature_image} width="100%" alt="post-image" />
                    )}
                    <div className="se-input-container">
                      <Controller
                        as={
                          <FileField
                            name={`feature_image`}
                            // ref={register({ required: true })}
                            accept="image/*"
                            valid={!errors?.feature_image}
                            helpText={
                              errors?.feature_image?.type === 'required'
                                ? 'This field is required'
                                : ''
                            }
                          />
                        }
                        // rules={{ required: true }}
                        name={`feature_image`}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                <Title>
                  <h6 className="se-mb-0 se-mt-xl">Short description:</h6>
                </Title>
                <div className="row se-mb-md">
                  <div className="col-md-6">
                    <Controller
                      as={<Input />}
                      rules={{ required: true }}
                      name="description"
                      placeholder="Post description"
                      defaultValue={post.description}
                      invalid={errors.description ? true : undefined}
                      helpText={
                        errors.description?.type === 'required' ? 'This field is required' : ''
                      }
                      control={control}
                    />
                  </div>
                </div>
                <Title>
                  <h6 className="se-mb-0 se-mt-xl">Content:</h6>
                </Title>
                <div className="row se-mb-md">
                  <div className="col-md-12">
                    <Controller
                      as={<WysiwygEditor placeholder="Input main content" name="body" />}
                      name="body"
                      rules={{ required: true }}
                      defaultValue={post.body}
                      // invalid={errors.name ? true : undefined}
                      // helpText={errors.name?.type === 'required' ? 'This field is required' : ''}
                      control={control}
                    />
                    {errors.body && (
                      <small className="se-form-help se-form-help--invalid">
                        {errors.body?.type === 'required' && 'This field is required'}
                      </small>
                    )}
                  </div>
                </div>

                {errorMessage && (
                  <React.Fragment>
                    <div className="se-pb-md"></div>
                    <Notification onClose={() => setErrorMessage('')} type="negative">
                      {errorMessage}
                    </Notification>
                  </React.Fragment>
                )}

                <Button
                  className="se-mt-md"
                  isLoading={submitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Update
                </Button>
              </div>
            </div>
          </form>
        )}

        {/* cannot load post */}
        {!post && errorMessage && (
          <React.Fragment>
            <div className="se-pb-md"></div>
            <Notification onClose={() => setErrorMessage('')} type="negative">
              Cannot load post '{slug}'
            </Notification>
          </React.Fragment>
        )}
      </div>
    </MainTabbedPage>
  )
}
