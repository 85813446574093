import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Header, Button, Footer } from '@storaensods/seeds-react'

import FileField from '../../components/FileField/FileField'

import './styles.sass'

type Props = {
  accept?: string
  multiple?: boolean
  ref?: any
  valid?: boolean
  helpText?: string
  onChange?: (files: FileList) => void
  name: string
}

const defaultProps = {
  accept: '*',
  multiple: false,
  helpText: '',
  onChange: (files: FileList) => {},
  name: '',
}

const CustomFileField = (props: Props = defaultProps) => {
  const [files, setFiles]: [FileList | undefined, any] = useState()

  return (
    <div
      className={`cvp-file-input ${!props.valid ? 'cvp-file-input--invalid' : ''}`}
      style={{ position: 'relative' }}
    >
      <label htmlFor={props.name}>
        {files && files.length > 0
          ? `${files.length} file${files.length > 1 ? 's' : ''} selected`
          : `Choose file${props.multiple ? 's' : ''}`}
      </label>
      <input
        id={props.name}
        type="file"
        name={props.name}
        className="se-input se-input--md"
        multiple={props.multiple}
        ref={props?.ref}
        accept={props.accept}
        onChange={(e: any) => {
          if (props.onChange) props.onChange(e.target.files)

          setFiles(e.target.files)
        }}
      />
      {typeof props.valid !== 'undefined' && props.helpText && (
        <small className={`se-form-help ${!props.valid ? 'se-form-help--invalid' : ''}`}>
          {props.helpText}
        </small>
      )}
    </div>
  )
}

CustomFileField.defaultProps = defaultProps

export default CustomFileField
