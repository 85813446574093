import _ from 'lodash'
import millJSON from './constants/mills.json'

export const FORM_CONFIG = {
    classification: {
        architecture: {
            name: 'Architecture',
            type: 'select',
            options: [
                'alexnet',
                'resnet18',
                'resnet34',
                'resnet50',
                'resnet101',
                'resnet152',
                'resnext50_32x4d',
                'resnext101_32x8d',
                'wide_resnet50_2',
                'wide_resnet101_2',
                'vgg11',
                'vgg11_bn',
                'vgg13',
                'vgg13_bn',
                'vgg16',
                'vgg16_bn',
                'vgg19_bn',
                'vgg19',
                'squeezenet1_0',
                'squeezenet1_1',
                'inception_v3',
                'densenet121',
                'densenet169',
                'densenet201',
                'densenet161',
                'googlenet',
                'mobilenet_v2',
                'mobilenet_v3_large',
                'mobilenet_v3_small',
                'mnasnet0_5',
                'mnasnet0_75',
                'mnasnet1_0',
                'mnasnet1_3',
                'shufflenet_v2_x0_5',
                'shufflenet_v2_x1_0',
                'shufflenet_v2_x1_5',
                'shufflenet_v2_x2_0',
            ],
            default: 'resnet18',
        },
        advanced_options: {
            metric: {
                name: 'Metric',
                type: 'select',
                description: 'A criteria to identify the training performance of model',
                // options: ['f1_score', 'accuracy'],
                options: [
                    { value: 'f1_avg', label: 'Average F1 score' },
                    { value: 'acc1', label: 'Accuracy' },
                ],
                default: 'f1_avg',
            },
            data_split: {
                name: 'Data split',
                type: 'select',
                description: 'A criteria to split the data into training and validation',
                options: [
                    //{ value: 'train', label: 'train' },
                    { value: 'trainval', label: 'train/validation' },
                    { value: 'trainvaltest', label: 'train/validation/test' },
                ],
                default: 'trainval',
            },
            // val_split_fraction: {
            //   name: 'Validation split fraction',
            //   type: 'select',
            //   // min: 0.1,
            //   // max: 0.5,
            //   // step: 0.1,
            //   options: [0.1, 0.2, 0.3, 0.4, 0.5],
            //   default: 0.2,
            // },
            split_fraction: {
                name: 'Validation split fraction',
                type: 'select',
                description: 'The fraction of data used for evaluating the model',
                // min: 0.1,
                // max: 0.5,
                // step: 0.1,
                options: [0.1, 0.2, 0.3, 0.4],
                default: 0.2,
            },
            learning_rate: {
                name: 'Learning rate',
                type: 'select',
                description: 'A parameter for optimising the training of a model',
                options: [0.05, 0.01, 0.005, 0.001, 0.0005, 0.0001, 0.00005, 0.00001, 0.000005, 0.000001],
                default: 0.001,
            },
            batch_size: {
                name: 'Batch size',
                description: 'Number of data samples that are processed in each model training iteration',
                type: 'select',
                options: [1, 4, 8, 16, 32, 64],
                default: 4,
            },
            epochs: {
                name: 'Epochs',
                type: 'select',
                description: 'One full training iteration on the overall training dataset',
                options: [10, 50, 100, 150, 250, 500, 1000, 2000, 5000, 10000],
                default: 100,
            },
        },
    },
    object_detection: {
        architecture: {
            name: 'Architecture',
            type: 'select',
            options: [
                'fasterrcnn_resnet50_fpn',
                'fasterrcnn_mobilenet_v3_large_320_fpn',
                'fasterrcnn_mobilenet_v3_large_fpn',
                'retinanet_resnet50_fpn',
            ],
            default: 'fasterrcnn_resnet50_fpn',
        },
        advanced_options: {
            metric: {
                name: 'Metric',
                type: 'select',
                description: 'A criteria to identify the training performance of model',
                options: [
                    { value: 'AP@IoU_0p50_0p95_area_all_maxDets_100', label: 'Average precision, all objects' },
                    { value: 'AP@IoU_0p50_0p95_area_small_maxDets_100', label: 'Average precision, small objects' },
                    { value: 'AP@IoU_0p50_0p95_area_medium_maxDets_100', label: 'Average precision, medium objects' },
                    { value: 'AP@IoU_0p50_0p95_area_large_maxDets_100', label: 'Average precision, medium objects' },
                    { value: 'AR@IoU_0p50_0p95_area_all_maxDets_100', label: 'Average recall, all objects' },
                    { value: 'AR@IoU_0p50_0p95_area_small_maxDets_100', label: 'Average recall, small objects' },
                    { value: 'AR@IoU_0p50_0p95_area_medium_maxDets_100', label: 'Average recall, medium objects' },
                    { value: 'AR@IoU_0p50_0p95_area_large_maxDets_100', label: 'Average recall, large objects' },
                ],
                default: 'AP@IoU_0p50_0p95_area_all_maxDets_100',
            },
            data_split: {
                name: 'Data split',
                type: 'select',
                description: 'A criteria to split the data into training and validation',
                options: [
                    // { value: 'train', label: 'train' },
                    { value: 'trainval', label: 'train/validation' },
                    { value: 'trainvaltest', label: 'train/validation/test' },
                ],
                default: 'trainval',
            },
            split_fraction: {
                name: 'Validation split fraction',
                type: 'select',
                description: 'The fraction of data used for evaluating the model',
                // min: 0.1,
                // max: 0.5,
                // step: 0.1,
                options: [0.1, 0.2, 0.3, 0.4, 0.5],
                default: 0.2,
            },
            learning_rate: {
                name: 'Learning rate',
                type: 'select',
                description: 'A parameter for optimising the training of a model',
                options: [0.05, 0.01, 0.005, 0.001, 0.0005, 0.0001, 0.00005, 0.00001, 0.000005, 0.000001],
                default: 0.001,
            },
            batch_size: {
                name: 'Batch size',
                type: 'select',
                description: 'Number of data samples that are processed in each model training iteration',
                options: [1, 4, 8, 16, 32, 64],
                default: 4,
            },
            epochs: {
                name: 'Epochs',
                type: 'select',
                description: 'One full training iteration on the overall training dataset',
                options: [10, 50, 100, 150, 250, 500, 1000, 2000, 5000, 10000],
                default: 100,
            },
        },
    },
    semantic_segmentation: {
        architecture: {
            name: 'Architecture',
            type: 'select',
            options: [
                'fcn_resnet50',
                "fcn_resnet101",
                "deeplabv3_resnet50",
                "deeplabv3_resnet101",
                "deeplabv3_mobilenet_v3_large",
                "lraspp_mobilenet_v3_large",
            ],
            default: 'fcn_resnet50',
        },
        advanced_options: {
            metric: {
                name: 'Metric',
                type: 'select',
                description: 'A criteria to identify the training performance of model',
                options: [
                    { value: 'acc_glob', label: 'Pixel accuracy' },
                    { value: 'Average_precision', label: 'Average pixel precision' },
                    { value: 'Average_recall', label: 'Average pixel recall' },
                    { value: 'Average_IoU', label: 'Average IoU' },
                    { value: 'Average_F1_score', label: 'Average F1 score' },
                ],
                default: 'acc_glob',
            },
            data_split: {
                name: 'Data split',
                type: 'select',
                description: 'A criteria to split the data into training and validation',
                options: [
                    // { value: 'train', label: 'train' },
                    { value: 'trainval', label: 'train/validation' },
                    { value: 'trainvaltest', label: 'train/validation/test' },
                ],
                default: 'trainval',
            },
            split_fraction: {
                name: 'Validation split fraction',
                type: 'select',
                description: 'The fraction of data used for evaluating the model',
                // min: 0.1,
                // max: 0.5,
                // step: 0.1,
                options: [0.1, 0.2, 0.3, 0.4, 0.5],
                default: 0.2,
            },
            learning_rate: {
                name: 'Learning rate',
                type: 'select',
                description: 'A parameter for optimising the training of a model',
                options: [0.05, 0.01, 0.005, 0.001, 0.0005, 0.0001, 0.00005, 0.00001, 0.000005, 0.000001],
                default: 0.001,
            },
            batch_size: {
                name: 'Batch size',
                type: 'select',
                description: 'Number of data samples that are processed in each model training iteration',
                options: [1, 4, 8, 16, 32, 64],
                default: 4,
            },
            epochs: {
                name: 'Epochs',
                type: 'select',
                description: 'One full training iteration on the overall training dataset',
                options: [10, 50, 100, 150, 250, 500, 1000, 2000, 5000, 10000],
                default: 100,
            },
        },
    },
    instance_segmentation: {
        architecture: {
            name: 'Architecture',
            type: 'select',
            options: [
                'maskrcnn_resnet50_fpn',
            ],
            default: 'maskrcnn_resnet50_fpn',
        },
        advanced_options: {
            metric: {
                name: 'Metric',
                type: 'select',
                description: 'A criteria to identify the training performance of model',
                options: [
                    { value: 'AP@IoU_0p50_0p95_area_all_maxDets_100', label: 'Average precision, all objects' },
                    { value: 'AP@IoU_0p50_0p95_area_small_maxDets_100', label: 'Average precision, small objects' },
                    { value: 'AP@IoU_0p50_0p95_area_medium_maxDets_100', label: 'Average precision, medium objects' },
                    { value: 'AP@IoU_0p50_0p95_area_large_maxDets_100', label: 'Average precision, medium objects' },
                    { value: 'AR@IoU_0p50_0p95_area_all_maxDets_100', label: 'Average recall, all objects' },
                    { value: 'AR@IoU_0p50_0p95_area_small_maxDets_100', label: 'Average recall, small objects' },
                    { value: 'AR@IoU_0p50_0p95_area_medium_maxDets_100', label: 'Average recall, medium objects' },
                    { value: 'AR@IoU_0p50_0p95_area_large_maxDets_100', label: 'Average recall, large objects' },
                ],
                default: 'AP@IoU_0p50_0p95_area_all_maxDets_100',
            },
            data_split: {
                name: 'Data split',
                type: 'select',
                description: 'A criteria to split the data into training and validation',
                options: [
                    // { value: 'train', label: 'train' },
                    { value: 'trainval', label: 'train/validation' },
                    { value: 'trainvaltest', label: 'train/validation/test' },
                ],
                default: 'trainval',
            },
            split_fraction: {
                name: 'Validation split fraction',
                type: 'select',
                description: 'The fraction of data used for evaluating the model',
                // min: 0.1,
                // max: 0.5,
                // step: 0.1,
                options: [0.1, 0.2, 0.3, 0.4, 0.5],
                default: 0.2,
            },
            learning_rate: {
                name: 'Learning rate',
                type: 'select',
                description: 'A parameter for optimising the training of a model',
                options: [0.05, 0.01, 0.005, 0.001, 0.0005, 0.0001, 0.00005, 0.00001, 0.000005, 0.000001],
                default: 0.001,
            },
            batch_size: {
                name: 'Batch size',
                type: 'select',
                description: 'Number of data samples that are processed in each model training iteration',
                options: [1, 4, 8, 16, 32, 64],
                default: 4,
            },
            epochs: {
                name: 'Epochs',
                type: 'select',
                description: 'One full training iteration on the overall training dataset',
                options: [10, 50, 100, 150, 250, 500, 1000, 2000, 5000, 10000],
                default: 100,
            },
        },
    },
}

export const MILLS: { [key: string]: string } = Object.assign(
    {},
    ...[
        'None',
        'Other',
        ...millJSON,
    ].map((m: string) => {
        let key = _.snakeCase(_.deburr(m))
        // for historical data that we have been working on in this specific mill
        key = key === 'sachsen_mill' ? 'sachsen' : key

        return {
            [key]: m,
        }
    }),
)

export const DEFAULT_DECIMAL_PLACES = 3

export const SUPPORTED_PYTHON_VERSIONS = [
  { label: 'Python 3.7', value: 'python3.7' },
  { label: 'Python 3.6', value: 'python3.6' },
  { label: 'Python 3.8', value: 'python3.8' },
]

export const COLORS = [
  [0, 255, 0],
  [0, 0, 255],
  [255, 0, 0],
  [0, 255, 255],
  [255, 255, 0],
  [255, 0, 255],
  [80, 70, 180],
  [250, 80, 190],
  [245, 145, 50],
  [70, 150, 250],
  [50, 190, 190],
  [81, 235, 96],
  [239, 196, 92],
  [49, 225, 26],
  [182, 38, 255],
  [136, 111, 87],
  [0, 225, 209],
  [92, 215, 226],
  [87, 105, 104],
  [107, 95, 56],
  [68, 215, 168],
  [156, 156, 5],
  [139, 64, 251],
  [160, 221, 143],
  [180, 147, 69],
  [173, 41, 6],
  [78, 130, 97],
  [138, 40, 180],
  [14, 78, 214],
  [5, 233, 119],
  [83, 102, 197],
  [113, 26, 69],
  [141, 113, 31],
  [229, 67, 60],
  [170, 184, 198],
  [120, 86, 202],
  [41, 206, 100],
  [115, 236, 18],
  [142, 65, 169],
  [220, 30, 11],
  [137, 52, 1],
  [6, 57, 59],
  [232, 23, 13],
  [74, 8, 32],
  [102, 109, 247],
  [3, 160, 238],
  [202, 97, 38],
  [255, 232, 50],
  [210, 140, 50],
  [206, 149, 124],
  [95, 11, 192],
  [158, 194, 24],
  [243, 152, 95],
  [35, 161, 213],
  [48, 144, 139],
  [111, 230, 3],
  [75, 30, 109],
  [130, 185, 252],
  [149, 230, 145],
  [22, 59, 66],
  [177, 71, 43],
  [121, 235, 50],
  [69, 104, 56],
  [173, 226, 125],
  [74, 145, 15],
  [37, 24, 243],
  [152, 122, 189],
  [96, 80, 68],
  [140, 242, 112],
  [36, 43, 204],
  [30, 199, 118],
  [240, 142, 45],
  [200, 126, 224],
  [92, 48, 115],
  [56, 30, 210],
  [200, 160, 39],
  [252, 50, 209],
  [168, 109, 51],
  [255, 93, 120],
  [68, 199, 187],
  [32, 100, 240],
  [218, 143, 49],
  [58, 247, 94],
  [177, 5, 168],
  [61, 214, 241],
  [175, 106, 64],
  [77, 191, 234],
  [156, 23, 171],
  [235, 93, 11],
  [110, 161, 129],
  [219, 243, 16],
  [1, 185, 55],
  [37, 171, 46],
  [85, 133, 42],
  [40, 44, 184],
  [238, 82, 60],
  [180, 71, 188],
  [40, 135, 55],
  [254, 62, 247],
  [205, 98, 79],
  [75, 194, 82],
]
