import { BlobServiceClient } from '@azure/storage-blob'
import { asyncForEach } from '../lib/utils'
import { ErrorMessage } from 'react-hook-form'

export interface UploadOptions {
  toDir?: string
}

export default class UploadService {
  public container: string = ''
  public sasToken: string = ''
  public accountName: string = ''
  public blobServiceClient: any
  public blobPath: string = ''

  constructor(sasToken: string, accountName: string, container: string, blob_path: string) {
    this.sasToken = sasToken
    this.container = container
    this.accountName = accountName
    this.blobPath = blob_path
    this.blobServiceClient = new BlobServiceClient(
      `https://${this.accountName}.blob.core.windows.net?${this.sasToken}`,
    )
  }

  upload = async (blockName: string, content: any) => {
    const containerClient = this.blobServiceClient.getContainerClient(this.container)
    const blockBlobClient = containerClient.getBlockBlobClient(blockName)
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(content)
    return {
      id: uploadBlobResponse.requestId,
      blockName: blockName,
    }
  }

  uploadParallel = async (
    files: FileList | File[],
    options: UploadOptions = {},
    onStatusUpdate?: ({ success, error }: any) => void,
  ) => {
    // TODO: listing before uploading for saving time
    const _PRL_NUM = 5
    let _bucket: { blockName: string; file: File }[] = []
    let finishBucket: { blockName: string; id: any }[] = []
    let errorBucket: { blockName: string; id: any; error: any }[] = []

    let queuedFiles = Array.from(files)
    const origQueuedFiles = [...queuedFiles]

    let toBeQueueFiles: File[] = []

    const onUpdateError = (error: any) => {
      errorBucket.push(error)
      if (onStatusUpdate) onStatusUpdate({ error })
    }

    const onUpdateSuccess = (success: any) => {
      finishBucket.push(success)
      if (onStatusUpdate) onStatusUpdate({ success })
    }

    const queue = async () => {
      if (toBeQueueFiles.length >= _PRL_NUM) return
      if (queuedFiles.length === 0) return

      const spliceNum = _PRL_NUM - toBeQueueFiles.length
      const toAdd = queuedFiles.splice(0, spliceNum)
      toBeQueueFiles = [...toBeQueueFiles, ...toAdd]

      await Promise.all(
        toAdd.map(
          (file: File): Promise<any> => {
            const uploadPath = options?.toDir
              ? `${this.blobPath}/${options.toDir}/${file.name}`
              : `${this.blobPath}/${file.name}`
            return new Promise((resolve, reject) => {
              const fr = new FileReader()
              fr.onload = (e) => {
                this.upload(uploadPath, e?.target?.result)
                  .then((resp) => {
                    const { id, blockName }: { id: any; blockName: string } = resp
                    onUpdateSuccess({
                      blockName,
                      id,
                    })
                    toBeQueueFiles = [...toBeQueueFiles.filter((x) => x.name !== file.name)]
                    queue().finally(() => {
                      resolve({ blockName, id })
                    })
                  })
                  .catch((error: Error) => {
                    onUpdateError({
                      blockName: uploadPath,
                      id: undefined,
                      error,
                    })
                    toBeQueueFiles = [...toBeQueueFiles.filter((x) => x.name !== file.name)]
                    queue()
                      .then(() => {})
                      .finally(() => {
                        // we cannot use reject.
                        // because reject once can terminate all promises
                        resolve({ blockName: uploadPath, id: undefined, error })
                      })
                  })
                  .finally(() => {})
              }
              fr.readAsArrayBuffer(file)
            })
          },
        ),
      ).catch((err) => {
        // silent catching
      })
    }

    await queue()

    return { successes: finishBucket, errors: errorBucket, total: queuedFiles.length }
  }
}
