import React, { useEffect, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Toggle,
  Tag,
  Input,
  Dropdown,
  Button,
} from '@storaensods/seeds-react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'

import { ConfirmationButton, CreatableSelect, MainTabbedPage, Table } from '../components'

import CVPApi from '../services/cvp'
import { Role, Solution, SolutionStatus, SolutionType } from '../types'

import styles from './Library.module.scss'
import { MILLS } from '../config'

export default () => {
  const history = useHistory()
  const { control, handleSubmit, formState, getValues, watch } = useForm()
  const [foundUsers, setFoundUsers]: [any[], any] = useState([])
  const [admins, setAdmins]: [any[], any] = useState([])
  const [allUsers, setAllUsers]: [any[], any] = useState([])

  // cdm
  useEffect(() => {
    CVPApi.findAdmins().then((users) => {
      setAllUsers(
        users.filter(user =>
          user.groups && (
            user.groups.length === 0
            || (user.groups.length > 0 && user.groups.find((g: any) => g.name !== 'api'))
          )
        )
      )
      setAdmins(users.filter(user => (user.groups || []).find((g: any) => g.name !== 'api')))
    })
  }, [])

  const onSubmit = async (data: any) => {
    try {
      const users = await CVPApi.findUsers(data.email.value)
      setFoundUsers(users)
    } catch (error) {
      setFoundUsers([])
    }
  }

  return (
    <MainTabbedPage activeItem="Admin Settings">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <div className="row se-pb-xl">
          <div className="col-md-8 col-lg-6 col-sm-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <VerticalMenuItem
                isActive
                label={<h6 className="se-mb-0">Admin settings</h6>}
                className="se-mb-lg"
              />
              <p>
                Assign admin right to other users
              </p>
              <div className={styles.searchContainer}>
                <Controller
                  as={<CreatableSelect
                    options={allUsers.map((a) => ({ label: a.email, value: a.email })) }
                    className='width-100'
                  />}
                  name="email"
                  placeholder="Search by email"
                  control={control}
                />
                <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                  Search
                </Button>
              </div>
              <small className={`se-form-help`}>
                Fill in an email of the person to search.
              </small>
            </form>
            <Table
              headers={[
                ['Email', 250],
                ['Role', 300],
                ['', 200],
              ]}
              content={foundUsers
                .sort((a, b) => b.id - a.id)
                .map((user) => {
                  return {
                    content: [
                      user.email,
                      user.groups.map((g: any) => g.name).join(', '),
                      [
                        (user.groups || []).find((g: any) => g.name === Role.PLATFORM_ADMIN) ? <ConfirmationButton
                          type="negative"
                          onClick={async () => {
                            const resp = await CVPApi.revokeAdminRight(user.id);
                            setFoundUsers(
                              [
                                ...foundUsers.filter(u => u.id !== user.id),
                                ...resp,
                              ]
                            )
                          }}
                          key="1"
                          actionText="Remove"
                          confirmationContent={
                            <p>
                              This user will no longer see all solutions and able to perform admin tasks.
                            </p>
                          }
                          flat
                        >
                          Remove
                        </ConfirmationButton> : <Button
                          type="primary"
                          onClick={async () => {
                            const resp = await CVPApi.grantAdminRight(user.id);
                            setFoundUsers(
                              [
                                ...foundUsers.filter(u => u.id !== user.id),
                                ...resp,
                              ]
                            )
                          }}
                          key="1"
                        >
                          Confirm
                        </Button>
                      ],
                    ],
                  }
                })}
            />
          </div>
        </div>

        <div className="row se-pb-xl">
          <div className="col-md-8 col-lg-6 col-sm-12">
            <VerticalMenuItem
              isActive
              label={<h6 className="se-mb-0">User roles</h6>}
              className="se-mb-lg"
            />
            <Table
              headers={[
                ['Email', 250],
                ['Role', 300],
                ['', 200],
              ]}
              content={admins
                .sort((a, b) => b.id - a.id)
                .map((user) => {
                  return {
                    content: [
                      user.email,
                      user.groups.map((g: any) => g.name).join(', '),
                      [
                        (user.groups || []).find((g: any) => g.name === Role.PLATFORM_ADMIN) ? <ConfirmationButton
                          type="negative"
                          onClick={async () => {
                            const resp = await CVPApi.revokeAdminRight(user.id);
                            setFoundUsers(
                              [
                                ...foundUsers.filter(u => u.id !== user.id),
                                ...resp,
                              ]
                            )
                          }}
                          key="1"
                          actionText="Remove"
                          confirmationContent={
                            <p>
                              This user will no longer see all solutions and able to perform admin tasks.
                            </p>
                          }
                          flat
                        >
                          Remove
                        </ConfirmationButton> : <Button
                          type="primary"
                          onClick={async () => {
                            const resp = await CVPApi.grantAdminRight(user.id);
                            setFoundUsers(
                              [
                                ...foundUsers.filter(u => u.id !== user.id),
                                ...resp,
                              ]
                            )
                          }}
                          key="1"
                        >
                          Confirm
                        </Button>
                      ],
                    ],
                  }
                })}
            />
          </div>
        </div>
      </div>

    </MainTabbedPage>
  )
}
