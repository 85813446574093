import React, { useState } from 'react'

// import {
//   TabBar, TabItem,
//   NavigationBar,
// } from '@storaensods/seeds-react'

import './styles.sass'

type Props = {
  headers: (string | [string, number])[]
  content: {
    content: any[]
  }[],
  dataKey?: string,
  onItemClick?: (event: any, item: any) => void,
}

export default ({ headers, content, onItemClick, dataKey }: Props) => {
  const [activeItem, setActiveItem]: [any, any] = useState(undefined)

  if (content.length === 0) return null

  return (
    <div>
      <table className="se-table">
        <thead className="se-table-head">
          <tr>
            {headers.map((header: string | [string, number], index: number) => (
              <th style={{ width: typeof header !== 'string' ? `${header[1]}px` : '' }} key={index}>
                <div className="se-table-head-content se-table-head-content--not-clickable">
                  {typeof header === 'string' ? header : header[0]}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {content.map((row: any, index: number) => {
            const key = dataKey ? row[dataKey] : index
            return (
              <tr
                className={`se-table-item ${onItemClick && 'se-table-item--clickable'} ${activeItem === key && 'se-table-item--active'}`}
                key={key}
                onClick={(e: any) => {
                  setActiveItem(key)
                  if (onItemClick) onItemClick(e, row)
                }}
              >
                {row.content.map((col: any) => (
                  <td>{col}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
