import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  Breadcrumbs,
  Link,
  Tag,
  VerticalMenu,
  VerticalMenuItem,
  Tile,
} from '@storaensods/seeds-react'

import Page from '../../components/Page/Page'

import CVPApi from '../../services/cvp'
import { Solution, AIModel, AIModelStatus, SolutionStatus } from '../../types'

import './styles.sass'
import { nonFailedModels, onlyAvailableModels, onlyPackageModels } from './actions'

type Props = {
  children?: any
  title?: string
  description?: string
  // activeItem: string,
  // items: string[],
  // onSwitch: (item: string) => void,
  renderer?: ({ solution, models }: { solution: Solution; models: AIModel[] }) => any
}

const Base = (props: Props) => {
  const { children, title, description, renderer } = props
  const match: any = useRouteMatch('/library/:slug/:page/:subpage?')
  const { slug, page, subpage } = match?.params

  const [solution, setSolution]: [Solution | undefined, any] = useState()
  const [models, setModels]: [AIModel[], any] = useState([])
  const [loading, setLoading]: [boolean, any] = useState(true)

  // cdm
  useEffect(() => {
    const reloadModels = async (slug: string) => {
      return await CVPApi.fetchAIModelBySolution(slug)
        .then((resp) => {
          setModels(resp)
        })
        .catch((error: Error) => {
          // TODO: show error
          console.error(error)
        })
    }
    let refreshID: any
    Promise.all([
      CVPApi.fetchSolution(slug)
        .then((resp: any) => {
          setSolution(resp)
        })
        .catch((error: Error) => {
          // TODO: show error
          console.error(error)
        }),
      reloadModels(slug),
    ]).finally(() => {
      setLoading(false)
      refreshID = setInterval(() => reloadModels(slug), 60000)
    })

    return () => refreshID && clearInterval(refreshID)
  }, [])

  return (
    <Page>
      <Breadcrumbs className="se-pl-lg">
        <Link href="/library">Home</Link>
        <Link href={`/library/${slug}/${page === 'models' ? 'overview' : page}`}>
          {title ? title : solution?.name || ''}
        </Link>
      </Breadcrumbs>
      <div className="solution-page">
        <div className="solution-sidebar">
          <VerticalMenu color="light">
            <VerticalMenuItem
              className="se-mt-md"
              isActive={page === 'overview'}
              icon="description"
              label="Overview"
              href={`/library/${slug}/overview`}
            />
            <VerticalMenuItem
              className="se-mt-md"
              isActive={page === 'instructions'}
              icon="format_list_numbered"
              label="Instructions"
              href={`/library/${slug}/instructions`}
            />
            <VerticalMenuItem
              className="se-mt-md"
              isActive={page === 'test'}
              icon="add_to_photos"
              label="Test with data"
              href={`/library/${slug}/test`}
            />
            <VerticalMenuItem
              className="se-mt-md"
              isActive={page === 'download'}
              icon="settings_system_daydream"
              label="Download"
              href={`/library/${slug}/download`}
            />
            {!loading &&
              (!solution?.is_external || solution?.has_external_training) &&
              (onlyAvailableModels(models).length > 0 ? (
                <VerticalMenuItem
                  className="se-mt-md"
                  isActive={page === 'fine-tune'}
                  icon="queue_play_next"
                  label="Fine-tune"
                  href={`/library/${slug}/fine-tune`}
                />
              ) : (
                <VerticalMenuItem
                  className="se-mt-md"
                  isActive={page === 'train'}
                  icon="queue_play_next"
                  label="Train"
                  href={`/library/${slug}/train`}
                />
              ))}
            {solution?.is_external && !solution?.has_external_inference && (
              <VerticalMenuItem
                className="se-mt-md"
                isActive={page === 'register' && subpage === 'inference'}
                icon="queue_play_next"
                label="Register inference"
                href={`/library/${slug}/register/inference`}
              />
            )}
            {solution?.is_external && !solution?.has_external_training && solution.has_external_inference && (
              <VerticalMenuItem
                className="se-mt-md"
                isActive={page === 'register' && subpage === 'training'}
                icon="queue_play_next"
                label="Register training"
                href={`/library/${slug}/register/training`}
              />
            )}
            <VerticalMenuItem
              className="se-mt-md"
              isActive={page === 'discussion'}
              icon="question_answer"
              label="Discussion forum"
              href={`/library/${slug}/discussion`}
            />
          </VerticalMenu>
        </div>
        <div className="solution-content">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <Tile
                color={
                  ({
                    [SolutionStatus.DRAFT]: 'gray',
                    [SolutionStatus.IN_DEVELOPMENT]: 'yellow',
                    [SolutionStatus.AVAILABLE]: 'green',
                  } as any)[solution?.status || 'draft'] || 'yellow'
                }
              >
                {solution?.is_external && <Tag>imported</Tag>}&nbsp;
                <span className="se-m-0 se-font-size-md">
                  {description ? description : `${solution?.name}, ${solution?.status}`}
                </span>
              </Tile>
              <div className="se-pt-lg se-pl-lg">
                {renderer && solution && !loading ? renderer({ solution, models }) : children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Base
