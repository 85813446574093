import React from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Button, Input, TextArea, Hero, Tile } from '@storaensods/seeds-react'
import ReactMarkdown from 'react-markdown'

import SolutionPageLayout from './Base'

import { Solution, AIModel } from '../../types'
import { TeamsLink } from '../../components'

export default () => {
  const history = useHistory()

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => (
    <React.Fragment>
      <ReactMarkdown source={solution.instructions} />
      <TeamsLink text="Get in-person support" />
    </React.Fragment>
  )

  return (
    <SolutionPageLayout
      renderer={contentRenderer}
      description="Instructions and general info notes"
    />
  )
}
