import React, { useEffect, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Breadcrumbs,
  Link,
  Button,
  Notification,
  Tile,
  Icon,
  Input,
  TextArea,
  Dropdown,
} from '@storaensods/seeds-react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { MainTabbedPage, Title, WysiwygEditor, FileField, Popover } from '../components'
import CVPApi from '../services/cvp'
import { MILLS } from '../config'
import { useExitPrompt } from '../lib/hooks'

export default () => {
  const history = useHistory()
  const { register, handleSubmit, watch, errors, control, formState } = useForm()
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting]: [boolean, any] = useState(false)
  const [showExitPrompt, exitPrompt, setShowExitPrompt]: [boolean, any, any] = useExitPrompt(false)

  const onSubmit = (data: any): void => {
    CVPApi.createSolution({
      payload: {
        status: 'draft',
        description: '',
        is_external: true,
        ...data,
        instructions: data?.instructions,
        overview: data?.overview,
        base_model_info: {
          mill: data?.mill?.value,
          model_name: data?.model_name,
        },
        type: data?.type?.value,
      },
    })
      .then((solution) => {
        setShowExitPrompt(false)
        history.push(`/library/${solution.id}/register/inference`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setSubmitting(false)
      })
  }

  // cdm
  useEffect(() => {}, [])

  useEffect(() => {
    setShowExitPrompt(formState.dirty)
  }, [formState.dirty])

  return (
    <MainTabbedPage activeItem="Import a Solution">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <Title color="blue">
          <h6 className="se-mb-0">Solution preparation</h6>
        </Title>
        <div className="row se-pt-md">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-md-7">
                <Tile color="gray" className="">
                  <Link href={`/kb/onboarding-solution-guideline`} target="__blank">
                    <Icon color="blue">chevron_right</Icon>
                    <span>Onboarding solution guideline</span>
                  </Link>
                </Tile>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          {exitPrompt}
          <div className="row se-mb-md">
            <div className="col-md-5">

              <div className="row se-mb-md">
                <div className="col-md-6">
                  <Title>
                    <Popover
                      component={
                          <h6 className="se-mb-0">Solution name:</h6>
                      }
                      position="bottom"
                    >
                      A solution can have several models, which belong to the same use case
                    </Popover>
                  </Title>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    name="name"
                    placeholder="Solution name"
                    invalid={errors.name ? true : undefined}
                    helpText={errors.name?.type === 'required' ? 'This field is required' : ''}
                    control={control}
                  />
                </div>
                <div className="col-md-6">
                  <Title>
                    <Popover
                      component={
                        <h6 className="se-mb-0"> Task: </h6>
                      }
                      position="bottom"
                    >
                      Computer Vision Task
                    </Popover>
                  </Title>
                  <Controller
                    as={
                      <Dropdown
                        // label='Select model'
                        options={[
                          { label: 'Classification', value: 'classification' },
                          { label: 'Object detection', value: 'object_detection' },
                          { label: 'Instance segmentation', value: 'instance_segmentation' },
                          { label: 'Semantic segmentation', value: 'semantic_segmentation' },
                        ]}
                      />
                    }
                    defaultValue={{ label: 'Classification', value: 'classification' }}
                    rules={{ required: true }}
                    name="type"
                    control={control}
                  />
                  {errors.type ? (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.type?.type === 'required' ? 'This field is required' : '&nbsp;'}
                    </small>
                  ) : (
                    <small className="se-form-help">
                       <Link
                        style={{ display: 'inline', }}
                        href="/kb/supported-application-task"
                        target="__blank"
                      >
                        <Icon color="blue">
                          chevron_right
                        </Icon>
                        <span>Learn more</span>
                      </Link>
                    </small>
                  )}
                </div>

              </div>

              <div className="row se-mb-md se-mt-2xl">
                <div className="col-md-6">
                  <Title>
                    <Popover
                      component={
                          <h6 className="se-mb-0">Mill:</h6>
                      }
                      position="bottom"
                    >
                      Name of the mill the model is developed for
                    </Popover>
                  </Title>
                  <Controller
                    as={
                      <Dropdown
                        options={
                          Object.keys(MILLS).map((mKey: string) => ({
                            label: MILLS[mKey],
                            value: mKey,
                          })) as { label: string; value: string }[]
                        }
                      />
                    }
                    rules={{ required: true }}
                    name="mill"
                    control={control}
                  />
                  {errors.mill && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.mill?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
                <div className="col-md-6">
                  <Title>
                    <Popover
                      component={
                          <h6 className="se-mb-0">Model name:</h6>
                      }
                      position="bottom"
                    >
                      Name of the use case at the defined mill
                    </Popover>
                  </Title>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    name="model_name"
                    placeholder="Model name"
                    invalid={errors.model_name ? true : undefined}
                    helpText={errors.model_name?.type === 'required' ? 'This field is required' : ''}
                    control={control}
                  />
                </div>
              </div>

              <Title>
                <Popover
                  component={
                      <h6 className="se-mb-0 se-mt-2xl">Purpose:</h6>
                  }
                  position="bottom"
                >
                  Short description of the solution shown in the Solution Library
                </Popover>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<TextArea />}
                    rules={{ required: true, maxLength: 120 }}
                    name="description"
                    placeholder="If you could sum up the solution in a pitch what would you write?"
                    // defaultValue="If you could sum up the solution in a pitch what would you write?"
                    control={control}
                  />
                  {errors.description ? (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.description?.type === 'required' && 'This field is required'}
                      {errors.description?.type === 'maxLength' && 'Maximum 120 characters'}
                    </small>
                  ) : (
                    <small className="se-form-help">
                      Maximum 120 characters.
                    </small>
                  )}
                </div>
              </div>

              <Title>
                <Popover
                  component={
                      <h6 className="se-mb-0 se-mt-2xl">Description:</h6>
                  }
                  position="bottom"
                >
                  Longer description of the solution, providing more details about the context to other user
                </Popover>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<TextArea />}
                    rules={{ required: true }}
                    name="overview"
                    placeholder="How is the solution in a bit more details? Try to elaborate on the context."
                    // defaultValue="How is the solution in a bit more details? Try to elaborate on the context."
                    control={control}
                  />
                  {errors.overview && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.overview?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>

              <Title>
                <Popover
                  component={
                      <h6 className="se-mb-0 se-mt-2xl">Instructions:</h6>
                  }
                  position="bottom"
                >
                  Explanation of how to take a solution into use
                </Popover>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<TextArea />}
                    rules={{ required: true }}
                    name="instructions"
                    placeholder="If you would describe the solution in steps in the clearest way possible what would it say?"
                    // defaultValue="If you would describe the solution in steps in the clearest way possible what would it say?"
                    control={control}
                  />
                  {errors.instructions && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.instructions?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>

              {errorMessage && (
                <React.Fragment>
                  <div className="se-pb-md"></div>
                  <Notification onClose={() => setErrorMessage('')} type="negative">
                    {errorMessage}
                  </Notification>
                </React.Fragment>
              )}

              <Button className="se-mt-md" isLoading={submitting} onClick={handleSubmit(onSubmit)}>
                Create
              </Button>
            </div>
          </div>
        </form>
      </div>
    </MainTabbedPage>
  )
}
