import React, { useEffect, useState, useReducer } from 'react'
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import {
  Button,
  Notification,
  Toggle,
  Dropdown,
  Link,
  Icon,
  Input,
  Spinner,
  RoundProgressIndicator,
} from '@storaensods/seeds-react'
import { sortedLastIndexOf, groupBy } from 'lodash'
import moment from 'moment'

import SolutionPageLayout from './Base'
import AdvancedOptions from './Form/AdvancedOptions'
import { Title, FileField, CreatableSelect, Popover } from '../../components'

import CVPApi from '../../services/cvp'
import UploadService from '../../services/upload'
import { Solution, AIModel, AIModelStatus, TrainingStepStatus, SolutionType, DatasetStatus, Dataset } from '../../types'

import { FORM_CONFIG, MILLS, DEFAULT_DECIMAL_PLACES } from '../../config'
import { asyncForEach } from '../../lib/utils'
import { onlyAvailableModels, trainingReducer, TrainingAction } from './actions'
import { unstable_batchedUpdates } from 'react-dom'
import DataUploadOptions from './Form/DataUploadOptions'
import { useExitPrompt } from '../../lib/hooks'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/library/:slug/:page')
  const { slug, page } = match?.params
  const formUtils = useForm()
  const { register, handleSubmit, watch, errors, control, formState } = formUtils
  const classNameControls = useFieldArray({ control, name: 'classes' })
  const fileControls = useFieldArray({ control, name: 'file' })
  const watchCVATOption = watch('enable_cvat')
  const [showExitPrompt, exitPrompt, setShowExitPrompt]: [boolean, any, any] = useExitPrompt(false)

  const [{ steps, submitting, errorMessages, uploadingFiles, totalFiles }, dispatch]: [
    any,
    any,
  ] = useReducer(trainingReducer, {
    steps: [
      { message: 'Uploading dataset', status: TrainingStepStatus.INIT },
      { message: 'Queuing model to AzureML for fine-tuning', status: TrainingStepStatus.INIT },
    ],
    submitting: false,
    errorMessages: [],
  })

  // const onSubmit = async (data: any, solution: Solution) => {
  const onSubmit = async (data: any) => {
    console.log('data', data)
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: true })
    dispatch({
      type: TrainingAction.UPDATE_UPLOAD_PROGRESS,
      uploadingFiles: undefined,
      totalFiles: undefined,
    })
    let data_path = data?.data_path

    // 1. Uploading dataset
    // step 0
    let _errorMessages: (string | undefined)[] = []
    dispatch({
      type: TrainingAction.UPDATE_TRAINING_STATUS,
      step: 0,
      status: TrainingStepStatus.LOADING,
    })
    let uploadRequest
    let dataset: Dataset
    if (!data.data_path) {
      try {
        uploadRequest = await CVPApi.requestDatasetUpload()
      } catch (error) {
        dispatch({ type: TrainingAction.UPDATE_ERROR_MESSAGES, messages: [error.message] })
        dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
        return
      }
      const uploadService = new UploadService(
        uploadRequest.token,
        uploadRequest.account_name,
        uploadRequest.container_name,
        uploadRequest.blob_path,
      )
      // console.log('request', uploadRequest)

      const classNames = data.classes,
        files = data.files
      // Mark as started uploading data
      try {
        dataset = await CVPApi.createDataset({
          payload: {
            solution_id: data.solution_id,
            data_path: uploadRequest.blob_path,
            container_name: uploadRequest.container_name,
            mill: data?.mill?.value,
          }
        })
        await CVPApi.updateDataset(dataset.id, {
          payload: {
            status: DatasetStatus.UPLOADING
          }
        })
      } catch (error) {
        dispatch({
          type: TrainingAction.UPDATE_TRAINING_STATUS,
          step: 0,
          status: TrainingStepStatus.ERROR,
        })
        dispatch({
          type: TrainingAction.UPDATE_ERROR_MESSAGES,
          messages: ['Failed to start uploading data set.'],
        })
        dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
        return
      }
      dispatch({
        type: TrainingAction.UPDATE_UPLOAD_PROGRESS,
        uploadingFiles: 0,
        totalFiles: files.reduce((t: number, curr: FileList) => (t += curr.length), 0),
      })
      await asyncForEach(classNames, async (className: string | undefined, index: number) => {
        // no data to upload
        if (!className) return

        if (className === 'annotations') {
          Object.defineProperty(files[index][0], 'name', {
            writable: true,
            value: 'instances_default.json'
          });
        }

        await uploadService.uploadParallel(
          files[index],
          {
            toDir: className,
          },
          ({ success, error }) => {
            if (error) {
              if (
                error.error?.details?.Code &&
                error?.details?.Code === 'UnauthorizedBlobOverwrite'
              ) {
                // auto skip existed file
                dispatch({ type: TrainingAction.MARK_UPLOAD_FILE_DONE })
                // return `Error uploading '${blockName}' (File existed)`
              } else if (error.error?.details?.Code) {
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              } else {
                // unknown or un-catch error
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              }
            }
            if (success) {
              dispatch({ type: TrainingAction.MARK_UPLOAD_FILE_DONE })
            }
          },
        )
      })
      dispatch({
        type: TrainingAction.UPDATE_ERROR_MESSAGES,
        messages: _errorMessages.filter(Boolean),
      })
      if (_errorMessages.length !== 0) {
        dispatch({
          type: TrainingAction.UPDATE_TRAINING_STATUS,
          step: 0,
          status: TrainingStepStatus.ERROR,
        })
        dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
        return
      } else {
        try {
          if (dataset) {
            await CVPApi.updateDataset(dataset.id, {
              payload: {
                status: DatasetStatus.UPLOADED
              }
            })
          }
        } catch (error) {
          dispatch({
            type: TrainingAction.UPDATE_TRAINING_STATUS,
            step: 0,
            status: TrainingStepStatus.ERROR,
          })
          dispatch({
            type: TrainingAction.UPDATE_ERROR_MESSAGES,
            messages: ['Failed to upload data set.'],
          })
          dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
          return
        }
        data_path = dataset.path
        dispatch({
          type: TrainingAction.UPDATE_TRAINING_STATUS,
          step: 0,
          status: TrainingStepStatus.DONE,
        })
      }
    } else {
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 0,
        status: TrainingStepStatus.DONE,
      })
    }

    // 2. Fine-tuning request
    dispatch({
      type: TrainingAction.UPDATE_TRAINING_STATUS,
      step: 1,
      status: TrainingStepStatus.LOADING,
    })
    try {
      await CVPApi.finetune(slug, data?.model?.value, {
        payload: {
          ...Object.assign(
            {},
            ...Object.keys(data).map((k) => ({
              [k]:
                typeof data[k] === 'object' && data[k]?.value
                  ? data[k].value
                  : isNaN(data[k])
                  ? data[k]
                  : +data[k],
            })),
          ),
          model_id: data?.model?.value,
          model: undefined,
          mill: data?.mill?.value,
          classes: data?.type !== SolutionType.OBJECT_DETECTION ? data?.classes : [],
          // split_fraction: data?.split_fraction?.value ? data?.split_fraction?.value : data?.split_fraction,
          enable_advanced_options: undefined,
          use_based_model_dataset: undefined,
          files: undefined,
          data_path,
        },
      })
      setShowExitPrompt(false)
      history.push(`/library/${slug}/`)
    } catch (error) {
      console.error(error)
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 1,
        status: TrainingStepStatus.ERROR,
      })
      dispatch({
        type: TrainingAction.UPDATE_ERROR_MESSAGES,
        messages: ['Failed to queue new model.'],
      })
    }
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
  }

  // cdm
  useEffect(() => {}, [])
  useEffect(() => {
    setShowExitPrompt(formState.dirty)
  }, [formState.dirty])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const form_config = (FORM_CONFIG as any)[solution?.type]
    const availableModels = onlyAvailableModels(models)
    const groupedAvailableModels = groupBy(availableModels, 'mill')
    const defaultModel: AIModel | undefined =
      availableModels.length > 0 ? availableModels[0] : undefined
    const selectedModel: AIModel | undefined =
      availableModels.find((m) => m.id === watch('model')?.value) || defaultModel
    if (availableModels.length === 0) {
      return (
        <p>
          There is no available models to fine-tune. Check the statuses of the current models at{' '}
          <Link style={{ display: 'inline' }} href={`/library/${slug}/overview`}>
            solution overview
          </Link>
          .
        </p>
      )
    }

    return (
      <React.Fragment>
        <form
          // onClick={handleSubmit(function (data) { onSubmit(data, solution) })}
          // onClick={handleSubmit(onSubmit)}
          className="se-pb-2xl"
        >
          {exitPrompt}
          <input name='type' type='hidden' value={solution.type} />
          <div className="row">
            <div className="col-md-12">
              <Title>
                <h6>Select existing model:</h6>{' '}
              </Title>
            </div>
            <div className="col-md-5">
              <Controller
                as={
                  <Dropdown
                    // label='Select model'
                    // size='sm'
                    options={Object.keys(groupedAvailableModels).map((mill) => ({
                      type: 'group',
                      name: mill,
                      items: groupedAvailableModels[
                        mill
                      ].map(
                        (m: {
                          id: string | number
                          name: string
                          version: string
                          mill?: string
                          created_at: string
                        }) => ({
                          value: m.id,
                          label: <div className="se-row-item">
                            <div className="se-row-placeholder">{m.id}</div>
                            <div className="se-row-content">
                              <span className="se-row-content--left">
                                <strong>
                                  {m.name}
                                </strong> - ver {m.version}
                              </span>
                              <span className="se-row-content--right">
                                {`${moment.utc(m.created_at).format("L LT")}`}
                              </span>
                            </div>
                          </div>,
                        }),
                      ),
                    }))}
                  />
                }
                defaultValue={
                  defaultModel
                    ? {
                        value: defaultModel?.id,
                        label: <div className="se-row-item">
                          <div className="se-row-placeholder">{defaultModel.id}</div>
                          <div className="se-row-content">
                            <span className="se-row-content--left">
                              <strong>
                                {defaultModel.name}
                              </strong> - ver {defaultModel.version}
                            </span>
                            <span className="se-row-content--right">
                              {`${moment.utc(defaultModel.created_at).format("L LT")}`}
                            </span>
                          </div>
                        </div>,
                      }
                    : undefined
                }
                onChangeName="onSelect"
                rules={{ required: true }}
                name="model"
                control={control}
              />
              {errors.model ? (
                <small className="se-form-help se-form-help--invalid">
                  {errors.model?.type === 'required' && 'This field is required'}
                </small>
              ) : (
                selectedModel && (
                  <small className="se-form-help">
                    <strong>Name:</strong> {selectedModel.name}
                    <br />
                    <strong>Description:</strong> {selectedModel.description}
                    <br />
                    <strong>Mill:</strong> {selectedModel.mill}
                    <br />
                    {
                      Object.keys(selectedModel.final_metrics).map((metric_key: string) => (
                        <>
                          <strong>{metric_key}:</strong>{' '}
                          {selectedModel.final_metrics[metric_key]?.toFixed(DEFAULT_DECIMAL_PLACES)}
                          <br />
                        </>
                      ))
                    }
                  </small>
                )
              )}
            </div>
          </div>

          <div className="row se-mt-2xl">
            <div className="col-md-12">
              <Title>
                <Popover
                  component={
                    <h6>Select mill:</h6>
                  }
                  position="bottom"
                >
                  Name of the mill the model is developed for
                </Popover>
              </Title>
            </div>
            <div className="col-md-5">
              <Controller
                as={
                  <Dropdown
                    options={
                      Object.keys(MILLS).map((mKey: string) => ({
                        label: MILLS[mKey],
                        value: mKey,
                      })) as { label: string; value: string }[]
                    }
                  />
                }
                rules={{ required: true }}
                name="mill"
                control={control}
              />
              {errors.mill && (
                <small className="se-form-help se-form-help--invalid">
                  {errors.mill?.type === 'required' && 'This field is required'}
                </small>
              )}
            </div>
          </div>

          <div className="row se-mt-2xl">
            <div className="col-md-5">
              <Title>
                <Popover
                  component={
                      <h6>Model name:</h6>
                  }
                  position="bottom"
                >
                  Name of the use case at the defined mill
                </Popover>
              </Title>
              <Controller
                as={<Input />}
                rules={{ required: true }}
                name="description"
                placeholder="Model name"
                helpText={errors.description?.type === 'required' ? 'This field is required' : ''}
                control={control}
              />
            </div>
          </div>

          <DataUploadOptions
            solution={solution}
            selectedModel={selectedModel}
            {...formUtils}
          />

          <div className="row se-mt-2xl">
            <div className="col-md-5">
              <Title>
                <Popover
                  component={
                    <h6>Select architecture:</h6>
                  }
                  position="bottom"
                >
                  Each setting has a default value, which can be modified as desired. If user does not enable the advanced settings, the default values are used for the training.
                </Popover>
              </Title>
              <Controller
                as={
                  <Dropdown
                    // label='Select model'
                    // size='sm'
                    options={form_config.architecture.options}
                  />
                }
                onChangeName="onSelect"
                defaultValue={selectedModel?.architecture || form_config.architecture.default}
                rules={{ required: true }}
                disabled
                name="architecture"
                control={control}
              />
              {errors.architecture && (
                <small className="se-form-help se-form-help--invalid">
                  {errors.architecture?.type === 'required' && 'This field is required'}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 se-mt-md">
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </p> */}
              <Link href="/kb/training-architecture-and-advanced-options" target="__blank">
                <Icon color="blue">
                  chevron_right
                </Icon>
                <span>Learn more</span>
              </Link>
            </div>
          </div>

          <AdvancedOptions
            register={register}
            watch={watch}
            errors={errors}
            control={control}
            field_config={
              !solution.is_external ?
                form_config.advanced_options
                : Object.assign({},
                  ...(["epochs", "learning_rate", "batch_size",].map((k: string) => ({
                    [k]: form_config.advanced_options[k]
                  })))
                )
            }
          />

          <Button
            className="se-mt-md"
            disabled={submitting}
            isLoading={submitting}
            // onClick={handleSubmit((data) => onSubmit(data, solution))}
            onClick={handleSubmit(onSubmit)}
          >
            Fine-tune
          </Button>
          <div className="row">
            <div className="col-md-8 se-mt-md">
              <small className={`se-form-help`}>
                The fine-tuning will take 15-45 minutes to start running. The whole process can take
                hours to finish.
              </small>
            </div>
          </div>

          {submitting && (
            <div className='row se-mt-md'>
              <div className="col col-md-2">
                <RoundProgressIndicator
                  height={75}
                  mode="regular"
                  step={
                    steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE) >= 0 ?
                    steps.length - steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE)
                    : 0
                  }
                  steps={steps.length}
                />
              </div>
              <div className="col">
                {steps.map((step: any, stepIndex: number) => (
                  <p key={stepIndex}>
                    {step.status === TrainingStepStatus.LOADING && (
                      <Spinner className="training-spinner" />
                    )}
                    {step.status === TrainingStepStatus.INIT && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.ERROR && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.DONE && <Icon>check</Icon>} {step.message}{' '}
                    {stepIndex === 0 &&
                      uploadingFiles &&
                      totalFiles &&
                      `:${uploadingFiles}/${totalFiles}`}
                  </p>
                ))}
              </div>
            </div>
          )}

          {errorMessages.length > 0 && (
            <React.Fragment>
              <div className="se-pb-md"></div>
              <Notification
                onClose={() => dispatch({ type: TrainingAction.RESET_ERROR })}
                type="negative"
              >
                {errorMessages.map((m: string, index: number) => (
                  <p key={index}>{m}</p>
                ))}
              </Notification>
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
    )
  }

  return (
    <SolutionPageLayout
      renderer={contentRenderer}
      description={'Fine-tune the solution to match your needs'}
    />
  )
}
