import React, { useEffect, useState, useReducer } from 'react'
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import {
  Button,
  Input,
  Notification,
  Dropdown,
  Link,
  Icon,
  Spinner,
  TextArea,
  Tile,
  Toggle,
  RoundProgressIndicator,
} from '@storaensods/seeds-react'

import SolutionPageLayout from './Base'
import { Title, FileField, CreatableSelect } from '../../components'
import { Solution, AIModel, TrainingStepStatus, SolutionType, Dataset, DatasetStatus } from '../../types'

import CVPApi from '../../services/cvp'
import UploadService from '../../services/upload'

import AdvancedOptions from './Form/AdvancedOptions'
import { FORM_CONFIG, MILLS, SUPPORTED_PYTHON_VERSIONS } from '../../config'
import { asyncForEach } from '../../lib/utils'
import { onlyAvailableModels, trainingReducer, TrainingAction } from './actions'
import { useExitPrompt } from '../../lib/hooks'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/library/:slug/:page')
  const { slug, page } = match?.params
  const { register, handleSubmit, watch, errors, control, formState } = useForm()
  const classNameControls = useFieldArray({ control, name: 'classes' })
  const watchCVATOption = watch('enable_cvat')
  const [showExitPrompt, exitPrompt, setShowExitPrompt]: [boolean, any, any] = useExitPrompt(false)

  const [{ steps, submitting, errorMessages, uploadingFiles, totalFiles }, dispatch]: [
    any,
    any,
  ] = useReducer(trainingReducer, {
    steps: [
      // { message: 'Validating model', status: TrainingStepStatus.INIT },
      // { message: 'Registering model', status: TrainingStepStatus.INIT },
    ],
    submitting: false,
    errorMessages: [],
  })
  const [classIds, setClassIds]: [number[], any] = useState([0, 1])

  const onSubmit = async (data: any) => {
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: true })
    let model_extension = data?.model[0]?.name.split('.').splice(-1,1)
    model_extension = model_extension.length > 0 ? model_extension[0] : undefined
    let uploadRequest = undefined, dataset: Dataset|undefined = undefined

    // prepare dataset for testing
    try {
      let _errorMessages: (string | undefined)[] = []
      if (data.test_images) {
        uploadRequest = await CVPApi.requestDatasetUpload()
        const uploadService = new UploadService(
          uploadRequest.token,
          uploadRequest.account_name,
          uploadRequest.container_name,
          uploadRequest.blob_path,
        )
        dataset = await CVPApi.createDataset({
          payload: {
            solution_id: data.solution_id,
            data_path: uploadRequest.blob_path,
            container_name: uploadRequest.container_name,
            mill: data?.mill?.value,
          }
        })
        await CVPApi.updateDataset(dataset.id, {
          payload: {
            status: DatasetStatus.UPLOADING
          }
        })
        await uploadService.uploadParallel(
          data.test_images,
          {
            toDir: 'images',
          },
          ({ success, error }) => {
            if (error) {
              if (
                error.error?.details?.Code &&
                error?.details?.Code === 'UnauthorizedBlobOverwrite'
              ) {
                // auto skip existed file
                // return `Error uploading '${blockName}' (File existed)`
              } else if (error.error?.details?.Code) {
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              } else {
                // unknown or un-catch error
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              }
            }
          },
        )
        if (_errorMessages.length === 0) {
          await CVPApi.updateDataset(dataset.id, {
            payload: {
              status: DatasetStatus.TEST_DATA_UPLOADED
            }
          })
        } else {
          throw Error(_errorMessages.join(', '))
        }
      }
    } catch (error) {
      dispatch({ type: TrainingAction.UPDATE_ERROR_MESSAGES, messages: error.message.split('\n') })
      dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
      return
    }


    // Registering request
    // dispatch({ type: TrainingAction.UPDATE_TRAINING_STATUS, step: 1, status: TrainingStepStatus.LOADING })
    try {
      delete data.test_images
      await CVPApi.registerInference(slug, {
        payload: {
          ...data,
          inference_script: data?.inference_script[0],
          // classes: data?.classes.join('\n'),
          classes: data?.classes.join('\n'),
          model: data?.model[0],
          model_extension: model_extension,
          mill: data?.mill?.value,
          python_version: data.python_version.value,
          data_path: dataset?.path,
          // split_fraction: data?.data_split.indexOf('test') >= 0 ? data?.split_fraction : 0,
        },
        formData: true,
      })
      setShowExitPrompt(false)
      history.push(`/library/${slug}/`)
    } catch (error) {
      // dispatch({ type: TrainingAction.UPDATE_TRAINING_STATUS, step: 1, status: TrainingStepStatus.ERROR })
      dispatch({ type: TrainingAction.UPDATE_ERROR_MESSAGES, messages: error.message.split('\n') })
    }
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
  }

  // cdm
  useEffect(() => {}, [])
  useEffect(() => {
    setShowExitPrompt(formState.dirty)
  }, [formState.dirty])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const { base_model_info } = solution
    const form_config = (FORM_CONFIG as any)[solution?.type]
    return (
      <React.Fragment>
        <Tile color="gray" className="">
          <Link href={`/kb/onboarding-solution-guideline`} target="__blank">
            <Icon color="blue">chevron_right</Icon>
            <span>Onboarding solution guideline</span>
          </Link>
        </Tile>

        <form className="se-pb-2xl se-mt-2xl">
          {exitPrompt}
          {
            solution?.base_model_info?.mill ? (
              <Controller
                as={
                  <input type='hidden' />
                }
                defaultValue={{ value: solution?.base_model_info?.mill }}
                rules={{ required: true }}
                name="mill"
                control={control}
              />
            ) : (
              <div className="row">

                <div className="col-md-12">
                  <Title>
                    <h6>Select mill:</h6>{' '}
                  </Title>
                </div>
                <div className="col-md-5">
                  <Controller
                    as={
                      <Dropdown
                        options={
                          Object.keys(MILLS).map((mKey: string) => ({
                            label: MILLS[mKey],
                            value: mKey,
                          })) as { label: string; value: string }[]
                        }
                      />
                    }
                    rules={{ required: true }}
                    name="mill"
                    control={control}
                  />
                  {errors.mill && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.mill?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>
            )
          }

          {
            solution?.base_model_info?.model_name ? (
              <Controller
                as={
                  <input type='hidden' />
                }
                defaultValue={solution?.base_model_info?.model_name}
                rules={{ required: true }}
                name="description"
                control={control}
              />
            ) : (
              <div className="row se-mt-2xl">
                <div className="col-md-5">
                  <Title>
                    <h6>Model name:</h6>{' '}
                  </Title>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    name="description"
                    placeholder="Model name"
                    control={control}
                  />
                  {errors.description && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.description?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>
            )
          }

          <div className="row se-mt-2xl">
            <div className="col-md-5">
              <Title>
                <h6>Upload model:</h6>
              </Title>
              <Controller
                as={
                  <FileField
                    name="model"
                    accept=".h5,.pth,.pb,.zip"
                    valid={!errors.model}
                    helpText={
                      !errors.model
                        ? 'Only file with .h5, .pth, .pb, .zip extension is supported'
                        : errors.model?.type === 'required'
                        ? 'This field is required'
                        : ''
                    }
                  />
                }
                name="model"
                rules={{ required: true }}
                control={control}
              />
            </div>
          </div>

          <div className="row se-mt-2xl">
            <div className="col-md-12">
              <Title>
                <h6>Inference configuration:</h6>
              </Title>
              <p>
                The following packages are supported: OpenCV, ...
                <br />
                Please specify additional packages below.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <Controller
                as={<TextArea />}
                // rules={{ required: true }}
                name="packages"
                defaultValue="tensorflow==2.2.0&#10;opencv-python==4.2.0.32"
                control={control}
              />
              {errors.packages && (
                <small className="se-form-help se-form-help--invalid">
                  {errors.packages?.type === 'required' && 'This field is required'}
                </small>
              )}
            </div>
          </div>
          <div className="row se-mt-md">
            <div className="col-md-5">
              <Controller
                as={
                  <FileField
                    name="inference_script"
                    accept=".py"
                    valid={!errors.inference_script}
                    helpText={
                      !errors.inference_script
                        ? 'Only file with .py extension is supported'
                        : errors.inference_script?.type === 'required'
                        ? 'This field is required'
                        : ''
                    }
                  />
                }
                name="inference_script"
                rules={{ required: true }}
                control={control}
              />
            </div>
          </div>
          <div className="row se-mt-md">
            <div className="col-md-5">
              <Controller
                as={
                  <Dropdown
                    label='Python version'
                    options={SUPPORTED_PYTHON_VERSIONS}
                  />
                }
                defaultValue={SUPPORTED_PYTHON_VERSIONS[0]}
                rules={{ required: true }}
                name="python_version"
                control={control}
              />
              {errors.python_version && (
                <small className="se-form-help se-form-help--invalid">
                  {errors.python_version?.type === 'required' && 'This field is required'}
                </small>
              )}
            </div>
          </div>

          <div className="row se-mt-2xl">
            <div className="col-md-12">
              <Title>
                <h6>Upload test image:</h6>
              </Title>
            </div>
          </div>
          <div className="row se-mt-md">
            <div className="col-md-5">
              <div className="se-input-container">
                <Controller
                  as={
                    <FileField
                      name={`test_images`}
                      // ref={register({ required: true })}
                      multiple
                      accept="image/*,.avi,.mp4"
                      valid={!errors?.images}
                      helpText={
                        errors?.test_images && errors.test_images.type === 'required'
                          ? 'Please select images.'
                          : 'Please select images.'
                      }
                    />
                  }
                  // rules={{ required: true }}
                  name={`test_images`}
                  control={control}
                />
              </div>
            </div>
          </div>

          <div className="row se-mt-2xl">
            <div className="col-md-12">
              <Title>
                <h6>Define dataset:</h6>
              </Title>
              <p>
                Please define here the class to be defined in the solution. <br />
                The order of the classes should match with the order in the model.
              </p>
            </div>
          </div>

          {/* <div className="row se-mb-md">
            <div className="col-md-5">
              <div className="d-flex">
                <Controller
                  as={<Toggle />}
                  name="enable_cvat"
                  valueName="isActive"
                  control={control}
                />
                <p className="align-self-center se-ml-md se-mb-0">Use CVAT</p>
              </div>
            </div>
          </div> */}

          <div style={{ display: !watchCVATOption ? 'block' : 'none' }}>
            <>
              {classIds.map((id) => (
                <div className="row se-mb-md" key={id}>
                  <div className="col-md-5">
                    <Controller
                      as={<Input />}
                      rules={{ required: true }}
                      name={`classes[${id}]`}
                      placeholder="Class name"
                      invalid={errors?.classes ? true : undefined}
                      helpText={
                        errors?.classes && errors.classes[id]?.type === 'required'
                          ? 'This field is required'
                          : ''
                      }
                      control={control}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button
                      flat
                      type="negative"
                      // size="sm"
                      onClick={() => {
                        classNameControls.remove(id)
                        setClassIds(classIds.filter((x) => x !== id))
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col-md-5">
                  <Button
                    flat
                    // size="sm"
                    type="primary"
                    onClick={() => setClassIds([...classIds, Math.max(...classIds) + 1])}
                  >
                    Add class
                  </Button>
                </div>
              </div>
            </>
          </div>

          {/* {
            solution.type === SolutionType.OBJECT_DETECTION && (
              <>
                <div className="row">
                  <div className="col-md-5">
                    <div className="se-input-container">
                      <Controller
                        as={
                          <input name='classes[0]' type='hidden' />
                        }
                        defaultValue='annotations'
                        rules={{ required: true }}
                        name={`classes[0]`}
                        control={control}
                      />
                      <Controller
                        as={
                          <FileField
                            name={`files[0]`}
                            // ref={register({ required: true })}
                            // multiple
                            accept=".json"
                            valid={!errors?.annotations}
                            helpText={
                              errors?.annotations && errors.annotations.type === 'required'
                                ? 'Please select annotation file (.json). This field is required'
                                : 'Please select annotation file (.json)'
                            }
                          />
                        }
                        rules={{ required: true }}
                        name={`files[0]`}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="row se-mt-md">
                  <div className="col-md-5">
                    <div className="se-input-container">
                      <Controller
                        as={
                          <input name='classes[1]' type='hidden' value='images' />
                        }
                        defaultValue='images'
                        rules={{ required: true }}
                        name={`classes[1]`}
                        control={control}
                      />
                      <Controller
                        as={
                          <FileField
                            name={`files[1]`}
                            // ref={register({ required: true })}
                            multiple
                            accept="image/*,.avi,.mp4"
                            valid={!errors?.images}
                            helpText={
                              errors?.images && errors.images.type === 'required'
                                ? 'Please select images. This field is required'
                                : 'Please select images'
                            }
                          />
                        }
                        rules={{ required: true }}
                        name={`files[1]`}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
              </>
            )
          }

          <div style={{ display: watchCVATOption ? 'block' : 'none' }}>
            <Button
              onClick={() => {}}
            >
              Annotate with CVAT
            </Button>
          </div> */}

          <Button
            className="se-mt-md"
            disabled={submitting}
            isLoading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            Validate & Register
          </Button>
          <div className="row">
            <div className="col-md-8 se-mt-md">
              <small className={`se-form-help`}>
                The validating process will take few minutes. After that, the model is queued for
                registering and deploying to web service.
              </small>
            </div>
          </div>

          {submitting && steps.length > 0 && (
            <div className='row se-mt-md'>
              <div className="col col-md-2">
                <RoundProgressIndicator
                  height={75}
                  mode="regular"
                  step={
                    steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE) >= 0 ?
                    steps.length - steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE)
                    : 0
                  }
                  steps={steps.length}
                />
              </div>
              <div className="col">
                {steps.map((step: any, stepIndex: number) => (
                  <p key={stepIndex}>
                    {step.status === TrainingStepStatus.LOADING && (
                      <Spinner className="training-spinner" />
                    )}
                    {step.status === TrainingStepStatus.INIT && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.ERROR && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.DONE && <Icon>check</Icon>} {step.message}{' '}
                    {stepIndex === 0 &&
                      uploadingFiles &&
                      totalFiles &&
                      `:${uploadingFiles}/${totalFiles}`}
                  </p>
                ))}
              </div>
            </div>
          )}

          {errorMessages.length > 0 && (
            <React.Fragment>
              <div className="se-pb-md"></div>
              <Notification
                onClose={() => dispatch({ type: TrainingAction.RESET_ERROR })}
                type="negative"
              >
                {errorMessages.map((m: string, index: number) => (
                  <p key={index} className="se-mb-0" style={{ minHeight: '1rem' }}>
                    {m}
                  </p>
                ))}
              </Notification>
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
    )
  }

  return (
    <SolutionPageLayout
      renderer={contentRenderer}
      description={'Import model for the solution'}
    />
  )
}
