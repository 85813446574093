import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  Divider,
  Button,
  Input,
  TextArea,
  Hero,
  Tile,
  Link,
  Notification,
  Dropdown,
} from '@storaensods/seeds-react'
import { useForm, Controller } from 'react-hook-form'

import SolutionPageLayout from './Base'
import UploadService from '../../services/upload'
import { FileField, Title, WysiwygEditor } from '../../components'

import CVPApi from '../../services/cvp'

import { Solution, AIModel, AIModelStatus } from '../../types'
import { useExitPrompt } from '../../lib/hooks'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/library/:slug/edit')
  const { slug } = match?.params
  const { register, handleSubmit, watch, errors, control, formState } = useForm()
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting]: [boolean, any] = useState(false)
  const [showExitPrompt, exitPrompt, setShowExitPrompt]: [boolean, any, any] = useExitPrompt(false)

  const getUploadService = (): Promise<UploadService> => {
    return CVPApi.requestPostImageUpload().then((uploadRequest) => {
      return new UploadService(
        uploadRequest.token,
        uploadRequest.account_name,
        uploadRequest.container_name,
        uploadRequest.blob_path,
      )
    })
  }

  const onSubmit = async (data: any) => {
    setSubmitting(true)

    const { preview_image } = data
    let image_path = undefined

    // upload image
    if (preview_image) {
      try {
        const uploadService = await getUploadService()
        const uploadResp = await uploadService.upload(
          `${uploadService.blobPath}/${preview_image[0].name}`,
          preview_image[0],
        )
        image_path = `https://${uploadService.accountName}.blob.core.windows.net/${uploadService.container}/${uploadService.blobPath}/${preview_image[0].name}`
      } catch (error) {
        setErrorMessage(error.message)
      }
    }

    CVPApi.updateSolution(slug, {
      payload: {
        ...data,
        preview_image: image_path,
      },
    })
      .then((solution) => {
        setShowExitPrompt(false)
        history.push(`/library/${solution.id}/overview`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setSubmitting(false)
      })
  }

  // cdm
  useEffect(() => {}, [])
  useEffect(() => {
    setShowExitPrompt(formState.dirty)
  }, [formState.dirty])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          {exitPrompt}
          <div className="row se-mb-md">
            <div className="col-md-12">
              <Title>
                <h6 className="se-mb-0 se-mt-2xl">Name:</h6>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<Input />}
                    defaultValue={solution.name}
                    rules={{ required: true }}
                    name="name"
                    placeholder="Input name of the solution"
                    control={control}
                  />
                  {errors.name && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.name?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>
              <Title>
                <h6 className="se-mb-0">Preview image:</h6>
              </Title>
              <div className="row">
                <div className="col-md-5">
                  {solution?.preview_image && (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img src={solution.preview_image} width="100%" alt="post-image" />
                  )}
                  <div className="se-input-container">
                    <Controller
                      as={
                        <FileField
                          name={`preview_image`}
                          accept="image/*"
                          valid={!errors?.preview_image}
                        />
                      }
                      helpText={`Image with 1024x768 ratio is recommended`}
                      name={`preview_image`}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              {/* <Title><h6 className='se-mb-0 se-mt-2xl'>Status:</h6></Title>
              <div className='row se-mb-md'>
                <div className='col-md-6'>
                  <Controller
                    as={<Dropdown
                      // label='Select model'
                      options={['draft', 'available']}
                    />}
                    defaultValue={solution.status || 'draft'}
                    onChangeName='onSelect'
                    rules={{ required: true }}
                    name='type'
                    control={control}
                  />
                  {
                    errors.type &&
                      <small className='se-form-help se-form-help--invalid'>
                        { errors.type?.type === 'required' && 'This field is required' }
                      </small>
                  }
                </div>
              </div> */}

              <Title>
                <h6 className="se-mb-0 se-mt-2xl">Purpose:</h6>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<TextArea />}
                    defaultValue={solution.description}
                    rules={{ required: true, maxLength: 120 }}
                    name="description"
                    placeholder="Input description of the solution"
                    control={control}
                  />
                  {errors.description ? (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.description?.type === 'required' && 'This field is required'}
                      {errors.description?.type === 'maxLength' && 'Maxium 120 characters'}
                    </small>
                  ) : (
                    <small className="se-form-help">
                      Maximum 120 characters
                    </small>
                  )}
                </div>
              </div>

              <Title>
                <h6 className="se-mb-0 se-mt-2xl">Overview:</h6>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<TextArea />}
                    // as={

                    //   <WysiwygEditor
                    //     placeholder="Input overview context of the problem and solution"
                    //     name="overview"
                    //   />
                    // }
                    name="overview"
                    defaultValue={solution.overview}
                    rules={{ required: true }}
                    placeholder="Input overview context of the problem and solution"
                    // invalid={errors.name ? true : undefined}
                    // helpText={errors.name?.type === 'required' ? 'This field is required' : ''}
                    control={control}
                  />
                  {errors.overview && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.overview?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>

              <Title>
                <h6 className="se-mb-0 se-mt-2xl">Instructions:</h6>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<TextArea />}
                    // as={
                    //   <WysiwygEditor
                    //     placeholder="Input instructions setting up the solution"
                    //     name="instructions"
                    //   />
                    // }
                    placeholder="Input instructions setting up the solution"
                    name="instructions"
                    defaultValue={solution.instructions}
                    rules={{ required: true }}
                    // invalid={errors.name ? true : undefined}
                    // helpText={errors.name?.type === 'required' ? 'This field is required' : ''}
                    control={control}
                  />
                  {errors.instructions && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.instructions?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>

              {errorMessage && (
                <React.Fragment>
                  <div className="se-pb-md"></div>
                  <Notification onClose={() => setErrorMessage('')} type="negative">
                    {errorMessage}
                  </Notification>
                </React.Fragment>
              )}

              <Button className="se-mt-md" isLoading={submitting} onClick={handleSubmit(onSubmit)}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }

  return <SolutionPageLayout renderer={contentRenderer} />
}
