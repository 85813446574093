import React from 'react'
import { useHistory } from 'react-router-dom'
import { Icon, Link } from '@storaensods/seeds-react'

import SolutionPageLayout from './Base'
import { TeamsLink } from '../../components'

export default () => {
  const history = useHistory()

  return (
    <SolutionPageLayout description="Join the discussion">
      <p>
        You can ask your questions, answer other people’s questions and join the discussions
        regarding this solution from this Teams channel dedicated to this solution.
      </p>
      <TeamsLink text="Get in-person support" />
    </SolutionPageLayout>
  )
}
