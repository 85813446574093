import React, { useEffect, useRef, useState } from 'react'

import {
//   TabBar, TabItem,
//   NavigationBar,
  Popover,
} from '@storaensods/seeds-react'

import './styles.sass'

type Props = {
  children: any
  component: any
  position: string
}

// TODO: fixing position top
export default ({ position, children, component, }: Props) => {
  const [isOpen, setIsOpen]: [boolean, any] = useState(false)
  const [popWidth, setPopWidth]: [number, any] = useState(0)
  const [popHeight, setPopHeight]: [number, any] = useState(0)
  const [hoverWidth, setHoverWidth]: [number, any] = useState(0)
  const [marginLeft, setMarginLeft]: [number, any] = useState(0)
  const ref: any = useRef(null)
  const hoverRef: any = useRef(null)

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }

  const parent = <div
    onMouseEnter={handleMouseEnter}
    ref={hoverRef}
  >
    {component}
  </div>

  useEffect(() => {
    setPopWidth(ref.current ? ref.current.offsetWidth : 0)
    setPopHeight(ref.current ? ref.current.offsetHeight : 0)
    setHoverWidth(hoverRef.current ? hoverRef.current.offsetWidth : 0)
    setMarginLeft(
      ['top', 'bottom'].indexOf(position) >= 0 ?
        (popWidth - hoverWidth) / 2
        : (
          position === 'left' ? (popWidth + 5)
          : (-1 * (hoverWidth + 5))
        )
    )
  }, [ref.current, hoverRef.current, isOpen]);
  const marginTop: number = position === 'top' ? 95 : -5
  const realPopPosition: string|undefined = ({
    'top': 'bottom',
    'bottom': 'top',
    'left': 'left',
    'right': 'right'
  } as any)[position]

  return (
    <div onMouseLeave={handleMouseLeave} style={{ position: 'relative'}}>
      {parent}
      <div style={{ width: `${popWidth || 999}px`, height: `${popHeight || 300}px`, zIndex: isOpen ? 9 : -1, position: 'absolute', overflow: 'visible' }}>
        <div ref={ref} style={{ width: `${popWidth || 999}px`, maxWidth: '300px', position: 'absolute', marginLeft: `-${marginLeft}px`, margin: '5px', marginTop: `-${marginTop}px`, zIndex: isOpen ? 10 : -1 }}>
          {
            <div className={`se-tooltip se-tooltip--${realPopPosition}`} style={{ visibility: isOpen ? 'visible' : 'hidden', maxWidth: '300px' }}>
              {children}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
