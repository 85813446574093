import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw, ContentState, Modifier } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import { Toggle } from '@storaensods/seeds-react'

import './styles.sass'

type Props = {
  name: string
  placeholder: string
  value?: string
  onChange?: (value: string | undefined) => void
}

export default ({ name, placeholder, onChange, ...rest }: Props) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromRaw(markdownToDraft(rest?.value || ''))),
  )
  const [value, setValue] = useState(rest?.value)
  const [showEditorCode, setShowEditorCode] = useState(false)

  const onEditEditorMarkdown = (newValue: string) => {
    setValue(newValue)
    if (onChange) onChange(newValue)
    setEditorState(EditorState.createWithContent(convertFromRaw(markdownToDraft(newValue || ''))))
  }

  const onEditorStateChange = (state: any) => {
    setEditorState(state)
    const newValue = draftToMarkdown(
      // convertToRaw(editorState.getCurrentContent()),
      convertToRaw(state.getCurrentContent()),
      {
        entityItems: {
          EMBEDDED_LINK: {
            open: function (entity: any) {
              // console.log('entity', entity)
              return ''
            },
            close: function (entity: any) {
              console.log('entity', entity)
              return `<video width="${entity?.data?.width}" height="${entity?.data?.height}" controls>
                  <source src="${entity?.data?.src}" />
              </video>`
          //     return '</span>';
            }
          }
        }
      }
    )
    console.log('raw3', newValue)
    setValue(newValue)
    if (onChange) onChange(newValue)
  }

  const onToggleEditorCode = (e: any) => {
    setShowEditorCode(!showEditorCode)
  }

  return (
    <>
      <input type="hidden" name={name} {...rest} value={value} />
      <Editor
        editorState={editorState}
        wrapperClassName={'se-wysiwyg-editor-wrapper'}
        editorClassName={'se-wysiwyg-editor'}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            'inline',
            'blockType',
            // 'fontSize',
            'list',
            // 'image',
            // 'textAlign',
            // 'colorPicker',
            'link',
            'embedded',
            // 'emoji', 'image',
            'remove',
            'history',
          ],
        }}
        // blockRendererFn={myBlockRenderer}
        // toolbarCustomButtons={[<AddVideo />]}
      />
      <div className="se-mt-md d-flex">
        <Toggle isActive={showEditorCode} onChange={onToggleEditorCode} />
        <h6 className="align-self-center se-ml-md se-mb-0">View Markdown code</h6>
      </div>
      <small className="se-form-help">
        The current format also support HTML code, switch to Markdown to input the code.
      </small>
      {showEditorCode && (
        <textarea
          className="se-mt-md"
          style={{ width: '100%', height: '300px' }}
          value={value}
          onChange={(e: any) => onEditEditorMarkdown(e.target.value)}
        />
      )}
    </>
  )
}
