import React from 'react'

import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'

type Props = {
  options: { label: string; value: string }[]
  creatable?: boolean
  style?: object
  className?: string
}

export default ({ options, creatable, ...rest }: Props) => {
  const Wrapper: any = creatable ? CreatableSelect : Select

  return (
    <Wrapper
      isClearable
      options={options}
      placeholder="Input or select an option"
      styles={{
        control: (provided: any, state: any) => ({
          ...provided,
          border: `1px solid ${state.isFocused ? '#118bd6' : '#9599a3'}`,
          borderRadius: 0,
          background: 'white',
          // color: state.isSelected ? 'red' : 'blue',
          padding: '2px',
        }),
        placeholder: (provided: any, state: any) => ({
          ...provided,
          color: '#515151',
        }),
        indicatorSeparator: (provided: any, state: any) => ({
          ...provided,
          visibility: 'hidden',
        }),
        dropdownIndicator: (provided: any, state: any) => ({
          ...provided,
          color: '#515151',
        }),
        menu: (provided: any, state: any) => ({
          ...provided,
          borderRadius: 0,
          background: 'white',
          border: `1px solid #9599a3`,
          padding: 0,
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          color: '#515151',
          backgroundColor: state.isFocused ? 'rgba(195, 226, 245, 0.3)' : 'white',
        }),
      }}
      {...rest}
    />
  )
}
