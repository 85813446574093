import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Header, Button, Footer, Modal } from '@storaensods/seeds-react'

type Props = {
  actionText?: string
  confirmationContent: any
  confirmationTitle?: string
  disabled?: boolean
  loading?: boolean
  onClick: any
  size?: string
  children: any
  type?: string
  flat?: boolean
  // [key: string]: any,
}

const defaultProps = {
  confirmationTitle: 'Confirmation required',
  actionText: 'Confirm',
  disabled: false,
  loading: false,
  size: 'md',
  type: 'primary',
  flat: false,
}

const ConfirmationButton = ({
  actionText,
  confirmationContent,
  confirmationTitle,
  disabled,
  loading,
  onClick,
  children,
  flat,
  ...rest
}: Props) => {
  const [state, setState]: [boolean, any] = useState(false)

  return (
    <div style={{ display: 'inline-block' }}>
      <Modal
        active={state}
        actions={[
          {
            label: actionText,
            onClick: () => {
              onClick()
              setState(false)
            },
          },
        ]}
        onClose={() => setState(false)}
        title={confirmationTitle}
      >
        <div
          style={{
            maxWidth: '30rem',
          }}
        >
          {confirmationContent}
        </div>
      </Modal>
      <Button flat={flat} disabled={disabled} isLoading={loading} {...rest} onClick={() => setState(true)}>
        {children}
      </Button>
    </div>
  )
}

ConfirmationButton.defaultProps = defaultProps

export default ConfirmationButton
