import React from 'react'

// import {
//   TabBar, TabItem,
//   NavigationBar,
// } from '@storaensods/seeds-react'

import './styles.sass'

type Props = {
  children: any
  color?: 'blue' | 'green' | 'yellow'
  className?: string
}

export default ({ children, color, className }: Props) => (
  <div className={`se-pl-sm se-mb-lg cvp-title cvp-title-${color || 'blue'} ${className}`}>{children}</div>
)
