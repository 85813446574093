interface Token {
  authenticationToken: string
  user: {
    userId: string
  }
}

interface AccessToken {
  access_token: string
  expires_on: string
  id_token: string
  provider_name: string
  refresh_token: string
  user_claims: {
    typ: string
    val: string
  }[]
  user_id: string
}

export class AzureAD {
  // session token
  public static token: Token | undefined = undefined
  get token(): Token | undefined {
    return AzureAD.token
  }
  set token(val: Token | undefined) {
    AzureAD.token = val
  }
  // access token
  public static accessToken: AccessToken | undefined = undefined
  get accessToken(): AccessToken | undefined {
    return AzureAD.accessToken
  }
  set accessToken(val: AccessToken | undefined) {
    AzureAD.accessToken = val
  }

  private endpoint: string =
    process.env.NODE_ENV === 'development' ? 'https://se-cvp-dev.azurewebsites.net' : 'https://se-cvp-dev.azurewebsites.net'
  private redirectEndpoint: string =
    process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://cvp.storaenso.com'

  public login = async () => {
    ;(window as any).location = `${this.endpoint}/.auth/login/aad?post_login_redirect_url=${this.redirectEndpoint}/home`
  }

  public logout = async () => {
    ;(window as any).location = `${this.endpoint}/.auth/logout?post_logout_redirect_uri=${this.redirectEndpoint}/`
  }

  public fetchAccessToken = async () => {
    const response = await fetch(`${this.endpoint}/.auth/me`, {
      credentials: 'include',
    })
    if (!response.ok) {
      throw Error('Failed fetching access token')
    }
    this.accessToken = (await response.json()).find((p: AccessToken) => p.provider_name === 'aad')
  }

  public refreshAccessToken = async () => {
    const response = await fetch(`${this.endpoint}/.auth/refresh`, {
      credentials: 'include',
    })
    if (!response.ok) {
      throw Error('Failed refreshing access token')
    }
  }

  public validate = async () => {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken?.access_token}`,
    })
    const response = await fetch(`${this.endpoint}/.auth/login/aad`, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        access_token: this.accessToken?.access_token,
        id_token: this.accessToken?.id_token,
      }),
      headers,
    })
    if (!response.ok) {
      throw Error('Invalid access token')
    }
    const json = await response.json()
    this.token = json
  }
}

const aad = new AzureAD()

export default aad
