import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Button, Input, TextArea, Hero, Tile } from '@storaensods/seeds-react'

import CVPApi from '../services/cvp'

import { Page } from '../components'

import './styles/home.sass'
import hero from '../assets/img/hero.png'

export default () => {
  const history = useHistory()
  const [authenticating, setAuthenticating] = useState(true)

  // cdm
  useEffect(() => {
    const authenticate = async () => {
      const token = await CVPApi.fetchToken()
      if (token) history.push(`/home`)

      setAuthenticating(false)
    }

    authenticate()
  }, [])

  if (authenticating) {
    return <></>
  }

  return (
    <Page>
      <Hero image={hero}>
        <Tile color="yellow" size="xl">
          <h3>Shared Computer Vision Solutions</h3>
          <p>
            Use existing shared solutions from the library or create, train, deploy new ones for
            others to use.
          </p>
        </Tile>
      </Hero>
      <div className="se-pl-xl se-pr-xl se-pt-xl se-pb-xl">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <p>Login with your Stora Enso credentials</p>
            <Button onClick={CVPApi.login}>Login</Button>
            <div className="se-pb-md"></div>
          </div>
          {/* <div className='col-sm-12 offset-md-1 col-md-7 col-lg-4 col-xl-3'>
            <p>Request access if you don't have.</p>
            <Input label='Your email:' />
            <div className='se-pb-md'></div>
            <TextArea label='Reason:' />
            <div className='se-pb-xl'></div>
            <Button>
              Send Request
            </Button>
          </div> */}
        </div>
      </div>
    </Page>
  )
}
