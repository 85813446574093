import React from 'react'

// import {
//   TabBar, TabItem,
//   NavigationBar,
// } from '@storaensods/seeds-react'

import TeamsIcon from './microsoft-teams.png'

type Props = {
  link?: string
  text?: string
}

const DEFAULT_LINK = "https://teams.microsoft.com/l/team/19%3ac46c46d6ffcb4c48901ee30ba1a67e08%40thread.tacv2/conversations?groupId=a37cbcd2-7ce0-4e48-9fc6-be512098ad6a&tenantId=75998ea3-790c-40ea-ac1e-02ee8edfb00f"
const DEFAULT_TEXT = "Our help channel"

export default ({ link = DEFAULT_LINK, text = DEFAULT_TEXT }: Props) => (
  <div className="goto-container">
    <a className="se-link" href={link} target="__blank">
      <img src={TeamsIcon} style={{ marginRight: '10px' }} alt="cvp-teams-link" />
      <span>{text}</span>
    </a>
  </div>
)
