import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Button, Dropdown, Notification, Link } from '@storaensods/seeds-react'

import SolutionPageLayout from './Base'
import { Popover, Table, Title } from '../../components'

import CVPApi from '../../services/cvp'

import { Solution, AIModel, AIModelStatus } from '../../types'
import { onlyPackageModels, downloadLink, onlyAvailableModels, onlyDeployedModels, downloadBlob } from './actions'
import _ from 'lodash'
import { DEFAULT_DECIMAL_PLACES, MILLS } from '../../config'
import moment from 'moment'

export default () => {
  const history = useHistory()
  const { register, handleSubmit, watch, errors, control } = useForm()
  const match: any = useRouteMatch('/library/:slug/:page')
  const { slug, page } = match?.params

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [downloading, setDownloading] = useState(false)

  const downloadModel = (model_id: number) => {
    CVPApi.downloadModel(slug, model_id)
      .then((response: any) => {
        console.log('downloading')
        if (typeof response === "string") {
          downloadLink(response)
        } else {
          downloadBlob(response["blob"])
        }
      })
      .catch((error: Error) => {
        // setErrorMessage(error.message)
      })
      .finally(() => {
        // setDownloading(false)
      })

  }

  const onStartPackaging = (data: any) => {
    setDownloading(true)
    CVPApi.requestPackaging(slug, data?.model?.value)
      .then((response) => {
        window.location.reload()
        // setSuccessMessage('Package for the model will be available soon!')
      })
      .catch((error: Error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const currentModel = models.find((m) => watch('model')?.value === m.id)
    const availableModels = models.filter((m) => m.has_package)
    const finalMetrics = onlyAvailableModels(models || []).length > 0 ? Object.keys(onlyAvailableModels(models)[0].final_metrics).splice(0, 2) : []

    return (
      <>
        <div className="row se-mb-md">
          <div className="col-md-12">

            <Title>
              <h6>Get package:</h6>
            </Title>
            <form onSubmit={handleSubmit(onStartPackaging)}>
              <div className="row se-mb-md">
                <div className="col-md-7 col-xs-12 col-sm-12">
                  <Controller
                    as={
                      <Dropdown
                        label="Select model"
                        options={onlyDeployedModels(models).filter(m => !m.has_package).map(
                          (m: AIModel) => ({
                            value: m.id,
                            label: <div className="se-row-item">
                              <div className="se-row-placeholder">{m.id}</div>
                              <div className="se-row-content">
                                <span className="se-row-content--left">
                                  <strong>
                                    {m.name}
                                  </strong> - ver {m.version}
                                </span>
                                <span className="se-row-content--right">
                                  {`${moment.utc(m.created_at).format("L LT")}`}
                                </span>
                              </div>
                            </div>,
                          }),
                        )}
                      />
                    }
                    onChangeName="onSelect"
                    rules={{ required: true }}
                    name="model"
                    control={control}
                  />
                  {errors.model ? (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.model?.type === 'required' && 'This field is required'}
                    </small>
                  ) : (
                    currentModel && (
                      <small className="se-form-help">
                        <strong>Name:</strong> {currentModel.name}
                        <br />
                        <strong>Description:</strong> {currentModel.description}
                        <br />
                        <strong>Mill:</strong> {currentModel.mill}
                        <br />
                      </small>
                    )
                  )}
                </div>
              </div>

              <>
                <Button className="se-mt-md" isLoading={downloading} onClick={handleSubmit(onStartPackaging)}>
                  Start packaging
                </Button>
                <div className="row">
                  <div className="col-md-8 se-mt-md">
                    <small className={`se-form-help`}>
                      The packaging would take 10-15 minutes to finish. Then it is available to select and download.
                    </small>
                  </div>
                </div>
              </>

              {errorMessage && (
                <React.Fragment>
                  <div className="se-pb-md"></div>
                  <Notification onClose={() => setErrorMessage('')} type="negative">
                    {errorMessage}
                  </Notification>
                </React.Fragment>
              )}
              {successMessage && (
                <React.Fragment>
                  <div className="se-pb-md"></div>
                  <Notification onClose={() => setErrorMessage('')} type="positive">
                    {successMessage}
                  </Notification>
                </React.Fragment>
              )}

            </form>


            <p className="se-mt-md"></p>
            <Title>
              <h6>System requirements:</h6>
            </Title>
            <p className="se-mb-md">
              {/* Solution package is available for Windows.  */}
              Following the <a href={`/library/${solution.id}/instructions`}>setup instructions</a> is a
              prequisite for the package to work.
            </p>
            {/* <p className="se-mb-md">
              OS: Windows
              <br />
              Processor: N/A
              <br />
              RAM: N/A
              <br />
              Additional software: N/A
            </p> */}

            {
              availableModels.length > 0 && (
                <div className="se-mb-2xl se-mt-2xl">
                  <Title>
                    <h6>Available packages to download:</h6>
                  </Title>

                  <Table
                    headers={[
                      ['Name', 200],
                      ...(finalMetrics && finalMetrics.length >= 2 ?
                        finalMetrics.map((metric: string) => (
                          [({
                            "Average precision": "Precision",
                            "Average recall": "Recall",
                            "Pixel accuracy": "Accuracy",
                            "Average pixel F1 score": "F1 score",
                            "Accuracy": "Accuracy",
                            "F1 score": "F1 score",
                          } as any)[metric] || metric,
                          100] as any
                        ))
                        : ([
                        '',
                        ''
                      ])),
                      ['Mill', 100],
                      ['Created By', 200],
                      ['Created At', 200],
                      ['', 100],
                    ]}
                    content={availableModels
                      .sort((a, b) => b.id - a.id)
                      .map((model) => {
                        const isDeployed =
                          [AIModelStatus.DEPLOYED, AIModelStatus.COMPLETED].indexOf(model.status) >= 0
                        return {
                          // eslint-disable-next-line no-sparse-arrays
                          content: [
                            [
                              <strong className={isDeployed ? 'se-color-info' : ''}>{model.name} - v{model.version}</strong>,
                              <br />,
                              <span className={isDeployed ? 'se-color-info' : ''}>
                                {model.description}
                              </span>,
                            ],
                            ...finalMetrics && finalMetrics.length >= 2 ? finalMetrics.map((metric_key: string) => (
                              <Popover
                                component={
                                  <span className={isDeployed ? 'se-color-info' : ''}>
                                    {model?.final_metrics[metric_key]?.toFixed(DEFAULT_DECIMAL_PLACES)}
                                  </span>
                                }
                                position="bottom"
                              >
                                <div className="cvp-model-status-popover-content">
                                  {
                                    ({
                                      'classification': {
                                        'Accuracy': 'A ratio of correctly predicted observation to the total observations.',
                                        'F1 score': "Harmonic mean of the model's precision and recall. It's a better measure of the incorrectly classified cases.",
                                      },
                                      'object_detection': {
                                        'Average precision': 'A ratio of correctly detected and classified objects to the total number of detected objects averaged over classes and different confidence thresholds.',
                                        'Average recall': 'A ratio of correctly detected and classified objects to the total number of objects present in the data averaged over classes.',
                                      },
                                      'semantic_segmentation': {
                                        'Pixel accuracy': 'A ratio of correctly classified pixels to the total number of pixels.',
                                        'Average pixel F1 score': 'A harmonic mean of the model’s pixel precision and pixel recall.',
                                      },
                                      'instance_segmentation': {
                                        'Average precision': 'A ratio of correctly detected and classified objects to the total number of detected objects averaged over classes and different confidence thresholds.',
                                        'Average recall': 'A ratio of correctly detected and classified objects to the total number of objects present in the data averaged over classes.'
                                      },
                                    }[solution.type] as any )[metric_key]
                                  }
                                </div>
                              </Popover>
                            )) : ['', ''],
                            <span className={isDeployed ? 'se-color-info' : ''}>
                              {MILLS[model?.mill || '']}
                            </span>,
                            <span className={isDeployed ? 'se-color-info' : ''}>
                              {model?.owner?.email}
                            </span>,
                            moment.utc(model.created_at).format("L LT"),
                            <Button
                              type="primary"
                              onClick={() => downloadModel(model.id)}
                              key="1"
                            >
                              Get package
                            </Button>,
                          ],
                        }
                      })}
                  />
                </div>
              )
            }

          </div>
        </div>
      </>
    )
  }

  return (
    <SolutionPageLayout
      renderer={contentRenderer}
      description={'Download the solution to deploy to your system'}
    />
  )
}
