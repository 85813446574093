import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router'

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event: any) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

// Hook
export function useExitPrompt(bool: boolean): [boolean, any, any] {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);


  window.onload = function() {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [
    showExitPrompt,
    // eslint-disable-next-line react/react-in-jsx-scope
    <Prompt
      when={showExitPrompt}
      message={location =>
        `Are you sure you want to go to ${location.pathname}`
      }
    />,
    setShowExitPrompt
  ];
}
