import { AIModel, AIModelStatus } from '../../types'

export const onlyAvailableModels = (models: AIModel[]) => {
  return models.filter(
    (m) => [
      AIModelStatus.DEPLOYING,
      AIModelStatus.DEPLOYED,
      AIModelStatus.COMPLETED,
      AIModelStatus.REGISTERED,
      AIModelStatus.STOPPED,
    ].indexOf(m.status) >= 0,
  )
}

export const onlyDeployedModels = (models: AIModel[]) => {
  return models.filter(
    (m) =>
      [
        AIModelStatus.DEPLOYED,
        AIModelStatus.STOPPED,
        // AIModelStatus.COMPLETED
      ].indexOf(m.status) >= 0,
  )
}

export const nonFailedModels = (models: AIModel[]) => {
  return models.filter((m) => [AIModelStatus.FAILED].indexOf(m.status) < 0)
}

export const onlyPackageModels = (models: AIModel[]) => {
  return models.filter((m) => [AIModelStatus.PACKAGED].indexOf(m.status) >= 0)
}

export const downloadLink = (file_path: string) => {
  let a: any = document.createElement('A')
  a.href = file_path
  a.target = '_parent'
  // a.download = file_path.substr(file_path.lastIndexOf('/') + 1)
  a.download = file_path
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const downloadBlob = (blob: any, filename?: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  if (filename)
    a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

interface State {
  steps: { message: string; status: any }[]
  submitting: boolean
  errorMessages: string[]
  uploadingFiles?: number
  totalFiles?: number
}

export enum TrainingAction {
  UPDATE_TRAINING_STATUS = 'UPDATE_TRAINING_STATUS',
  UPDATE_SUBMITTING_STATUS = 'UPDATE_SUBMITTING_STATUS',
  UPDATE_ERROR_MESSAGES = 'UPDATE_ERROR_MESSAGES',
  RESET_ERROR = 'RESET_ERROR',
  UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS',
  MARK_UPLOAD_FILE_DONE = 'MARK_UPLOAD_FILE_DONE',
}

export const trainingReducer = (state: State, action: any) => {
  switch (action.type) {
    case TrainingAction.UPDATE_TRAINING_STATUS: {
      const _newSteps = [...state.steps]
      _newSteps[action.step].status = action.status
      return {
        ...state,
        steps: _newSteps,
      }
    }
    case TrainingAction.UPDATE_SUBMITTING_STATUS: {
      return {
        ...state,
        submitting: action.status,
      }
    }
    case TrainingAction.UPDATE_ERROR_MESSAGES: {
      return {
        ...state,
        errorMessages: action.messages,
      }
    }
    case TrainingAction.RESET_ERROR: {
      return {
        ...state,
        errorMessages: [],
      }
    }
    case TrainingAction.UPDATE_UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadingFiles: action.uploadingFiles,
        totalFiles: action?.totalFiles || state.totalFiles,
      }
    }
    case TrainingAction.MARK_UPLOAD_FILE_DONE: {
      return {
        ...state,
        uploadingFiles:
          typeof state.uploadingFiles === 'number' ? state.uploadingFiles + 1 : undefined,
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}
