import React, { useEffect, useState, useReducer } from 'react'
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import {
  Button,
  Input,
  Notification,
  Dropdown,
  Link,
  Icon,
  Spinner,
  Toggle,
  RoundProgressIndicator,
} from '@storaensods/seeds-react'

import SolutionPageLayout from './Base'
import { Title, FileField, CreatableSelect, Popover } from '../../components'
import { Solution, AIModel, TrainingStepStatus, SolutionType, Dataset, DatasetStatus } from '../../types'

import CVPApi from '../../services/cvp'
import UploadService from '../../services/upload'

import AdvancedOptions from './Form/AdvancedOptions'
import DataUploadOptions from './Form/DataUploadOptions'
import { FORM_CONFIG, MILLS } from '../../config'
import { asyncForEach } from '../../lib/utils'
import { onlyAvailableModels, trainingReducer, TrainingAction } from './actions'
import { useExitPrompt } from '../../lib/hooks'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/library/:slug/:page')
  const { slug, page } = match?.params
  const formUtils = useForm()
  const { register, handleSubmit, watch, errors, control, setValue, formState } = formUtils
  const [showExitPrompt, exitPrompt, setShowExitPrompt]: [boolean, any, any] = useExitPrompt(false)


  const [{ steps, submitting, errorMessages, uploadingFiles, totalFiles }, dispatch]: [
    any,
    any,
  ] = useReducer(trainingReducer, {
    steps: [
      { message: 'Uploading dataset', status: TrainingStepStatus.INIT },
      { message: 'Queuing model to AzureML for training', status: TrainingStepStatus.INIT },
    ],
    submitting: false,
    errorMessages: [],
  })

  const onSubmit = async (data: any) => {
    console.log('data', data);
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: true })
    dispatch({
      type: TrainingAction.UPDATE_UPLOAD_PROGRESS,
      uploadingFiles: undefined,
      totalFiles: undefined,
    })
    let data_path = data?.data_path

    // 1. Uploading dataset
    dispatch({
      type: TrainingAction.UPDATE_TRAINING_STATUS,
      step: 0,
      status: TrainingStepStatus.LOADING,
    })
    let uploadRequest
    let dataset: Dataset
    try {
      uploadRequest = await CVPApi.requestDatasetUpload()
    } catch (error) {
      dispatch({ type: TrainingAction.UPDATE_ERROR_MESSAGES, messages: [error.message] })
      dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
      return
    }
    const uploadService = new UploadService(
      uploadRequest.token,
      uploadRequest.account_name,
      uploadRequest.container_name,
      uploadRequest.blob_path,
    )

    const classNames = data.classes.filter(Boolean),
      files = data.files.filter(Boolean)
    let _errorMessages: (string | undefined)[] = []
    // Mark as started uploading data
    if (!data.data_path) {
      try {
        dataset = await CVPApi.createDataset({
          payload: {
            solution_id: data.solution_id,
            data_path: uploadRequest.blob_path,
            container_name: uploadRequest.container_name,
            mill: data?.mill?.value,
          }
        })
        await CVPApi.updateDataset(dataset.id, {
          payload: {
            status: DatasetStatus.UPLOADING
          }
        })
        // await CVPApi.markSolutionUpload(slug, {
        //   payload: {
        //     data_path: uploadRequest.blob_path,
        //     container_name: uploadRequest.container_name,
        //   },
        // })
      } catch (error) {
        dispatch({
          type: TrainingAction.UPDATE_TRAINING_STATUS,
          step: 0,
          status: TrainingStepStatus.ERROR,
        })
        dispatch({
          type: TrainingAction.UPDATE_ERROR_MESSAGES,
          messages: ['Failed to start uploading data set.'],
        })
        dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
        return
      }
      dispatch({
        type: TrainingAction.UPDATE_UPLOAD_PROGRESS,
        uploadingFiles: 0,
        totalFiles: files.reduce((t: number, curr: FileList) => (t += curr.length), 0),
      })
      await asyncForEach(classNames, async (className: string | undefined, index: number) => {
        // no data to upload
        if (!className) return
        if (className === 'annotations') {
          Object.defineProperty(files[index][0], 'name', {
            writable: true,
            value: 'instances_default.json'
          });
        }

        await uploadService.uploadParallel(
          files[index],
          {
            toDir: className,
          },
          ({ success, error }) => {
            if (error) {
              if (
                error.error?.details?.Code &&
                error?.details?.Code === 'UnauthorizedBlobOverwrite'
              ) {
                // auto skip existed file
                dispatch({ type: TrainingAction.MARK_UPLOAD_FILE_DONE })
                // return `Error uploading '${blockName}' (File existed)`
              } else if (error.error?.details?.Code) {
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              } else {
                // unknown or un-catch error
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              }
            }
            if (success) {
              dispatch({ type: TrainingAction.MARK_UPLOAD_FILE_DONE })
            }
          },
        )
      })
      dispatch({
        type: TrainingAction.UPDATE_ERROR_MESSAGES,
        messages: _errorMessages.filter(Boolean),
      })
      if (_errorMessages.length !== 0) {
        dispatch({
          type: TrainingAction.UPDATE_TRAINING_STATUS,
          step: 0,
          status: TrainingStepStatus.ERROR,
        })
        dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
        return
      } else {
        try {
          await CVPApi.updateDataset(dataset.id, {
            payload: {
              status: DatasetStatus.UPLOADED
            }
          })
        } catch (error) {
          dispatch({
            type: TrainingAction.UPDATE_TRAINING_STATUS,
            step: 0,
            status: TrainingStepStatus.ERROR,
          })
          dispatch({
            type: TrainingAction.UPDATE_ERROR_MESSAGES,
            messages: ['Failed to upload data set.'],
          })
          dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
          return
        }
        data_path = dataset.path
        dispatch({
          type: TrainingAction.UPDATE_TRAINING_STATUS,
          step: 0,
          status: TrainingStepStatus.DONE,
        })
      }
    } else {
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 0,
        status: TrainingStepStatus.DONE,
      })
    }

    // 2. Training request
    dispatch({
      type: TrainingAction.UPDATE_TRAINING_STATUS,
      step: 1,
      status: TrainingStepStatus.LOADING,
    })
    try {
      await CVPApi.train(slug, {
        payload: {
          ...Object.assign(
            {},
            ...Object.keys(data).map((k) => ({
              [k]:
                typeof data[k] === 'object' && data[k]?.value
                  ? data[k].value
                  : isNaN(data[k])
                  ? data[k]
                  : +data[k],
            })),
          ),
          classes: data?.type === SolutionType.CLASSIFICATION ? classNames : [],
          mill: data?.mill?.value,
          enable_advanced_options: undefined,
          files: undefined,
          // split_fraction: data?.data_split.indexOf('test') >= 0 ? data?.split_fraction : 0,
          data_path,
        },
      })
      setShowExitPrompt(false)
      history.push(`/library/${slug}/`)
    } catch (error) {
      dispatch({
        type: TrainingAction.UPDATE_TRAINING_STATUS,
        step: 1,
        status: TrainingStepStatus.ERROR,
      })
      dispatch({
        type: TrainingAction.UPDATE_ERROR_MESSAGES,
        messages: ['Failed to queue new model.'],
      })
    }
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
  }

  // cdm
  useEffect(() => {}, [])
  useEffect(() => {
    setShowExitPrompt(formState.dirty)
  }, [formState.dirty])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const form_config = (FORM_CONFIG as any)[solution?.type]
    return (
      <React.Fragment>
        <form className="se-pb-2xl">
          {exitPrompt}
          {/* <div className='row'>
            <div className='col-md-5'>
              <Title><h6>Model's name:</h6> </Title>
              <Controller
                as={<Input />}
                rules={{ required: true }}
                name='model_name'
                invalid={errors.model_name ? true : undefined}
                helpText={errors.model_name?.type === 'required' ? 'This field is required' : ''}
                control={control}
              />
            </div>
          </div> */}

          <Controller
            as={
              <input type='hidden' />
            }
            defaultValue={solution.type}
            rules={{ required: true }}
            name="type"
            value={solution.type}
            control={control}
          />

          {
            solution?.base_model_info?.mill ? (
              <Controller
                as={
                  <input type='hidden' />
                }
                defaultValue={{ value: solution?.base_model_info?.mill }}
                rules={{ required: true }}
                name="mill"
                control={control}
              />
            ) : (
              <div className="row">

                <div className="col-md-12">
                  <Title>
                    <Popover
                      component={
                        <h6>Select mill:</h6>
                      }
                      position="bottom"
                    >
                      Name of the mill the model is developed for
                    </Popover>
                  </Title>
                </div>
                <div className="col-md-5">
                  <Controller
                    as={
                      <Dropdown
                        options={
                          Object.keys(MILLS).map((mKey: string) => ({
                            label: MILLS[mKey],
                            value: mKey,
                          })) as { label: string; value: string }[]
                        }
                      />
                    }
                    rules={{ required: true }}
                    name="mill"
                    control={control}
                  />
                  {errors.mill && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.mill?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>
            )
          }

          {
            solution?.base_model_info?.model_name ? (
              <Controller
                as={
                  <input type='hidden' />
                }
                defaultValue={solution?.base_model_info?.model_name}
                rules={{ required: true }}
                name="description"
                control={control}
              />
            ) : (
              <div className="row se-mt-2xl">
                <div className="col-md-5">
                  <Title>
                    <Popover
                      component={
                          <h6>Model name:</h6>
                      }
                      position="bottom"
                    >
                      Name of the use case at the defined mill
                    </Popover>
                  </Title>
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    name="description"
                    placeholder="Model name"
                    helpText={errors.description?.type === 'required' ? 'This field is required' : ''}
                    control={control}
                  />
                </div>
              </div>
            )
          }

          <DataUploadOptions
            solution={solution}
            {...formUtils}
          />

          <div className="row se-mt-2xl">
            <div className="col-md-5">
              <Title>
                <Popover
                  component={
                    <h6>Select architecture:</h6>
                  }
                  position="bottom"
                >
                  Each setting has a default value, which can be modified as desired. If user does not enable the advanced settings, the default values are used for the training.
                </Popover>
              </Title>
              <Controller
                as={
                  <Dropdown
                    // label='Select model'
                    // size='sm'
                    options={form_config.architecture.options}
                  />
                }
                onChangeName="onSelect"
                defaultValue={form_config.architecture.default}
                rules={{ required: true }}
                name="architecture"
                control={control}
              />
              {errors.architecture && (
                <small className="se-form-help se-form-help--invalid">
                  {errors.architecture?.type === 'required' && 'This field is required'}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 se-mt-md">
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </p> */}
              <Link href="/kb/training-architecture-and-advanced-options" target="__blank">
                <Icon color="blue">
                  chevron_right
                </Icon>
                <span>Learn more</span>
              </Link>
            </div>
          </div>

          <AdvancedOptions
            register={register}
            watch={watch}
            errors={errors}
            control={control}
            field_config={form_config.advanced_options}
          />

          <Button
            className="se-mt-md"
            disabled={submitting}
            isLoading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            Train
          </Button>
          <div className="row">
            <div className="col-md-8 se-mt-md">
              <small className={`se-form-help`}>
                The training will take 15-45 minutes to start running. The whole process can take
                hours to finish.
              </small>
            </div>
          </div>

          {submitting && steps.length > 0 && (
            <div className='row se-mt-md'>
              <div className="col col-md-2">
                <RoundProgressIndicator
                  height={75}
                  mode="regular"
                  step={
                    steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE) >= 0 ?
                    steps.length - steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE)
                    : 0
                  }
                  steps={steps.length}
                />
              </div>
              <div className="col">
                {steps.map((step: any, stepIndex: number) => (
                  <p key={stepIndex}>
                    {step.status === TrainingStepStatus.LOADING && (
                      <Spinner className="training-spinner" />
                    )}
                    {step.status === TrainingStepStatus.INIT && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.ERROR && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.DONE && <Icon>check</Icon>} {step.message}{' '}
                    {stepIndex === 0 &&
                      uploadingFiles &&
                      totalFiles &&
                      `:${uploadingFiles}/${totalFiles}`}
                  </p>
                ))}
              </div>
            </div>
          )}

          {errorMessages.length > 0 && (
            <React.Fragment>
              <div className="se-pb-md"></div>
              <Notification
                onClose={() => dispatch({ type: TrainingAction.RESET_ERROR })}
                type="negative"
              >
                {errorMessages.map((m: string, index: number) => (
                  <p key={index}>{m}</p>
                ))}
              </Notification>
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
    )
  }

  return (
    <SolutionPageLayout
      renderer={contentRenderer}
      description={'Train the solution to match your needs'}
    />
  )
}
