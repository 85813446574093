import React, { useEffect, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Toggle,
  Tag,
  Input,
  Dropdown,
  Link,
  Button,
  Icon,
} from '@storaensods/seeds-react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'

import { MainTabbedPage, Popover } from '../components'

import CVPApi from '../services/cvp'
import { Solution, SolutionStatus, SolutionType } from '../types'

import styles from './Library.module.scss'
import { MILLS } from '../config'

interface SearchData {
  search: string
  status: any
  type: any
  mill: any
}

export default () => {
  const history = useHistory()
  const [solutions, setSolutions]: [Solution[], any] = useState([])
  const [showArchived, setShowArchived]: [boolean, any] = useState(false)
  const { control, handleSubmit, formState, getValues, watch } = useForm<SearchData>()
  const [statusOptions] = useState(() => ['status', ...Object.values(SolutionStatus)])
  const [taskOptions] = useState(() => ['task', ...Object.values(SolutionType)])
  const [millOptions] = useState(() => [
    { label: 'Mill', value: 'all' },
    ...Object.entries(MILLS).map(([value, label]) => ({ label, value })),
  ])

  const watchStatus = watch('status')
  const watchType = watch('type')
  const watchMill = watch('mill')

  // cdm
  useEffect(() => {
    CVPApi.fetchSolutions()
      .then((resp: Solution[]) => {
        setSolutions(resp)
      })
      .catch((error: Error) => {
        // console.error(error)
      })
      .finally(() => {})
  }, [])

  useEffect(() => {
    const status = watchStatus?.value || watchStatus
    const type = watchType?.value
    const mill = watchMill?.value === 'all' ? undefined : watchMill?.value
    const search = getValues()?.search

    CVPApi.fetchSolutions({
      status: status === 'status' ? undefined : status,
      type: type === 'task' ? undefined : type,
      mill,
      search,
    }).then((solutions) => void setSolutions(solutions))
  }, [watchStatus, watchType, watchMill, getValues])

  const _renderSolution = (solution: any, solutionIndex: number) => {
    return (
      <div
        className={`col-md-12 col-lg-6 col-xl-5 col-sm-12 se-pb-xl ${solutionIndex % 2 !== 0 ? 'offset-xl-1 offset-lg-0 offset-sm-0 offset-md-0' : ''}`}
        key={solution.id}
      >
        <Card
          actions={[
            {
              label: 'Go to solution',
              onClick: () => history.push(`/library/${solution.id}/overview`),
            },
          ]}
          subtitle={
            <>
              {
                solution?.is_external && (
                  <Popover
                    component={
                      <Tag>imported</Tag>
                    }
                    position="bottom"
                  >
                    Imported model
                  </Popover>
                )
              } &nbsp; <Popover
                component={
                  <span>{solution.status}</span>
                }
                position="bottom"
              >
                {({
                  'draft': 'A newly created solution',
                  'in_development': 'Solution is under development',
                  'available': 'Developed and ready to be used',
                  'archived': 'Resources will be removed after 30 days automatically',
                  'deleted': 'Resources will be removed in a day',
                } as any)[solution.status]}
              </Popover>
            </>
          }
          title={''}
          type={
            ({
              [SolutionStatus.DRAFT]: 'primary',
              [SolutionStatus.IN_DEVELOPMENT]: 'attention',
              [SolutionStatus.AVAILABLE]: 'positive',
              [SolutionStatus.ARCHIVED]: 'negative',
              [SolutionStatus.DISCARDED]: 'negative',
            } as any)[solution.status] || 'attention'
          }
        >
          <div className="row" style={{ height: '200px', overflow: 'hidden' }}>
            {solution.preview_image && (
              <div className="col-md-7 col-lg-6 col-sm-12" style={{ cursor: 'pointer' }} onClick={() => history.push(`/library/${solution.id}/overview`)}>
                <div style={{
                  backgroundImage: `url(${encodeURI(solution.preview_image)})`,
                  width: '100%',
                  height: '150px',
                  backgroundSize: 'center',
                  backgroundPosition: 'center',
                }}>
                </div>
              </div>
            )}

            <div className={solution.preview_image ? 'col-md-5 col-lg-6 col-sm-12' : 'col'}>
              <h5 style={{ cursor: 'pointer' }} onClick={() => history.push(`/library/${solution.id}/overview`)}>{solution.name}</h5>
              {solution.description.split('\n').map((sentence: string, index: number) => (
                <p key={index}>{sentence}</p>
              ))}
            </div>
          </div>
        </Card>
      </div>
    )
  }

  const onSubmit = async (data: SearchData) => {
    const status = data.status?.value || data.status
    const type = data.type?.value || data.type
    const mill = data.mill?.value === 'all' ? undefined : data.mill?.value
    const solutions = await CVPApi.fetchSolutions({
      status: status === 'status' ? undefined : status,
      type: type === 'task' ? undefined : type,
      mill,
      search: data.search || '',
    })
    setSolutions(solutions)
  }

  return (
    <MainTabbedPage activeItem="Solution Library">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row se-pb-xl">
            <div className="col-md-3 col-sm-12">
              <VerticalMenuItem
                isActive
                label={<h6 className="se-mb-0">Search for existing solutions</h6>}
                className="se-mb-lg"
              />
              <div className={styles.searchContainer}>
                <Controller
                  as={<Input />}
                  name="search"
                  placeholder="search by keyword"
                  control={control}
                />
                <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                  Search
                </Button>
              </div>
            </div>
          </div>
          <div className="row se-pb-md">
            <div className="col-md-5 col-sm-12">
              <div className="row">
                <div className="col">
                  <VerticalMenuItem
                    isActive
                    label={
                      <h6 className="se-mb-0">
                        Select your task
                    </h6>
                    }
                    className="se-mb-sm"
                  />
                  <Link
                    href="/kb/supported-application-task"
                    target="__blank"
                    className="se-mb-lg"
                  >
                    <Icon color="blue">
                      chevron_right
                    </Icon>
                    <span>Learn more</span>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12 se-pb-sm">
                  <Controller
                    as={
                      <Dropdown
                        options={[
                          { label: 'task', value: 'task' },
                          { label: 'Classification', value: 'classification' },
                          { label: 'Object detection', value: 'object_detection' },
                          { label: 'Instance segmentation', value: 'instance_segmentation' },
                          { label: 'Semantic segmentation', value: 'semantic_segmentation' },
                        ]}
                      />
                    }
                    defaultValue={{ label: 'task', value: 'task' }}
                    // defaultValue={taskOptions[0]}
                    onChangeName="onSelect"
                    name="type"
                    control={control}
                  />
                </div>
                <div className="col-md-4 col-sm-12 se-pb-sm">
                  <Controller
                    as={<Dropdown options={statusOptions} />}
                    defaultValue={statusOptions[0]}
                    onChangeName="onSelect"
                    name="status"
                    control={control}
                  />
                </div>
                <div className="col-md-4 col-sm-12 se-pb-sm">
                  <Controller
                    as={<Dropdown options={millOptions} />}
                    defaultValue={millOptions[0]}
                    onChangeName="onSelect"
                    name="mill"
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <VerticalMenuItem
          href="/library"
          isActive
          label={<h6 className="se-mb-0">Shared Solution Library</h6>}
        />
        <div className="row se-pt-xl">
          <div className="col-xl-8 col-lg-12 col-md-12">
            <div className="row">
              {
                [
                  ...solutions.filter(s => s.status === SolutionStatus.AVAILABLE).sort((a: any, b: any) => b.id - a.id),
                  ...solutions.filter(s => s.status === SolutionStatus.IN_DEVELOPMENT).sort((a: any, b: any) => b.id - a.id),
                  ...solutions.filter(s => s.status === SolutionStatus.DRAFT).sort((a: any, b: any) => b.id - a.id),
                  ...solutions.filter(
                    (s: any) => (
                      [SolutionStatus.ARCHIVED, SolutionStatus.DISCARDED].indexOf(s.status) >= 0 &&
                      showArchived && CVPApi.isPlatformAdmin
                    )
                  ).sort((a: any, b: any) => b.id - a.id),
                ].map(_renderSolution)
              }
            </div>
          </div>
        </div>
      </div>
      {CVPApi.isEditable && (
        <div className="cvp-admin-panel se-pl-xl se-pr-xl se-pt-xl se-pb-xl">
          <div className="row">
            <div className="col-md-12">
              <Toggle
                isActive={showArchived}
                size="sm"
                onChange={() => setShowArchived(!showArchived)}
              >
                Show archived solutions
              </Toggle>
              <div>
                <small className="se-form-help">
                  The panel is only visible for platform admin.
                </small>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainTabbedPage>
  )
}
