import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  Divider,
  Button,
  Input,
  TextArea,
  Hero,
  Tile,
  Notification,
  Link,
  Icon,
  // Table,
} from '@storaensods/seeds-react'
import ReactMarkdown from 'react-markdown'
// @ts-ignore
import Plot from 'react-plotly.js'
import moment from 'moment'
import _ from 'lodash'

import SolutionPageLayout from '../Solution/Base'
import { Title, Table, ConfirmationButton, ModelStatus, FileField } from '../../components'

import CVPApi from '../../services/cvp'

import { Solution, AIModel, AIModelStatus, SolutionType, Dataset, DatasetStatus } from '../../types'
import { DEFAULT_DECIMAL_PLACES } from '../../config'

import './styles.sass'
import { Controller, useForm } from 'react-hook-form'
import UploadService from '../../services/upload'

const PlotName: { [key: string]: string } = {
  'loss': 'Loss function',
  'AP@IoU_0p50_area_all_maxDets_100': 'Average precision',
  'AR@IoU_0p50_0p95_area_all_maxDets_100': 'Average recall',
  'Average_F1_score': 'Average F1 score',
  'acc_glob': 'Accuracy global',
  'lr': 'Learning rate',
}

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/library/:slug/models/:model_slug')
  const { slug, model_slug } = match?.params
  const uploadImagesForm = useForm()

  const [uploadingImages, setUploadingImages]: [boolean, any] = useState(false)
  const [deploying, setDeploying]: [boolean, any] = useState(false)
  const [discarding, setDiscarding]: [boolean, any] = useState(false)
  const [archiving, setArchiving]: [boolean, any] = useState(false)
  const [stopping, setStopping] = useState<boolean>(false);
  const [errorMessage, setErrorMessage]: [string, any] = useState('')

  const onUploadTestImagesSubmit = async (data: any) => {
    let uploadRequest = undefined, dataset: Dataset|undefined = undefined
    try {
      let _errorMessages: (string | undefined)[] = []
      if (data.test_images) {
        uploadRequest = await CVPApi.requestDatasetUpload()
        const uploadService = new UploadService(
          uploadRequest.token,
          uploadRequest.account_name,
          uploadRequest.container_name,
          uploadRequest.blob_path,
        )
        dataset = await CVPApi.createDataset({
          payload: {
            solution_id: data.solution_id,
            data_path: uploadRequest.blob_path,
            container_name: uploadRequest.container_name,
            mill: data?.mill?.value,
          }
        })
        await CVPApi.updateDataset(dataset.id, {
          payload: {
            status: DatasetStatus.UPLOADING
          }
        })
        await uploadService.uploadParallel(
          data.test_images,
          {
            toDir: 'images',
          },
          ({ success, error }) => {
            if (error) {
              if (
                error.error?.details?.Code &&
                error?.details?.Code === 'UnauthorizedBlobOverwrite'
              ) {
                // auto skip existed file
                // return `Error uploading '${blockName}' (File existed)`
              } else if (error.error?.details?.Code) {
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              } else {
                // unknown or un-catch error
                _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
              }
            }
          },
        )
        if (_errorMessages.length === 0) {
          await CVPApi.updateDataset(dataset.id, {
            payload: {
              status: DatasetStatus.TEST_DATA_UPLOADED
            }
          })
        } else {
          throw Error(_errorMessages.join(', '))
        }
      }
    } catch (error) {
      setErrorMessage(error.message)
      setUploadingImages(false)
      return
    }

    // set data path for model
    try {
      delete data.test_images
      await CVPApi.updateModel(slug, model_slug, {
        payload: {
          data_path: dataset?.path,
        }
      })
      setUploadingImages(false)
      window.location.reload()
    } catch (error) {
      setErrorMessage(error.message)
    }
    setUploadingImages(false)
  }

  const onClickDeploy = () => {
    setDeploying(true)
    setErrorMessage('')
    CVPApi.deployModel(slug, model_slug, {})
      .then((model: AIModel) => {
        history.push(`/library/${slug}/overview`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        // history.push(`/library/${slug}/overview`)
        setDeploying(false)
      })
  }

  const onClickStop = () => {
    setStopping(true)
    setErrorMessage('')
    CVPApi.stopModel(slug, model_slug, {})
      .then((model: AIModel) => {
        history.push(`/library/${slug}/overview`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        // history.push(`/library/${slug}/overview`)
        setStopping(false)
      })
  }

  const onClickDiscard = () => {
    setDiscarding(true)
    setErrorMessage('')
    CVPApi.discardModel(slug, model_slug, {})
      .then((model: AIModel) => {
        history.push(`/library/${slug}/overview`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        // history.push(`/library/${slug}/overview`)
        setDiscarding(false)
      })
  }

  const onClickArchive = () => {
    setArchiving(true)
    setErrorMessage('')
    CVPApi.updateModel(slug, model_slug, {
      payload: {
        status: AIModelStatus.ARCHIVED,
      }
    })
      .then((model: AIModel) => {
        history.push(`/library/${slug}/overview`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        // history.push(`/library/${slug}/overview`)
        setArchiving(false)
      })
  }

  // cdm
  useEffect(() => {}, [])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const model = models.find((m) => String(m.id) === String(model_slug))
    const output_metrics = model?.output_plot || model?.output_metrics
    const modelExceptions: (string|undefined)[] = ['exception_package', 'exception_stop_endpoint', 'exception_register_external', 'exception_register', 'exception_deploy'].map(
      (exceptionKey: string) => {
        const title: string = ({
          'exception_package': 'Package error',
          'exception_stop_endpoint': 'Stopping endpoint error',
          'exception_register_external': 'Importing error with external model',
          'exception_register': 'Register error with internal model',
          'exception_deploy': 'Model deployment error',
        } as any)[exceptionKey]
        const message = _.get(model, exceptionKey, undefined)
        if (message) {
          return `${title}: ${message}.`
        }
        return undefined
      }
    ).filter((m) => typeof m !== 'undefined')
    // console.log('model', model)
    return (
      <React.Fragment>
        <div className="se-mb-2xl">
          {[AIModelStatus.DEPLOYED, AIModelStatus.DEPLOYING].indexOf(model?.status as any) >= 0 && (
            <div className="se-mr-xs">
              <>
                <p>
                  Click 'Stop' to start stopping the model in Azure Web Service.
                </p>
                <ConfirmationButton
                  disabled={stopping}
                  loading={stopping}
                  onClick={onClickStop}
                  size="sm"
                  actionText="Stop"
                  confirmationContent={
                    <p>
                      Click 'stop' to start stopping the model in Azure Web Service. It can take
                      some time to finish.
                    </p>
                  }
                >
                  Stop
                </ConfirmationButton>
              </>
            </div>
          )}
          {[AIModelStatus.COMPLETED, AIModelStatus.REGISTERED, AIModelStatus.STOPPED].indexOf(model?.status as any) >= 0 && (
            <div className="se-mr-xs">
              <p>The current model is ready to deploy.</p>
              <>
                <p>
                  Click 'Deploy' to start deploying the model to Azure Web Service.
                </p>
                <ConfirmationButton
                  disabled={discarding || deploying}
                  loading={deploying}
                  onClick={onClickDeploy}
                  size="sm"
                  actionText="Deploy"
                  confirmationContent={
                    <p>
                      Click 'deploy' to start deploying the model to Azure Web Service. It can take
                      some time to be available for testing.
                    </p>
                  }
                >
                  Deploy
                </ConfirmationButton>{' '}
              </>
            </div>
          )}
          {[AIModelStatus.COMPLETED, AIModelStatus.REGISTERED, AIModelStatus.STOPPED].indexOf(model?.status as any) >= 0 && (
            <div className="se-mr-xs">
              {(CVPApi.isEditable || CVPApi.canAccessResource(model)) && (
                <>
                  <p>
                    Click 'Discard' to remove it from solution overview.
                  </p>
                  <ConfirmationButton
                    disabled={discarding || deploying}
                    loading={discarding}
                    onClick={onClickDiscard}
                    size="sm"
                    actionText="Discard"
                    type="negative"
                    confirmationContent={<p>Click 'Discard' to remove it from solution overview.</p>}
                  >
                    Discard
                  </ConfirmationButton>{' '}
                </>
              )}
            </div>
          )}
          {model?.status === AIModelStatus.FAILED && (
            <div className="se-mr-xs">
              {(CVPApi.isEditable || CVPApi.canAccessResource(model)) && (
                <>
                  <ConfirmationButton
                    disabled={discarding}
                    loading={discarding}
                    onClick={onClickDiscard}
                    size="sm"
                    actionText="Discard"
                    type="negative"
                    confirmationContent={<p>Click 'Discard' to remove it from solution overview.</p>}
                  >
                    Discard
                  </ConfirmationButton>
                </>
              )}
            </div>
          )}
          <br />
          {(CVPApi.isEditable || CVPApi.canAccessResource(model)) && (
            <>
              {
                model?.status && [
                  AIModelStatus.DEPLOYED,
                  AIModelStatus.COMPLETED,
                  AIModelStatus.CANCELED,
                  AIModelStatus.FAILED,
                ].indexOf(model?.status) >= 0 && (
                  <ConfirmationButton
                    disabled={archiving}
                    loading={archiving}
                    onClick={onClickArchive}
                    size="sm"
                    actionText="Archive"
                    type="negative"
                    confirmationContent={<p>Click 'Archive' to remove it from the library.</p>}
                  >
                    Archive
                  </ConfirmationButton>
              )}
            </>
          )}
        </div>
        {model?.output_error?.error?.message && (
          <>
            <Notification type="negative" className="se-mb-md">
              <p>Training error:</p>
              {model.output_error.error.message
                .split('\n')
                .map((sentence: string, index: number) => (
                  <p key={index}>{sentence}</p>
                ))}
              {model?.log && (
                model.log
                .split('\n')
                .map((sentence: string, index: number) => (
                  <p key={`log-${index}`}>{sentence}</p>
                ))
              )}
            </Notification>
          </>
        )}

        {
          modelExceptions.length > 0 && (
            <Notification type="negative" className="se-mb-md">
              {modelExceptions.map((message: string|undefined) => (
                <p>
                  {(message as string)}
                </p>
              ))}
            </Notification>
          )
        }

        {
          CVPApi.isSuperAdmin && model && !model?.data_path && (
            <form>
              <div className="row se-mt-2xl">
                <div className="col-md-12">
                  <Title>
                    <h6>Upload test image:</h6>
                  </Title>
                </div>
              </div>
              <div className="row se-mt-md">
                <div className="col-md-5">
                  <div className="se-input-container">
                    <Controller
                      as={
                        <FileField
                          name={`test_images`}
                          // ref={register({ required: true })}
                          multiple
                          accept="image/*,.avi,.mp4"
                          valid={!uploadImagesForm.errors?.test_images}
                          helpText={
                            uploadImagesForm.errors?.test_images && uploadImagesForm.errors.test_images.type === 'required'
                              ? 'Please select images.'
                              : 'Please select images.'
                          }
                        />
                      }
                      // rules={{ required: true }}
                      name={`test_images`}
                      control={uploadImagesForm.control}
                    />
                  </div>
                </div>
              </div>
              <Button
                className="se-mt-md"
                disabled={uploadingImages}
                isLoading={uploadingImages}
                onClick={uploadImagesForm.handleSubmit(onUploadTestImagesSubmit)}
              >
                Upload
              </Button>
            </form>
          )
        }

        {errorMessage && (
          <React.Fragment>
            <div className="se-pb-md"></div>
            <Notification onClose={() => setErrorMessage('')} type="negative">
              {errorMessage}
            </Notification>
          </React.Fragment>
        )}
        <br/>
        <br/>

        <Title>Name:</Title>
        <p>{model?.name}</p>
        <Title>Description:</Title>
        <p>{model?.description}</p>
        <Title>Created By:</Title>
        <p>{model?.owner?.email}</p>

        <Title className="se-mb-sm">Status:</Title>
        <Link
          href="/kb/understanding-model-statuses"
          target="__blank"
          className="se-mb-md"
        >
          <Icon color="blue">
            chevron_right
          </Icon>
          <span>Learn more</span>
        </Link>
        <p>
          {model?.status && <ModelStatus status={model.status} />}

        </p>
        {
          model?.training_time && (
            <>
              <p>
                Training time: {
                  moment().startOf('day')
                  .seconds(model.training_time)
                  .format('H:mm:ss')
                }
              </p>
              {
                model.training_properties && (
                  <>
                    <p key={0}>Total images: {model.training_properties.total_images}</p>
                    <p key={1}>Train images: {model.training_properties.train_images}</p>
                    <p key={2}>Valdation images: {model.training_properties.val_images}</p>
                    <p key={3}>Test images: {model.training_properties.test_images}</p>
                  </>
                )
              }
              <br />
            </>
          )
        }

        <Title>Training performance:</Title>
        <>
          {model?.final_metrics ? (
            <Table
              headers={[]}
              content={
                Object.keys(model.final_metrics).map((metric_key: string) => (
                  {
                    content: [metric_key, model?.final_metrics[metric_key]?.toFixed(DEFAULT_DECIMAL_PLACES)]
                  }
                ))
              }
            />
          ) : (
            <p>Not available.</p>
          )}
        </>

        {output_metrics && (
          <div className="model-plots-wrapper se-mt-md">
            {
            Object.keys(output_metrics).filter((k: string) => {
              return ({
                classification: ['loss', 'lr'],
                object_detection: ['loss', 'AP@IoU_0p50_area_all_maxDets_100', 'AR@IoU_0p50_0p95_area_all_maxDets_100', 'Average_F1_score', 'lr'],
                semantic_segmentation: ['loss', 'acc_glob', 'Average_F1_score', 'lr'],
                instance_segmentation: ['loss', 'AP@IoU_0p50_area_all_maxDets_100', 'AR@IoU_0p50_0p95_area_all_maxDets_100', 'Average_F1_score', 'lr'],
              }[solution.type] as string[]).indexOf(k) >= 0
            }).map((k: string) => (
              output_metrics[k]['x'] && output_metrics[k]['y'] ?
                <Plot
                  data={[{
                    type: 'scatter',
                    ...output_metrics[k]
                  }]}
                  layout={{ title: PlotName[k] || k, autosize: true }}
                />
                : <Plot
                  data={output_metrics[k]}
                  layout={{ title: PlotName[k] || k, autosize: true }}
                />
            ))}
          </div>
        )}
      </React.Fragment>
    )
  }

  return <SolutionPageLayout renderer={contentRenderer} />
}
