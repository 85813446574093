import React, { useEffect, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Input,
  Breadcrumbs,
  Link,
  Notification,
  Button,
  Tile,
  Icon,
} from '@storaensods/seeds-react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { useForm, Controller } from 'react-hook-form'

import { MainTabbedPage, WysiwygEditor, Title } from '../../components'

import CVPApi from '../../services/cvp'

import './styles.sass'

export default () => {
  const history = useHistory()
  const { register, handleSubmit, watch, errors, control } = useForm()
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting]: [boolean, any] = useState(false)

  const onSubmit = (data: any): void => {
    CVPApi.createPost({
      payload: {
        ...data,
        is_page: false,
      },
    })
      .then((post) => {
        history.push(`/kb/${post.slug}/edit`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }

  // cdm
  useEffect(() => {}, [])

  return (
    <MainTabbedPage activeItem="Knowledge Center">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg se-kb-post">
        <VerticalMenuItem href="#" isActive label={<h6 className="se-mb-0">New post</h6>} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row se-mb-md se-mt-2xl">
            <div className="col-md-5">
              <Title>
                <h6 className="se-mb-0">Title:</h6>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-6">
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    name="title"
                    placeholder="Post title"
                    invalid={errors.title ? true : undefined}
                    helpText={errors.title?.type === 'required' ? 'This field is required' : ''}
                    control={control}
                  />
                </div>
              </div>
              <Title>
                <h6 className="se-mb-0 se-mt-xl">Short description:</h6>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-6">
                  <Controller
                    as={<Input />}
                    rules={{ required: true }}
                    name="description"
                    placeholder="Post description"
                    invalid={errors.description ? true : undefined}
                    helpText={
                      errors.description?.type === 'required' ? 'This field is required' : ''
                    }
                    control={control}
                  />
                </div>
              </div>
              <Title>
                <h6 className="se-mb-0 se-mt-xl">Content:</h6>
              </Title>
              <div className="row se-mb-md">
                <div className="col-md-12">
                  <Controller
                    as={<WysiwygEditor placeholder="Input main content" name="body" />}
                    name="body"
                    rules={{ required: true }}
                    // invalid={errors.name ? true : undefined}
                    // helpText={errors.name?.type === 'required' ? 'This field is required' : ''}
                    control={control}
                  />
                  {errors.body && (
                    <small className="se-form-help se-form-help--invalid">
                      {errors.body?.type === 'required' && 'This field is required'}
                    </small>
                  )}
                </div>
              </div>

              {errorMessage && (
                <React.Fragment>
                  <div className="se-pb-md"></div>
                  <Notification onClose={() => setErrorMessage('')} type="negative">
                    {errorMessage}
                  </Notification>
                </React.Fragment>
              )}

              <Button className="se-mt-md" isLoading={submitting} onClick={handleSubmit(onSubmit)}>
                Create
              </Button>
            </div>
          </div>
        </form>
      </div>
    </MainTabbedPage>
  )
}
