import React from 'react'
import { useHistory } from 'react-router-dom'

import { Header, Button, Footer } from '@storaensods/seeds-react'

import CVPApi from '../../services/cvp'

type Props = {
  children: any
}

export default ({ children }: Props) => {
  const history = useHistory()
  const token = CVPApi.fetchToken()

  return (
    <React.Fragment>
      <Header title="Computer Vision Platform">
        <div className="d-none d-md-block">
          <div className="se-header-buttons">
            {/* <Button flat>
              Menu
            </Button> */}
            {token ? (
              <Button flat onClick={CVPApi.logout}>
                Logout
              </Button>
            ) : (
              <Button flat onClick={CVPApi.login}>
                Login
              </Button>
            )}
          </div>
        </div>
      </Header>
      <div>{children}</div>
      <Footer
        menuLinks={[
          // {
          //   title: 'Privacy',
          //   url: '#',
          // },
          // {
          //   title: 'Legal notice',
          //   url: '#',
          // },
          // {
          //   title: 'Help and feedback',
          //   url: '#',
          // },
        ]}
      >
        Stora Enso is a leading provider of renewable solutions in packaging, biomaterials, wooden
        constructions and paper on global markets. Our aim is to replace fossil based materials by
        innovating and developing new products and services based on wood and other renewable
        materials.
      </Footer>
    </React.Fragment>
  )
}
