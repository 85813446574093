function parseParams(params: any): string {
  return Object.keys(params)
    .map((k) => {
      return `${k}=${params[k]}`
    })
    .join('&')
}

function toParams(query: string): { [key: string]: string } {
  const params: any = {}
  const pairs = (query[0] === '?' ? query.substr(1) : query).split('&')
  // TODO update below line to remove tslint
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return params
}

async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

const resizeImage = (base64Str: string, maxWidth = 400, maxHeight = 350) => {
  return new Promise((resolve) => {
    let img = new Image()
    img.src = base64Str
    img.onload = () => {
      let canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      canvas.width = width
      canvas.height = height
      let ctx: any = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL())
    }
  })
}

export { parseParams, toParams, asyncForEach, resizeImage }
