interface State {
  steps: { message: string; status: any }[]
  submitting: boolean
  errorMessages: string[]
  uploadingFiles?: number
  totalFiles?: number
}

export enum StepStatus {
  ERROR = -2,
  INIT = -1,
  LOADING = 0,
  DONE = 1,
}

export enum Action {
  UPDATE_STATUS = 'UPDATE_STATUS',
  UPDATE_SUBMITTING_STATUS = 'UPDATE_SUBMITTING_STATUS',
  UPDATE_ERROR_MESSAGES = 'UPDATE_ERROR_MESSAGES',
  RESET_ERROR = 'RESET_ERROR',
  UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS',
  MARK_UPLOAD_FILE_DONE = 'MARK_UPLOAD_FILE_DONE',
}

export const annotatingReducer = (state: State, action: any) => {
  switch (action.type) {
    case Action.UPDATE_STATUS: {
      const _newSteps = [...state.steps]
      _newSteps[action.step].status = action.status
      return {
        ...state,
        steps: _newSteps,
      }
    }
    case Action.UPDATE_SUBMITTING_STATUS: {
      return {
        ...state,
        submitting: action.status,
      }
    }
    case Action.UPDATE_ERROR_MESSAGES: {
      return {
        ...state,
        errorMessages: action.messages,
      }
    }
    case Action.RESET_ERROR: {
      return {
        ...state,
        errorMessages: [],
      }
    }
    case Action.UPDATE_UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadingFiles: action.uploadingFiles,
        totalFiles: action?.totalFiles || state.totalFiles,
      }
    }
    case Action.MARK_UPLOAD_FILE_DONE: {
      return {
        ...state,
        uploadingFiles:
          typeof state.uploadingFiles === 'number' ? state.uploadingFiles + 1 : undefined,
      }
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`)
    }
  }
}
