import React from 'react'
import { useHistory } from 'react-router-dom'

import { TabBar, TabItem, NavigationBar } from '@storaensods/seeds-react'

import Page from './Page'
import CVPApi from '../../services/cvp'

import './styles.sass'

type Props = {
  children: any
  activeItem: string
  // items: string[],
  // onSwitch: (item: string) => void,
}

export default ({ activeItem, children }: Props) => {
  const history = useHistory()

  const items = ['Home', 'Solution Library', 'Create a Solution', 'Import a Solution', 'Knowledge Center', 'Annotate Data', 'Admin Settings']
  const onSwitch = (item: string) => {
    history.push(
      ({
        Home: '/home',
        'Solution Library': '/library',
        'Create a Solution': '/library/new',
        'Import a Solution': '/library/register',
        'Knowledge Center': '/kb',
        'Annotate Data': '/library/datasets/annotate',
        'Admin Settings': '/admin',
      } as any)[item],
    )
  }

  return (
    <Page>
      <div className="tab-bg">
        <NavigationBar>
          {items.filter(i => !(i === 'Admin Settings' && !CVPApi.isPlatformAdmin)).map((item) => (
            <TabItem
              key={item}
              onClick={() => onSwitch(item)}
              className="se-ml-3xl se-mr-3xl"
              color="yellow"
              isActive={item === activeItem}
            >
              {item}
            </TabItem>
          ))}
        </NavigationBar>
      </div>
      {children}
    </Page>
  )
}
