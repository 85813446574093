import React, { DOMElement } from 'react'
import { Toggle, Dropdown, Slider, Link, Icon } from '@storaensods/seeds-react'
import { Controller } from 'react-hook-form'

import SolutionPageLayout from '../Base'

import { Solution } from '../../../types'
import { Popover } from '../../../components'

type Props = {
  register: any
  watch: any
  errors: any
  control: any
  field_config: any
}

export default (props: Props) => {
  const { register, watch, errors, control, field_config } = props
  const watchAdvancedOptions = watch('enable_advanced_options')

  const render_field = (name: string, config: any): any => {
    switch (config.type) {
      case 'select':
        return (
          <Controller
            as={
              <Dropdown
                label={
                  <Popover
                    component={
                      <span>{config.name}:</span>
                    }
                    position="bottom"
                  >
                    {config.description || config.name}
                  </Popover>
                }
                options={config.options}
              />
            }
            defaultValue={String(config.default)}
            onChangeName="onSelect"
            name={name}
            control={control}
          />
        )
      case 'slider':
        return (
          <Controller
            as={
              <Slider
                label={
                  <Popover
                    component={
                      <span>{config.name}:</span>
                    }
                    position="bottom"
                  >
                    {config.description || config.name}
                  </Popover>
                }
                maxValue={config.max}
                minValue={config.min}
                step={config.step}
              />
            }
            defaultValue={config.default}
            name={name}
            control={control}
          />
        )
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      <div className="row se-mt-2xl">
        <div className="col-md-5">
          <div className="d-flex">
            <Controller
              as={<Toggle />}
              name="enable_advanced_options"
              valueName="isActive"
              control={control}
            />
            <h6 className="align-self-center se-ml-md se-mb-0">Advanced options</h6>
          </div>
        </div>
      </div>
      <div className="row se-mt-md" style={{ display: watchAdvancedOptions ? 'block' : 'none' }}>
        <div className="col-md-12">
          <div className="row">
            {Object.keys(field_config).map((k: string) => (
              <div key={k} className="col-md-5 se-mb-md">
                {render_field(k, field_config[k])}
              </div>
            ))}
          </div>

          {/* <div className='row'>
            <p className='col-md-8'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </p>
          </div> */}
          <Link href="/kb/training-architecture-and-advanced-options" target="__blank">
            <Icon color="blue">
              chevron_right
            </Icon>
            <span>Learn more</span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}
