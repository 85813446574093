import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Hero, Tile, VerticalMenuItem } from '@storaensods/seeds-react'
import ReactMarkdown from 'react-markdown'
import KbService from '../services/kb'

import CVPApi from '../services/cvp'
import { Post } from '../types'

import { MainTabbedPage, VideoJs } from '../components'

import './styles/home.sass'
import './styles/home.scss'
import hero from '../assets/img/hero.png'

const parseLayout = (body: string) => {
  const lines = body.trim().split('[\n\r]+')
  const firstLine = lines[0]

  const match = firstLine.match(/\[col: (\d+)\]/)
  if (match) {
    return { col: match[1], body: body.replace(/\[col: (\d+)\]/, '').trim() }
  }

  return { col: null, body }
}

export default () => {
  const history = useHistory()
  const [post, setPost] = useState<Partial<Post> | undefined>()

  // cdm
  useEffect(() => {
    CVPApi.fetchPost('home')
      .then((post: Post | undefined) => {
        setPost(post)
      })
      .catch((error: Error) => {
        KbService.fetchPage('home').then((data) => {
          setPost({
            id: '1',
            body: data?.matter.content ?? '',
            title: data?.matter.data.title,
            description: data?.matter.data.description,
            feature_image: data?.matter.data.image,
          })
        })
      })
      .finally(() => {})
  }, [])

  const body = post?.body
  const bodyCols = body ? body.split('[---]') : []

  return (
    <MainTabbedPage activeItem="Home">
      <Hero image={encodeURI(post?.feature_image || hero)}>
        <Tile color="yellow" size="xl">
          <h3>{post?.title}</h3>
          <p>{post?.description}</p>
        </Tile>
      </Hero>
      <div className="se-pl-xl se-pr-xl se-pt-xl se-pb-xl">
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <div className="row">
              {bodyCols.map((col, index) => {
                const parsed = parseLayout(col)

                return (
                  <div className={parsed.col ? `col-body col-${parsed.col}` : 'col'} key={index}>
                    <ReactMarkdown
                      source={parsed.body}
                      escapeHtml={false}
                      renderers={{
                        heading: ({ children }) => (
                          <VerticalMenuItem
                            isActive
                            label={<h6 className="se-mb-0">{children}</h6>}
                            className="se-mb-lg"
                          />
                        ),
                        html: ({ value }) => {
                          // const matches = value.match(/<video.*width="([^"]*)".*height="([^"]*)".*<source.*src="([^"]*)".*/gi)
                          if (value.match(/<video.*width="([^"]*)".*height="([^"]*)".*/gmi)) {
                            try {
                              const regexMap: any = {
                                width: /.*width=.?"([^"\\]+).?".*/gmi,
                                height: /.*height=.?"([^"\\]+).?".*/gmi,
                                src: /.*src=.?"([^"\\]+).?".*/gmi,
                              }
                              let data: any = {}
                              Object.keys(regexMap).forEach((key: string) => {
                                const matches = regexMap[key].exec(value)
                                data[key] = matches[1]
                              });
                              return (
                                <VideoJs
                                  options={{
                                    autoplay: false,
                                    controls: true,
                                    responsive: true,
                                    // liveui: true,
                                    width: data.width,
                                    height: data.height,
                                    fluid: true,
                                    sources: [{
                                      src: `${data.src}`,
                                      // type: 'video/mp4'
                                    }]
                                  }}
                                />
                                // <video width={data.width} height={data.height} controls>
                                //   <source src={data.src} />
                                // </video>
                              )
                            } catch (error) {
                              console.error(error)
                            }
                          }
                          return (
                            <div dangerouslySetInnerHTML={{ __html: value }} />
                          )
                        },
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {CVPApi.isEditable && (
        <div className="cvp-admin-panel se-pl-xl se-pr-xl se-pt-xl se-pb-xl">
          <div className="row">
            <div className="col-md-12">
              <Button
                type="primary"
                size="sm"
                onClick={() => history.push(`/kb/${post?.slug}/edit`)}
              >
                Edit
              </Button>
              <div>
                <small className="se-form-help">
                  The panel is only visible for platform admin.
                </small>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainTabbedPage>
  )
}
