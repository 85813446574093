import React, { useEffect, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Button,
  Breadcrumbs,
  Link,
  Tile,
  Icon,
} from '@storaensods/seeds-react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import { MainTabbedPage, VideoJs } from '../../components'

// import KBService, { Post } from '../../services/kb'
import CVPApi from '../../services/cvp'
import { Solution, AIModel, AIModelStatus, SolutionStatus, Post } from '../../types'

import './styles.sass'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/kb/:slug')
  const { slug, page } = match?.params
  const [post, setPost]: [Post | undefined, any] = useState()

  // cdm
  useEffect(() => {
    CVPApi.fetchPost(slug)
      .then((post: Post | undefined) => {
        setPost(post)
      })
      .catch((error: Error) => {})
      .finally(() => {})
  }, [])

  return (
    <MainTabbedPage activeItem="Knowledge Center">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg se-kb-post">
        <VerticalMenuItem href="/kb" isActive label={<h6 className="se-mb-0">{post?.title}</h6>} />
        <div className="row se-pt-xl">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-md-12 se-pb-xl">
                <ReactMarkdown
                  source={post?.body} escapeHtml={false}
                  renderers={{
                    // heading: ({ children }) => (
                    //   <VerticalMenuItem
                    //     isActive
                    //     label={<h6 className="se-mb-0">{children}</h6>}
                    //     className="se-mb-lg"
                    //   />
                    // ),
                    html: ({ value }) => {
                      // const matches = value.match(/<video.*width="([^"]*)".*height="([^"]*)".*<source.*src="([^"]*)".*/gi)
                      if (value.match(/<video.*width="([^"]*)".*height="([^"]*)".*/gmi)) {
                        try {
                          const regexMap: any = {
                            width: /.*width=.?"([^"\\]+).?".*/gmi,
                            height: /.*height=.?"([^"\\]+).?".*/gmi,
                            src: /.*src=.?"([^"\\]+).?".*/gmi,
                          }
                          let data: any = {}
                          Object.keys(regexMap).forEach((key: string) => {
                            const matches = regexMap[key].exec(value)
                            data[key] = matches[1]
                          });
                          return (
                            <VideoJs
                              options={{
                                autoplay: false,
                                controls: true,
                                responsive: true,
                                // liveui: true,
                                width: data.width,
                                height: data.height,
                                fluid: true,
                                sources: [{
                                  src: `${data.src}`,
                                  // type: 'video/mp4'
                                }]
                              }}
                            />
                            // <video width={data.width} height={data.height} controls>
                            //   <source src={data.src} />
                            // </video>
                          )
                        } catch (error) {
                          console.error(error)
                        }
                      }
                      return (
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                      )
                    },
                    // virtualHtml: (props) => {
                    //   console.log('virtual', props)
                    //   return <video>aaa</video>
                    // },
                    // parsedHtml: (props) => {
                    //   console.log('parsed', props)
                    //   return <video>aaa</video>
                    // },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {CVPApi.isEditable && (
        <div className="cvp-admin-panel se-pl-md se-pr-md se-pt-xl se-pb-xl">
          <div className="row">
            <div className="col-md-12">
              <Button
                type="primary"
                size="sm"
                onClick={() => history.push(`/kb/${post?.slug}/edit`)}
              >
                Edit
              </Button>
              <div>
                <small className="se-form-help">
                  The panel is only visible for platform admin.
                </small>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainTabbedPage>
  )
}
