import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  Divider,
  Button,
  Input,
  TextArea,
  Hero,
  Tile,
  Link,
  Notification,
  Icon,
  RoundProgressIndicator,
} from '@storaensods/seeds-react'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'

import SolutionPageLayout from './Base'
import { Title, Table, ModelStatus, ConfirmationButton, Popover } from '../../components'

import CVPApi from '../../services/cvp'

import { Solution, AIModel, AIModelStatus, SolutionStatus } from '../../types'
import { MILLS, DEFAULT_DECIMAL_PLACES } from '../../config'
import { MODEL_TOOLTIP } from '../../components/ModelStatus/ModelStatus'
import moment from 'moment'
import { onlyAvailableModels } from './actions'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/library/:slug/:page')
  const { slug, page } = match?.params

  const [errorMessage, setErrorMessage]: [string, any] = useState('')

  const markSolutionAs = (status: SolutionStatus) => {
    CVPApi.updateSolution(slug, {
      payload: {
        status,
      },
    })
      .then((solution: Solution) => {
        window.location.reload()
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        // history.push(`/library/${slug}/overview`)
        // setDeploying(false)
      })
  }

  const discardSolution = () => {
    CVPApi.discardSolution(slug)
      .then((solution: Solution) => {
        history.push(`/library/`)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        // history.push(`/library/${slug}/overview`)
        // setDeploying(false)
      })
  }

  // cdm
  useEffect(() => {}, [])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const finalMetrics = onlyAvailableModels(models || []).length > 0 ? Object.keys(onlyAvailableModels(models)[0].final_metrics).splice(0, 2) : []

    return (
      <React.Fragment>
        <ReactMarkdown source={solution.overview} />
        <p>
          <strong>Created by:</strong> {solution?.owner?.email}
        </p>
        {errorMessage && (
          <React.Fragment>
            <div className="se-pt-md"></div>
            <Notification onClose={() => setErrorMessage('')} type="negative">
              {errorMessage}
            </Notification>
          </React.Fragment>
        )}
        <div className="se-mt-md">
        {(CVPApi.isEditable || CVPApi.canAccessResource(solution)) && (
          <>
            <Button type="primary" size="sm" onClick={() => history.push(`/library/${slug}/edit`)}>
              Edit solution
            </Button>{' '}
            {solution?.status === SolutionStatus.DRAFT && (
              <Button
                type="secondary"
                size="sm"
                onClick={() => markSolutionAs(SolutionStatus.IN_DEVELOPMENT)}
              >
                Mark as In-Development
              </Button>
            )}
            {solution?.status === SolutionStatus.IN_DEVELOPMENT && (
              <Button
                type="positive"
                size="sm"
                onClick={() => markSolutionAs(SolutionStatus.AVAILABLE)}
              >
                Mark as Available
              </Button>
            )}
            {solution?.status === SolutionStatus.AVAILABLE && (
              <Button
                type="secondary"
                size="sm"
                onClick={() => markSolutionAs(SolutionStatus.IN_DEVELOPMENT)}
              >
                Mark as In-Development
              </Button>
            )}{' '}
            {solution?.status !== SolutionStatus.ARCHIVED ? (
              <ConfirmationButton
                onClick={() => markSolutionAs(SolutionStatus.ARCHIVED)}
                size="sm"
                actionText="Archive"
                type="negative"
                confirmationContent={<p>Click 'Archive' will remove the solution from Library and resources are schedule to deleted in 30 days.</p>}
              >
                Archive
              </ConfirmationButton>
            ) : (
              <Button
                type="attention"
                size="sm"
                onClick={() => markSolutionAs(SolutionStatus.IN_DEVELOPMENT)}
              >
                Un-archive
              </Button>
            )}{' '}
          </>
        )}
        {CVPApi.isEditable && (
          solution.status !== SolutionStatus.DISCARDED && (
            <ConfirmationButton
              onClick={discardSolution}
              size="sm"
              actionText="Delete"
              type="negative"
              confirmationContent={<p>Click 'Delete' will remove the solution from Library.</p>}
            >
              Delete
            </ConfirmationButton>
          )
        )}
        </div>

        {models.length > 0 && (
          <div className="row se-mb-md se-mt-2xl">
            <div className="col-md-12">
              <Title>
                <h6>Available models</h6>
              </Title>
              <Table
                headers={[
                  ['Name', 200],
                  // 'Description',
                  ['Status', 200],
                  ...(finalMetrics && finalMetrics.length >= 2 ?
                    finalMetrics.map((metric: string) => (
                      [({
                        "Average precision": "Precision",
                        "Average recall": "Recall",
                        "Pixel accuracy": "Accuracy",
                        "Average pixel F1 score": "F1 score",
                        "Accuracy": "Accuracy",
                        "F1 score": "F1 score",
                      } as any)[metric] || metric,
                      100] as any
                    ))
                    : ([
                    '',
                    ''
                  ])),
                  ['Mill', 150],
                  ['Creator', 100],
                  ['Created At', 200],
                  ['', 100],
                ]}
                content={models
                  .sort((a, b) => b.id - a.id)
                  .map((model) => {
                    const isDeployed =
                      [AIModelStatus.DEPLOYED, AIModelStatus.COMPLETED].indexOf(model.status) >= 0
                    return {
                      // eslint-disable-next-line no-sparse-arrays
                      content: [
                        [
                          <strong className={isDeployed ? 'se-color-info' : ''}>{model.name} - v{model.version}</strong>,
                          <br />,
                          <span className={isDeployed ? 'se-color-info' : ''}>
                            {model.description}
                          </span>,
                        ],
                        [
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textAlign: 'center', marginRight: '5px' }} className={isDeployed ? 'se-color-info' : ''}>
                              {model?.metric_progress &&
                                <RoundProgressIndicator
                                  height={40}
                                  mode="percentage"
                                  step={model?.metric_progress}
                                  steps={100}
                                  width={40}
                                />
                              }
                            </span>
                            <div style={{ verticalAlign: 'middle' }}>
                              <div className={isDeployed ? 'se-color-info' : ''}>
                                <ModelStatus status={model.status} />
                              </div>
                              {/* <span className={isDeployed ? 'se-color-info' : ''}>
                                {MODEL_TOOLTIP[model.status]}
                              </span> */}
                            </div>
                          </div>,
                        ],
                        ...finalMetrics && finalMetrics.length >= 2 ? finalMetrics.map((metric_key: string) => (
                          <Popover
                            component={
                              <span className={isDeployed ? 'se-color-info' : ''}>
                                {model?.final_metrics[metric_key]?.toFixed(DEFAULT_DECIMAL_PLACES)}
                              </span>
                            }
                            position="bottom"
                          >
                            <div className="cvp-model-status-popover-content">
                              {
                                ({
                                  'classification': {
                                    'Accuracy': 'A ratio of correctly predicted observation to the total observations.',
                                    'F1 score': "Harmonic mean of the model's precision and recall. It's a better measure of the incorrectly classified cases.",
                                  },
                                  'object_detection': {
                                    'Average precision': 'A ratio of correctly detected and classified objects to the total number of detected objects averaged over classes and different confidence thresholds.',
                                    'Average recall': 'A ratio of correctly detected and classified objects to the total number of objects present in the data averaged over classes.',
                                  },
                                  'semantic_segmentation': {
                                    'Pixel accuracy': 'A ratio of correctly classified pixels to the total number of pixels.',
                                    'Average pixel F1 score': 'A harmonic mean of the model’s pixel precision and pixel recall.',
                                  },
                                  'instance_segmentation': {
                                    'Average precision': 'A ratio of correctly detected and classified objects to the total number of detected objects averaged over classes and different confidence thresholds.',
                                    'Average recall': 'A ratio of correctly detected and classified objects to the total number of objects present in the data averaged over classes.'
                                  },
                                }[solution.type] as any )[metric_key]
                              }
                            </div>
                          </Popover>
                        )) : ['', ''],
                        <span className={isDeployed ? 'se-color-info' : ''}>
                          {MILLS[model?.mill || '']}
                        </span>,
                        <span className={isDeployed ? 'se-color-info' : ''}>
                          {model?.owner?.email.split('@')[0]}
                        </span>,
                        moment.utc(model.created_at).format("L LT"),
                        <Button
                          type="primary"
                          onClick={() => history.push(`/library/${slug}/models/${model.id}`)}
                          key="1"
                        >
                          View
                        </Button>,
                      ],
                    }
                  })}
              />
              <p className="se-mt-md">
                A model can have different statuses.
                <Link
                  href="/kb/understanding-model-statuses"
                  target="__blank"
                >
                  <Icon color="blue">
                    chevron_right
                  </Icon>
                  <span>Learn more</span>
                </Link>
              </p>
            </div>
          </div>
        )}
      </React.Fragment>
    )

  }

  return <SolutionPageLayout renderer={contentRenderer} />
}
