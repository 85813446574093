import React from 'react'

// import {
  // Popover,
  //   TabBar, TabItem,
  //   NavigationBar,
// } from '@storaensods/seeds-react'

import { Popover } from '../'
import { AIModelStatus } from '../../types'

import './styles.sass'

type Props = {
  status: string
}

export const MODEL_TOOLTIP = {
  [AIModelStatus.COMPLETED]: 'The training process is completed and ready to deploy',
  [AIModelStatus.CANCELED]: 'The training process is cancelled',
  [AIModelStatus.FAILED]: 'The training process is failed to produce model',
  [AIModelStatus.PACKAGED]: 'The model is packaged and ready for downloading',

  [AIModelStatus.DRAFT]: 'Model is added to training backlog',
  [AIModelStatus.DEPLOYED]: 'The model is deployed and ready for testing',
  [AIModelStatus.DEPLOYING]: 'The model is deploying',
  [AIModelStatus.ARCHIVED]: 'The model is archived and will be deleted in 30 days',
  [AIModelStatus.DISCARDED]: 'The model is discarded and will be deleted',
  [AIModelStatus.DELETED]: 'The model is deleted',
  [AIModelStatus.STOPPED]: 'The model is stopped outside working hour, it is starting again tomorrow',

  [AIModelStatus.REGISTERED]: 'Model is registered',
  [AIModelStatus.REGISTERING]:
    'Model is queued for registration. It often take few minutes to be registered',

  [AIModelStatus.NOT_STARTED]: 'The training is about to start',
  [AIModelStatus.STARTING]: 'Starting the training',
  [AIModelStatus.PROVISIONING]: 'Creating computing resource for the training',
  [AIModelStatus.PREPARING]: 'Preparing the training environment',
  [AIModelStatus.QUEUED]:
    'The training is queued and it often take 15-45 minutes to start running. The whole process can take hours to finish.',
  [AIModelStatus.RUNNING]: 'The training is running',
  [AIModelStatus.FINALIZING]: 'Finalizing the training',
  [AIModelStatus.CANCEL_REQUESTED]: 'The training is being cancelled',
  [AIModelStatus.NOT_RESPONDING]: 'The training process is not responding',
}

export default ({ status }: Props) => (
  <div className="cvp-model-status">
    {(MODEL_TOOLTIP as any)[status] ? (
      <Popover component={<span>{status}</span>} position="bottom">
        <div className="cvp-model-status-popover-content">{(MODEL_TOOLTIP as any)[status]}</div>
      </Popover>
    ) : (
      <span>{status}</span>
    )}
  </div>
)
