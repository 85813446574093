import { RequestPolicyOptions } from '@azure/storage-blob'
import moment from 'moment'
import _ from 'lodash'

import { BaseApi } from './baseApi'
import AzureAD from './aad'

import { Solution, AIModel, Post, Role, Dataset } from '../types'

class CVPApi extends BaseApi {
  public user_id?: number
  public email?: string
  protected roles: { id: number; name: string }[] = []

  constructor() {
    super({
      endpoint: String(process.env.REACT_APP_CVP_API_URL),
    })
  }

  public login = async () => {
    this.removeToken()
    AzureAD.login()
  }

  public logout = async () => {
    this.removeToken()
    AzureAD.logout()
  }

  public clearToken = async () => {
    this.removeToken()
  }

  public auth = async (email: string, password: string): Promise<any> => {
    throw Error('The function is removed.')
    //   // AD auth
    //   // TODO: replace with AD auth
    //   // const token = (email === 'cvp@storaenso.com' && password === 'cvp@2020') ?
    //     // (process.env.NODE_ENV === 'production' ? 'FgU9EyN1tPMRhV4AvQSaGIktI3ziMMP2kf01' : 'secret') : undefined;

    //   // default login anyone for now. TODO: remove later
    //   const token = process.env.NODE_ENV === 'production' ? 'FgU9EyN1tPMRhV4AvQSaGIktI3ziMMP2kf01' : 'secret'
    //   // save token
    //   if (token) {
    //     this.setToken(token);
    //   } else {
    //     throw Error('Wrong credentials')
    //   }
  }

  public authWithAAD = async (payload: any): Promise<any> => {
    const response = await this._requestAsync(`${this.endpoint}/login/`, 'POST', {
      payload,
    })
    const token = response?.token
    await this.setToken(token, moment(payload.expires_on).diff(moment()))
  }

  get isEditable(): boolean {
    return (
      _.intersection([Role.PLATFORM_ADMIN, Role.SUPER_ADMIN], this.roles.map((r) => r.name) || [])
        .length > 0
    )
  }

  get isSuperAdmin(): boolean {
    return _.intersection([Role.SUPER_ADMIN], this.roles.map((r) => r.name) || []).length > 0
  }

  get isPlatformAdmin(): boolean {
    return _.intersection([Role.PLATFORM_ADMIN], this.roles.map((r) => r.name) || []).length > 0
  }

  public canAccessResource = (resource: any) => {
    return this.user_id === resource?.owner?.id
  }

  public refreshToken = async () => {
    await AzureAD.refreshAccessToken()
    await AzureAD.fetchAccessToken()
    await this.authWithAAD(AzureAD.accessToken)
  }

  public fetchMe = async () => {
    const resp = await this._requestAsync(`${this.endpoint}/me/`)
    if (resp?.groups) this.roles = resp.groups
    if (resp?.email && resp?.id) {
      this.user_id = resp.id
      this.email = resp.email
    }
    return resp
  }

  public fetchSolutions = async (params?: any): Promise<Solution[]> => {
    return await this._requestAsync(`${this.endpoint}/solutions/`, 'GET', {
      params: _.pickBy(params, _.identity),
    })
  }

  public fetchSolution = async (id: number | string): Promise<Solution> => {
    return await this._requestAsync(`${this.endpoint}/solutions/${id}/`)
  }

  public updateSolution = async (id: number | string, requestOptions: any): Promise<Solution> => {
    return await this._requestAsync(`${this.endpoint}/solutions/${id}/`, 'PUT', requestOptions)
  }

  public createSolution = async (requestOptions: any): Promise<Solution> => {
    return await this._requestAsync(`${this.endpoint}/solutions/`, 'POST', requestOptions)
  }

  // deprecated, please check requestDatasetUpload
  public requestSolutionUpload = async (
    id: number | string,
    requestOptions: any,
  ): Promise<{
    token: string
    blob_path: string
    account_name: string
    container_name: string
  }> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/upload/`,
      'POST',
      requestOptions,
    )
  }

  // deprecated
  public markSolutionUpload = async (id: number | string, requestOptions: any): Promise<any> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/upload/`,
      'PUT',
      requestOptions,
    )
  }

  public discardSolution = async (
    id: number | string,
  ): Promise<any> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/`,
      'DELETE',
    )
  }

  public fetchAIModelBySolution = async (id: number | string): Promise<AIModel[]> => {
    return await this._requestAsync(`${this.endpoint}/solutions/${id}/models/`)
  }

  public train = async (id: number | string, requestOptions: any): Promise<AIModel> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/models/`,
      'POST',
      requestOptions,
    )
  }

  public registerInference = async (id: number | string, requestOptions: any): Promise<AIModel> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/onboard/inference/`,
      'POST',
      requestOptions,
    )
  }

  public registerTraining = async (id: number | string, requestOptions: any): Promise<AIModel> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/onboard/training/`,
      'POST',
      requestOptions,
    )
  }

  public finetune = async (
    id: number | string,
    model_id: number | string,
    requestOptions: any,
  ): Promise<AIModel> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/models/${model_id}/finetune/`,
      'POST',
      requestOptions,
    )
  }

  public runInference = async (
    id: number | string,
    model_id: number | string,
    requestOptions: any,
  ): Promise<any> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/models/${model_id}/inference/`,
      'POST',
      requestOptions,
    )
  }

  public deployModel = async (
    id: number | string,
    model_id: number | string,
    requestOptions: any,
  ): Promise<any> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/models/${model_id}/deploy/`,
      'POST',
      requestOptions,
    )
  }

  public stopModel = async (
    id: number | string,
    model_id: number | string,
    requestOptions: any,
  ): Promise<any> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/models/${model_id}/stop/`,
      'POST',
      requestOptions,
    )
  }

  public updateModel = async (
    id: number | string,
    model_id: number | string,
    requestOptions: any,
  ): Promise<any> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/models/${model_id}/`,
      'PUT',
      requestOptions,
    )
  }

  public discardModel = async (
    id: number | string,
    model_id: number | string,
    requestOptions: any,
  ): Promise<any> => {
    return await this._requestAsync(
      `${this.endpoint}/solutions/${id}/models/${model_id}/`,
      'DELETE',
      requestOptions,
    )
  }

  public downloadModel = async (
    id: number | string,
    model_id: number | string,
  ): Promise<string> => {
    return await this._requestAsync(`${this.endpoint}/solutions/${id}/models/${model_id}/download/`)
  }

  public downloadTestImage = async (
    id: number | string,
    model_id: number | string,
  ): Promise<string> => {
    return await this._requestAsync(`${this.endpoint}/solutions/${id}/models/${model_id}/download_image/`)
  }

  public requestPackaging = async (
    id: number | string,
    model_id: number | string,
  ): Promise<string> => {
    return await this._requestAsync(`${this.endpoint}/solutions/${id}/models/${model_id}/package/`, 'POST')
  }

  public fetchPosts = async (): Promise<Post[]> => {
    return await this._requestAsync(`${this.endpoint}/posts/`)
  }

  public fetchPost = async (id: number | string): Promise<Post> => {
    return await this._requestAsync(`${this.endpoint}/posts/${id}/`)
  }

  public updatePost = async (id: number | string, requestOptions: any): Promise<Post> => {
    return await this._requestAsync(`${this.endpoint}/posts/${id}/`, 'PUT', requestOptions)
  }

  public createPost = async (requestOptions: any): Promise<Post> => {
    return await this._requestAsync(`${this.endpoint}/posts/`, 'POST', requestOptions)
  }

  public requestPostImageUpload = async (
    requestOptions?: any,
  ): Promise<{
    token: string
    blob_path: string
    account_name: string
    container_name: string
  }> => {
    return await this._requestAsync(`${this.endpoint}/posts/upload/`, 'POST', requestOptions)
  }

  public requestDatasetUpload = async (): Promise<{
    token: string
    blob_path: string
    account_name: string
    container_name: string
  }> => {
    return await this._requestAsync(
      `${this.endpoint}/datasets/request_upload_token/`,
      'POST',
    )
  }

  public fetchDatasets = async (requestOptions?: any): Promise<Dataset[]> => {
    return await this._requestAsync(`${this.endpoint}/datasets/`, 'GET', requestOptions)
  }

  public createDataset = async (requestOptions: any): Promise<Dataset> => {
    return await this._requestAsync(`${this.endpoint}/datasets/`, 'POST', requestOptions)
  }

  public updateDataset = async (id: number | string, requestOptions: any): Promise<Dataset> => {
    return await this._requestAsync(`${this.endpoint}/datasets/${id}/`, 'PUT', requestOptions)
  }

  public cvatAnnotate = async (id: number | string): Promise<any> => {
    return await this._requestAsync(`${this.endpoint}/datasets/${id}/annotate/`, 'POST', {});
  }

  public findUsers = async (email: string): Promise<any[]> => {
    return await this._requestAsync(`${this.endpoint}/admins/`, 'GET', {
      params: {
        email
      }
    });
  }

  public findAdmins = async (): Promise<any[]> => {
    return await this._requestAsync(`${this.endpoint}/admins/`, 'GET', {
      params: { }
    });
  }

  public grantAdminRight = async (id: number): Promise<any> => {
    return await this._requestAsync(`${this.endpoint}/admins/`, 'POST', {
      payload: {
        user_ids: [id]
      }
    });
  }

  public revokeAdminRight = async (id: number): Promise<any> => {
    return await this._requestAsync(`${this.endpoint}/admins/${id}/`, 'DELETE',);
  }

}

const api = new CVPApi()

export default api
