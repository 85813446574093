export interface Solution {
  id: string
  name: string
  type: SolutionType
  description: string
  status: string
  overview: string
  instructions: string
  slug?: string
  is_external?: boolean
  has_external_inference?: boolean
  has_external_training?: boolean
  preview_image?: string
  owner?: {
    email: string
  }
  base_model_info?: {
    mill: string
    model_name: string
  }
}

export interface AIModel {
  name: string
  description: string
  version: string
  id: number
  created_at: string
  status: AIModelStatus
  mill?: string
  classes: string[]
  architecture?: string
  data_path?: string
  output_error?: {
    error: {
      code: string
      details: any[]
      detailsUri: string
      message: string
      messageParameters: any
      debugInfo: {
        message: string
        stackTrace: string
        type: string
      }
    }
  }
  log?: string
  training_time?: number
  training_properties?: {
    total_images: number | string
    train_images: number | string
    val_images: number | string
    test_images: number | string
  }
  metric_progress?: number
  // metric_f1_score?: number
  // metric_accuracy?: number
  final_metrics: {
    [key: string]: number
  }
  output_metrics: {
    accuracy: number
    f1_score: number
  }
  output_plot: {
    [key: string]: any
  }
  details?: {
    [key: string]: any
  }
  owner?: {
    id: string
    email: string
  }
  has_package?: boolean
  exception_package?: string
  exception_stop_endpoint?: string
  exception_register_external?: string
  exception_register?: string
  exception_deploy?: string
}

export enum SolutionStatus {
  DRAFT = 'draft',
  IN_DEVELOPMENT = 'in_development',
  AVAILABLE = 'available',
  ARCHIVED = 'archived',
  DISCARDED = 'discarded',
  DELETED = 'deleted',
}

export enum SolutionType {
  CLASSIFICATION = 'classification',
  OBJECT_DETECTION = 'object_detection',
  SEMANTIC_SEGMENTATION = 'semantic_segmentation',
  INSTANCE_SEGMENTATION = 'instance_segmentation',
}

export enum AIModelStatus {
  // main statuses
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  FAILED = 'failed',
  PACKAGED = 'packaged',

  // platform defined status
  // IN_DEVELOPMENT = 'in_development',
  DRAFT = 'prepared_for_training',
  DEPLOYED = 'deployed',
  DEPLOYING = 'deploying',
  ARCHIVED = 'archived',
  DISCARDED = 'discarded',
  DELETED = 'deleted',
  STOPPED = 'stopped',

  // onboarding model
  REGISTERED = 'registered',
  REGISTERING = 'registering',

  // might not in use
  NOT_STARTED = 'notstarted',
  STARTING = 'starting',
  PROVISIONING = 'provisioning',
  PREPARING = 'preparing',
  QUEUED = 'queued',
  RUNNING = 'running',
  FINALIZING = 'finalizing',
  CANCEL_REQUESTED = 'cancelrequested',
  NOT_RESPONDING = 'notresponding',
}

export enum TrainingStepStatus {
  ERROR = -2,
  INIT = -1,
  LOADING = 0,
  DONE = 1,
}

export interface Post {
  id: string
  title: string
  description: string
  body: string
  slug: string
  feature_image: string
  is_page: boolean
  created_at: string
  updated_at: string
}

export enum Role {
  SUPER_ADMIN = 'superadmin',
  PLATFORM_ADMIN = 'platform_admin',
  SCIENTIST = 'data_scientist',
}

export enum DatasetStatus {
  INITIALIZED = "initialized",
  UPLOADING = "uploading",
  UPLOADED = "data_uploaded",
  TEST_DATA_UPLOADED = "test_data_uploaded",
  WAITING_FOR_ANNOTATIONS = "waiting_for_annotations",
  DISCARDED = "discarded",
}

export interface Dataset {
  id: number
  created_at: string
  solution?: Solution
  task?: string
  description?: string
  user?: {
    email: string
  }
  mill?: string
  status?: DatasetStatus
  is_cvat: boolean
  is_public: boolean
  path: string
}

