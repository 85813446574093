import React, { useEffect, useState, useReducer } from 'react'
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import {
  Button,
  Input,
  Notification,
  Dropdown,
  Link,
  Icon,
  Spinner,
  TextArea,
  Tile,
  Toggle,
  RoundProgressIndicator,
} from '@storaensods/seeds-react'

import SolutionPageLayout from './Base'
import { Title, FileField, CreatableSelect } from '../../components'
import { Solution, AIModel, TrainingStepStatus, SolutionType } from '../../types'

import CVPApi from '../../services/cvp'
import UploadService from '../../services/upload'

import AdvancedOptions from './Form/AdvancedOptions'
import { FORM_CONFIG, MILLS, SUPPORTED_PYTHON_VERSIONS } from '../../config'
import { asyncForEach } from '../../lib/utils'
import { onlyAvailableModels, trainingReducer, TrainingAction } from './actions'
import { useExitPrompt } from '../../lib/hooks'

export default () => {
  const history = useHistory()
  const match: any = useRouteMatch('/library/:slug/:page')
  const { slug, page } = match?.params
  const { register, handleSubmit, watch, errors, control, formState } = useForm()
  const classNameControls = useFieldArray({ control, name: 'classes' })
  const watchCVATOption = watch('enable_cvat')
  const [showExitPrompt, exitPrompt, setShowExitPrompt]: [boolean, any, any] = useExitPrompt(false)


  const [{ steps, submitting, errorMessages, uploadingFiles, totalFiles }, dispatch]: [
    any,
    any,
  ] = useReducer(trainingReducer, {
    steps: [
      // { message: 'Validating model', status: TrainingStepStatus.INIT },
      // { message: 'Registering model', status: TrainingStepStatus.INIT },
    ],
    submitting: false,
    errorMessages: [],
  })
  const [classIds, setClassIds]: [number[], any] = useState([0, 1])

  const onSubmit = async (data: any) => {
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: true })

    // Registering request
    // dispatch({ type: TrainingAction.UPDATE_TRAINING_STATUS, step: 1, status: TrainingStepStatus.LOADING })
    try {
      await CVPApi.registerTraining(slug, {
        payload: {
          ...data,
          zip_file: data?.zip_file[0],
          // python_version: data.python_version.value,
          // classes: data?.classes.join('\n'),
          // classes: data?.type === SolutionType.CLASSIFICATION ? data?.classes.join('\n') : '',
          // split_fraction: data?.data_split.indexOf('test') >= 0 ? data?.split_fraction : 0,
        },
        formData: true,
      })
      setShowExitPrompt(false)
      history.push(`/library/${slug}/`)
    } catch (error) {
      // dispatch({ type: TrainingAction.UPDATE_TRAINING_STATUS, step: 1, status: TrainingStepStatus.ERROR })
      dispatch({ type: TrainingAction.UPDATE_ERROR_MESSAGES, messages: error.message.split('\n') })
    }
    dispatch({ type: TrainingAction.UPDATE_SUBMITTING_STATUS, status: false })
  }

  // cdm
  useEffect(() => {}, [])
  useEffect(() => {
    setShowExitPrompt(formState.dirty)
  }, [formState.dirty])

  const contentRenderer = ({
    solution,
    models,
  }: {
    solution: Solution
    models: AIModel[]
  }): any => {
    const { base_model_info } = solution
    const form_config = (FORM_CONFIG as any)[solution?.type]
    return (
      <React.Fragment>
        <Tile color="gray" className="">
          <Link href={`/kb/onboarding-solution-guideline`} target="__blank">
            <Icon color="blue">chevron_right</Icon>
            <span>Onboarding solution guideline</span>
          </Link>
        </Tile>

        <form className="se-pb-2xl se-mt-2xl">
          {exitPrompt}
          <div className="row se-mt-2xl">
            <div className="col-md-12">
              <Title>
                <h6>Training configuration:</h6>
              </Title>
              <p>
                The following packages are supported: OpenCV, ...
                <br />
                Please specify additional packages below.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <Controller
                as={<TextArea />}
                // rules={{ required: true }}
                name="packages"
                defaultValue="tensorflow==2.2.0&#10;opencv-python==4.2.0.32"
                control={control}
              />
              {errors.packages && (
                <small className="se-form-help se-form-help--invalid">
                  {errors.packages?.type === 'required' && 'This field is required'}
                </small>
              )}
            </div>
          </div>
          <div className="row se-mt-md">
            <div className="col-md-5">
              <Controller
                as={
                  <FileField
                    name="zip_file"
                    accept=".zip"
                    valid={!errors.zip_file}
                    helpText={
                      !errors.zip_file
                        ? 'Please upload zip-file containing the training source code.'
                        : errors.zip_file?.type === 'required'
                        ? 'This field is required'
                        : ''
                    }
                  />
                }
                name="zip_file"
                rules={{ required: true }}
                control={control}
              />
            </div>
          </div>

          {/* <div className="row se-mt-md">
            <div className="col-md-5">
              <Controller
                as={
                  <Dropdown
                    label='Python version'
                    options={SUPPORTED_PYTHON_VERSIONS}
                  />
                }
                defaultValue={SUPPORTED_PYTHON_VERSIONS[0]}
                rules={{ required: true }}
                name="python_version"
                control={control}
              />
              {errors.python_version && (
                <small className="se-form-help se-form-help--invalid">
                  {errors.python_version?.type === 'required' && 'This field is required'}
                </small>
              )}
            </div>
          </div> */}

          <Button
            className="se-mt-md"
            disabled={submitting}
            isLoading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            Validate & Register
          </Button>
          <div className="row">
            <div className="col-md-8 se-mt-md">
              <small className={`se-form-help`}>
                The validating process will take few minutes. After that, the model is queued for
                registering and deploying to web service.
              </small>
            </div>
          </div>

          {submitting && steps.length > 0 && (
            <div className='row se-mt-md'>
              <div className="col col-md-2">
                <RoundProgressIndicator
                  height={75}
                  mode="regular"
                  step={
                    steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE) >= 0 ?
                    steps.length - steps.slice().reverse().findIndex((s: any) => s.status === TrainingStepStatus.DONE)
                    : 0
                  }
                  steps={steps.length}
                />
              </div>
              <div className="col">
                {steps.map((step: any, stepIndex: number) => (
                  <p key={stepIndex}>
                    {step.status === TrainingStepStatus.LOADING && (
                      <Spinner className="training-spinner" />
                    )}
                    {step.status === TrainingStepStatus.INIT && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.ERROR && <Icon>radio_button_unchecked</Icon>}
                    {step.status === TrainingStepStatus.DONE && <Icon>check</Icon>} {step.message}{' '}
                    {stepIndex === 0 &&
                      uploadingFiles &&
                      totalFiles &&
                      `:${uploadingFiles}/${totalFiles}`}
                  </p>
                ))}
              </div>
            </div>
          )}

          {errorMessages.length > 0 && (
            <React.Fragment>
              <div className="se-pb-md"></div>
              <Notification
                onClose={() => dispatch({ type: TrainingAction.RESET_ERROR })}
                type="negative"
              >
                {errorMessages.map((m: string, index: number) => (
                  <p key={index} className="se-mb-0" style={{ minHeight: '1rem' }}>
                    {m}
                  </p>
                ))}
              </Notification>
            </React.Fragment>
          )}
        </form>
      </React.Fragment>
    )
  }

  return (
    <SolutionPageLayout
      renderer={contentRenderer}
      description={'Import model for the solution'}
    />
  )
}
