import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import AzureAD from '../services/aad'
import CVPApi from '../services/cvp'

export default function requireAuthentication(Component: any) {
  const AuthenticatedComponent = (props: any) => {
    const history = useHistory()
    const [authenticating, setAuthenticating] = useState(true)

    // cdm
    useEffect(() => {
      const authenticate = async () => {
        const token = await CVPApi.fetchToken()
        if (!token) {
          try {
            await AzureAD.refreshAccessToken()
            await AzureAD.fetchAccessToken()
            await CVPApi.authWithAAD(AzureAD.accessToken)
          } catch (error) {
            await CVPApi.clearToken()
            history.push(`/`)
          }
        }
        if (await CVPApi.fetchToken()) await CVPApi.fetchMe()

        setAuthenticating(false)
      }

      authenticate()
    }, [])

    return <React.Fragment>{!authenticating && <Component {...props} />}</React.Fragment>
  }

  return AuthenticatedComponent
}
