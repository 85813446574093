import React, { useEffect, useState } from 'react'
import { VerticalMenuItem, Button, Breadcrumbs, Link, Tile, Icon, Input, } from '@storaensods/seeds-react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import { MainTabbedPage, TeamsLink } from '../../components'

// import KBService, { Post } from '../../services/kb'
import './styles.sass'
import CVPApi from '../../services/cvp'
import { Solution, AIModel, AIModelStatus, SolutionStatus, Post } from '../../types'


export default () => {
  const history = useHistory()
  const [posts, setPosts]: [Post[], any] = useState([])
  const [foundPosts, setFoundPosts]: [Post[], any] = useState([])
  const { control, handleSubmit, formState, getValues, watch } = useForm()

  // cdm
  useEffect(() => {
    CVPApi.fetchPosts()
      .then((posts) => {
        setPosts(posts)
        setFoundPosts(posts)
      })
      .catch((error) => {})
      .finally(() => {})
  }, [])

  const onSubmit = async (data: any) => {
    const search = data?.search
    setFoundPosts(
      posts.filter(
        (p: Post) => p.description.indexOf(search) >= 0 || p.body.indexOf(search) >= 0 || p.title.indexOf(search) >= 0
      )
    )
  }

  return (
    <MainTabbedPage activeItem="Knowledge Center">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row se-pb-xl">
            <div className="col-md-3 col-sm-12">
              <VerticalMenuItem
                isActive
                label={<h6 className="se-mb-0">Search for articles</h6>}
                className="se-mb-lg"
              />
              <div className={'searchContainer'}>
                <Controller
                  as={<Input />}
                  name="search"
                  placeholder="Search by keyword"
                  control={control}
                />
                <Button isLoading={formState.isSubmitting} onClick={handleSubmit(onSubmit)}>
                  Search
                </Button>
              </div>
            </div>
          </div>
        </form>
        <VerticalMenuItem
          href="#"
          isActive
          label={<h6 className="se-mb-0">General Computer Vision Models</h6>}
        />
        <div className="row se-pt-xl">
          <div className="col-md-12 col-lg-8 col-md-12">
            <div className="row">
              {foundPosts
                .filter((p) => !p.is_page)
                .map((post, index) => (
                  <div
                    key={post.slug}
                    className={
                      `${index % 2 !== 0 ? 'offset-md-1' : 'offset-md-0'} ${index % 3 !== 0 ? 'offset-lg-1' : 'offset-lg-0'} col-lg-3 col-md-5 se-pb-xl`
                    }
                  >
                    <Tile color="gray" style={{ height: '100%' }}>
                      <Link href={`/kb/${post.slug}`} style={{ display: 'block' }}>
                        <div
                          className="se-mb-md"
                          style={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${
                              post?.feature_image ? encodeURI(post.feature_image) : ''
                            })`,
                            width: '100%',
                            paddingTop: '60%',
                          }}
                        />
                        <h6>{post?.title}</h6>
                      </Link>
                      <p>{post?.description}</p>
                    </Tile>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {CVPApi.isEditable && (
        <div className="cvp-admin-panel se-pl-md se-pr-md se-pt-xl se-pb-xl se-mb-md">
          <div className="row">
            <div className="col-md-12">
              <Button type="primary" size="md" onClick={() => history.push(`/kb/new`)}>
                Add new post
              </Button>
              <div>
                <small className="se-form-help">
                  The function is only available for platform admin.
                </small>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="se-pl-md se-pr-md se-pb-lg">
        <VerticalMenuItem href="#" isActive label={<h6 className="se-mb-0">Utilities</h6>} />
        <div className="row se-pt-xl">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-md-5">
                <Tile color="gray" style={{ height: '100%' }}>
                  <Link
                    href={`https://se-cvp-vott.azurewebsites.net/`}
                    style={{ display: 'block' }}
                  >
                    <h6>Web-based VoTT</h6>
                  </Link>
                  <p>An open source annotation and labeling tool for image and video assets.</p>
                </Tile>
              </div>
              <div className="offset-md-1 col-md-5">
                <Tile color="gray" style={{ height: '100%' }}>
                  <Link
                    href={`https://se-cvp-vott.azurewebsites.net/`}
                    style={{ display: 'block' }}
                  >
                    <h6>Release package VoTT (support local file system)</h6>
                  </Link>
                  <p>
                    An open source annotation and labeling tool for image and video assets. This is
                    available for Windows, Linux and OSX. Latest release can be downloaded from
                    Microsoft Github{' '}
                    <a href="https://github.com/Microsoft/VoTT/releases" target="__blank">
                      official link
                    </a>
                    .
                  </p>
                </Tile>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="se-pl-md se-pr-md se-pb-lg">
        <TeamsLink text="Get in-person support" />
        <div className="row se-pt-xl">
          <div className="col-lg-8 col-md-12"></div>
        </div>
      </div>
    </MainTabbedPage>
  )
}
