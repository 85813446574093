enum Routes {
  Root = '/',
  Home = '/home',
  Library = '/library',
  Login = '/login',

  SolutionNew = '/library/new',
  SolutionOnboard = '/library/register',
  // solution pages
  SolutionRoot = '/library/:slug',
  SolutionOverview = '/library/:slug/overview',
  SolutionInstruction = '/library/:slug/instructions',
  SolutionTest = '/library/:slug/test',
  SolutionDeploy = '/library/:slug/download',
  SolutionTrain = '/library/:slug/train',
  SolutionExternalFinalize = '/library/:slug/register/inference',
  SolutionExternalTrainingFinalize = '/library/:slug/register/training',
  SolutionDevelop = '/library/:slug/fine-tune',
  SolutionDiscussion = '/library/:slug/discussion',
  SolutionEdit = '/library/:slug/edit',

  // Model page
  AIModelDetail = '/library/:slug/models/:model_slug',

  // Dataset
  DatasetAnnotateWithCVAT = '/library/datasets/annotate',
  DatasetDetail = '/library/datasets/:id/',

  // Knowledge base
  KnowledgeBase = '/kb',
  KBNew = '/kb/new',
  KBPost = '/kb/:slug',
  KBEdit = '/kb/:slug/edit',

  Admin = '/admin',
}

export default Routes
