import React, { useEffect, useReducer, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Toggle,
  Tag,
  Input,
  Dropdown,
  Button,
  RoundProgressIndicator,
  Icon,
  Spinner,
  Notification,
} from '@storaensods/seeds-react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import moment from 'moment'

import { MainTabbedPage, Title, FileField, CreatableSelect, Table, ConfirmationButton, Popover, } from '../../components'

import CVPApi from '../../services/cvp'
import { Dataset, DatasetStatus, Solution, SolutionStatus, SolutionType } from '../../types'

import styles from './Library.module.scss'
import { MILLS } from '../../config'
import UploadService from '../../services/upload'
import { Action, annotatingReducer, StepStatus } from './actions'
import { useExitPrompt } from '../../lib/hooks'

const extractFramesFromVideo = async (videoFile: File, fps: number = 25): Promise<File[]> => {

  return new Promise(async (resolve) => {
    let videoObjectUrl = URL.createObjectURL(videoFile);
    let video = document.createElement("video");
    const filename = videoFile.name.split(".").splice(0, videoFile.name.split(".").length - 1)
    const extension = videoFile.name.split(".")[videoFile.name.split(".").length -1 ]

    let seekResolve: any;
    video.addEventListener('seeked', async function() {
      if(seekResolve) seekResolve();
    });

    video.src = videoObjectUrl;

    // workaround chromium metadata bug (https://stackoverflow.com/q/38062864/993683)
    while((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
      await new Promise(r => setTimeout(r, 1000));
      video.currentTime = 10000000*Math.random();
    }
    let duration = video.duration;

    let canvas = document.createElement('canvas');
    let context: any = canvas.getContext('2d');
    let [w, h] = [video.videoWidth, video.videoHeight]
    canvas.width =  w;
    canvas.height = h;

    let frames = [];
    let interval = 1 / fps;
    let currentTime = 0;

    while(currentTime < duration) {
      video.currentTime = currentTime;
      // eslint-disable-next-line no-loop-func
      await new Promise(r => seekResolve=r);

      context.drawImage(video, 0, 0, w, h);
      let base64ImageData = canvas.toDataURL('image/jpeg');

      const dataURLtoFile = (dataurl: string, filename: string) => {
          let arr = dataurl.split(',')
          let mime = 'image/jpeg'
          let bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);

          while(n--){
              u8arr[n] = bstr.charCodeAt(n);
          }

          return new File([u8arr], filename, {type:mime});
      }

      frames.push(dataURLtoFile(
        base64ImageData,
        `${filename}_${currentTime}.jpeg`
      ));

      currentTime += interval;
    }
    resolve(frames);
  })

}

export default () => {
  const history = useHistory()
  const { control, handleSubmit, formState, getValues, watch, errors, } = useForm()
  const [datasets, setDatasets]: [Dataset[], any] = useState([])
  const [finishedDatasets, setFinishedDatasets]: [Dataset[], any] = useState([])
  const [showExitPrompt, exitPrompt, setShowExitPrompt]: [boolean, any, any] = useExitPrompt(false)
  const [{ steps, submitting, errorMessages, uploadingFiles, totalFiles }, dispatch]: [
    any,
    any,
  ] = useReducer(annotatingReducer, {
    steps: [
      { message: 'Processing files', status: StepStatus.INIT },
      { message: 'Uploading dataset', status: StepStatus.INIT },
      { message: 'Setting up CVAT', status: StepStatus.INIT },
    ],
    submitting: false,
    errorMessages: [],
  })

  const fetchDatasets = async (status: DatasetStatus, cb: any) => {
    const ds = await CVPApi.fetchDatasets({
      params: Object.assign({}, {
        status: status,
      }, CVPApi.isPlatformAdmin ? {} : {
        user_id: CVPApi.user_id,
      })
    })
    if (cb)
      cb(ds)
    return ds
  }

  // cdm
  useEffect(() => {
    fetchDatasets(DatasetStatus.WAITING_FOR_ANNOTATIONS, (ds: any) => setDatasets(ds))
    fetchDatasets(DatasetStatus.UPLOADED, (ds: any) => setFinishedDatasets(ds))
  }, [])
  useEffect(() => {
    setShowExitPrompt(formState.dirty)
  }, [formState.dirty])

  const onSubmit = async (data: any) => {
    const files: FileList = getValues('files')
    dispatch({ type: Action.UPDATE_SUBMITTING_STATUS, status: true })
    dispatch({
      type: Action.UPDATE_UPLOAD_PROGRESS,
      uploadingFiles: undefined,
      totalFiles: undefined,
    })

    dispatch({
      type: Action.UPDATE_STATUS,
      step: 0,
      status: StepStatus.LOADING,
    })
    let uploadRequest
    let dataset: (Dataset | undefined)
    let _errorMessages: (string | undefined)[] = []
    let uploadService
    const hasVideo: Boolean = Array.from(files).filter(f => f.name.indexOf('.mp4') >= 0).length > 0
    const hasImage: Boolean = Array.from(files).filter(f => f.name.indexOf('.mp4') < 0).length > 0
    // processing Filelist
    const processedFiles = await Array.from(files).reduce(async (accP: any, f: File) => {
      const acc = await accP
      if (f.name.indexOf('.mp4') >= 0) {
        const videoFiles: File[] = await extractFramesFromVideo(f, 24)
        return [...acc, f ,...videoFiles]
      }
      return [...acc, f]
    }, Promise.resolve([]))
    dispatch({
      type: Action.UPDATE_STATUS,
      step: 0,
      status: StepStatus.DONE,
    })

    dispatch({
      type: Action.UPDATE_STATUS,
      step: 1,
      status: StepStatus.LOADING,
    })
    // 1. create dataset and prepare for cvat
    try {
      uploadRequest = await CVPApi.requestDatasetUpload()
      uploadService = new UploadService(
        uploadRequest.token,
        uploadRequest.account_name,
        uploadRequest.container_name,
        uploadRequest.blob_path,
      )
      dataset = await CVPApi.createDataset({
        payload: {
          // solution_id: solution.id,
          data_path: uploadRequest.blob_path,
          container_name: uploadRequest.container_name,
          task: data?.task?.value,
          mill: data?.mill?.value,
          description: data?.description,
          // is_video: hasVideo,
        }
      })
      await CVPApi.updateDataset(dataset.id, {
        payload: {
          status: DatasetStatus.UPLOADING
        }
      })

      // 2. uploading dataset to blob
      dispatch({
        type: Action.UPDATE_UPLOAD_PROGRESS,
        uploadingFiles: 0,
        totalFiles: processedFiles.reduce((t: number, curr: FileList) => (t += curr.length), 0),
      })
      await uploadService.uploadParallel(
        processedFiles,
        {
          toDir: 'images',
        },
        ({ success, error }) => {
          if (error) {
            if (
              error.error?.details?.Code &&
              error?.details?.Code === 'UnauthorizedBlobOverwrite'
            ) {
              // auto skip existed file
              dispatch({ type: Action.MARK_UPLOAD_FILE_DONE })
              // return `Error uploading '${blockName}' (File existed)`
            } else if (error.error?.details?.Code) {
              _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
            } else {
              // unknown or un-catch error
              _errorMessages = [..._errorMessages, `Error uploading '${error.blockName}'`]
            }
          }
          if (success) {
            dispatch({ type: Action.MARK_UPLOAD_FILE_DONE })
          }
        },
      )
      dispatch({
        type: Action.UPDATE_STATUS,
        step: 1,
        status: StepStatus.DONE,
      })
    } catch (error) {
      dispatch({
        type: Action.UPDATE_STATUS,
        step: 1,
        status: StepStatus.ERROR,
      })
      dispatch({
        type: Action.UPDATE_ERROR_MESSAGES,
        messages: ['Failed to start uploading data set.'],
      })
      dispatch({ type: Action.UPDATE_SUBMITTING_STATUS, status: false })
      if (dataset) {
        await CVPApi.updateDataset(dataset.id, {
          payload: {
            status: DatasetStatus.DISCARDED
          }
        })
      }
    }

    // 3. mark dataset as waiting for annotation
    // 4. create CVAT task with new dataset path (using server_files)
    // function setCookie(c_name: any, value: any, exdays: number) {
    //   const exdate = new Date();
    //   exdate.setDate(exdate.getDate() + exdays);
    //   const c_value = escape(value) + ((exdays == null) ? "" : "; path=/; expires=" + exdate.toUTCString());
    //   document.cookie = c_name + "=" + c_value;
    // }
    dispatch({
      type: Action.UPDATE_STATUS,
      step: 2,
      status: StepStatus.LOADING,
    })
    if (dataset) {
      try {
        const resp = await CVPApi.cvatAnnotate(dataset.id);
        // setCookie('sessionid', resp['sessionid'], 365);
        await CVPApi.updateDataset(dataset.id, {
          payload: {
            status: DatasetStatus.WAITING_FOR_ANNOTATIONS
          }
        })
        dispatch({
          type: Action.UPDATE_STATUS,
          step: 2,
          status: StepStatus.DONE,
        })
        window.open(resp['signed_url'], '_blank');
        window.location.reload();
      } catch (error) {
        await CVPApi.updateDataset(dataset.id, {
          payload: {
            status: DatasetStatus.DISCARDED
          }
        })
        dispatch({
          type: Action.UPDATE_STATUS,
          step: 2,
          status: StepStatus.ERROR,
        })
        dispatch({
          type: Action.UPDATE_ERROR_MESSAGES,
          messages: ['Failed to create CVAT link.'],
        })
        dispatch({ type: Action.UPDATE_SUBMITTING_STATUS, status: false })
      }
    }
    dispatch({ type: Action.UPDATE_SUBMITTING_STATUS, status: false })
  }

  return (
    <MainTabbedPage activeItem="Annotate Data">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <VerticalMenuItem
          href="#"
          isActive
          label={<h6 className="se-mb-0">Annotate with CVAT</h6>}
        />
        <p className="se-mt-md">
          Computer Vision Annotation Tool supporting the user in the data annotation process.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          {exitPrompt}
          <div>

            <div className="row se-mt-2xl">
              <div className="col-md-3 col-sm-5 col-xs-8">
                <Title>
                  <Popover
                    component={
                      <h6>Description:</h6>
                    }
                    position="bottom"
                  >
                    Explanation what the dataset is about
                  </Popover>
                </Title>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  name="description"
                  placeholder="Dataset description"
                  control={control}
                  // helpText="Explanation what the dataset is about"
                />
                {errors.description && (
                  <small className="se-form-help se-form-help--invalid">
                    {errors.description?.type === 'required' && 'This field is required'}
                  </small>
                )}
              </div>
            </div>

            <div className="row se-mt-2xl">
              <div className="col-md-12">
                <Title>
                  <Popover
                    component={
                      <h6>Task:</h6>
                    }
                    position="bottom"
                  >
                    Computer Vision task the data should be annotated for
                  </Popover>
                </Title>
              </div>
              <div className="col-md-3 col-sm-5 col-xs-8">
                <Controller
                  as={
                    <Dropdown
                      // label='Select model'
                      options={[
                        // { label: 'Classification', value: 'classification' },
                        { label: 'Object detection', value: 'object_detection' },
                        { label: 'Instance segmentation', value: 'instance_segmentation' },
                        { label: 'Semantic segmentation', value: 'semantic_segmentation' },
                      ]}
                    />
                  }
                  defaultValue={{ label: 'Object detection', value: 'object_detection' }}
                  rules={{ required: true }}
                  name="task"
                  control={control}
                />
                {errors.task && (
                  <small className="se-form-help se-form-help--invalid">
                    {errors.task?.type === 'required' && 'This field is required'}
                  </small>
                )}
              </div>

            </div>

            <div className="row se-mt-2xl">
              <div className="col-md-12">
                <Title>
                  <Popover
                    component={
                      <h6>Select mill:</h6>
                    }
                    position="bottom"
                  >
                    Mill the data belongs to
                  </Popover>
                </Title>
              </div>
              <div className="col-md-3 col-sm-5 col-xs-8">
                <Controller
                  as={
                    <Dropdown
                      options={
                        Object.keys(MILLS).map((mKey: string) => ({
                          label: MILLS[mKey],
                          value: mKey,
                        })) as { label: string; value: string }[]
                      }
                    />
                  }
                  rules={{ required: true }}
                  name="mill"
                  control={control}
                />
                {errors.mill && (
                  <small className="se-form-help se-form-help--invalid">
                    {errors.mill?.type === 'required' && 'This field is required'}
                  </small>
                )}
              </div>

            </div>

            <div className="row se-mt-2xl">
              <div className="col-md-12">
                <Title>
                  <Popover
                    component={
                      <h6>Dataset to annotate:</h6>
                    }
                    position="bottom"
                  >
                    Select image or video data to be annotated
                  </Popover>
                </Title>
              </div>
              <div className="col-md-3 col-sm-5 col-xs-8">
                <div className="se-input-container">
                  <Controller
                    as={
                      <input name='classes' type='hidden' value='images' />
                    }
                    defaultValue='images'
                    // rules={{ required: true }}
                    name={`classes`}
                    control={control}
                  />
                  <Controller
                    as={
                      <FileField
                        name={`files`}

                        // ref={register({ required: true })}
                        multiple
                        accept="image/*,.avi,.mp4"
                        valid={!errors?.images}
                        helpText={
                          errors?.images && errors.images.type === 'required'
                            ? 'This field is required'
                            : ''
                        }
                      />
                    }
                    rules={{ validate: {
                      hasOnlyOneType: (files: any[]) => {
                        const hasVideo: Boolean = Array.from(files).filter(f => f.name.indexOf('.mp4') >= 0).length > 0
                        const hasImage: Boolean = Array.from(files).filter(f => f.name.indexOf('.mp4') < 0).length > 0
                        return !(hasVideo && hasImage)
                      }
                    } }}
                    name={`files`}
                    control={control}
                  />
                </div>
              </div>
            </div>
            <br />
            <Button
              onClick={handleSubmit(onSubmit)}
              isLoading={submitting}
            >
              Annotate
            </Button>
            <div className="row">
              <div className="col-md-4 se-mt-md">
                <small className={`se-form-help`}>
                  In case of big dataset, it would take a while to process and upload the processed files. There is an expected delay of 1-2 minutes for the annotation job to show up in CVAT.
                </small>
              </div>
            </div>

            {submitting && steps.length > 0 && (
              <div className='row se-mt-md'>
                <div className="col col-md-2">
                  <RoundProgressIndicator
                    height={75}
                    mode="regular"
                    step={
                      steps.slice().reverse().findIndex((s: any) => s.status === StepStatus.DONE) >= 0 ?
                      steps.length - steps.slice().reverse().findIndex((s: any) => s.status === StepStatus.DONE)
                      : 0
                    }
                    steps={steps.length}
                  />
                </div>
                <div className="col">
                  {steps.map((step: any, stepIndex: number) => (
                    <p key={stepIndex}>
                      {step.status === StepStatus.LOADING && (
                        <Spinner className="training-spinner" style={{ display: 'inline-block' }} />
                      )}
                      {step.status === StepStatus.INIT && <Icon>radio_button_unchecked</Icon>}
                      {step.status === StepStatus.ERROR && <Icon>radio_button_unchecked</Icon>}
                      {step.status === StepStatus.DONE && <Icon>check</Icon>} {step.message}{' '}
                      {stepIndex === 0 &&
                        uploadingFiles &&
                        totalFiles &&
                        `:${uploadingFiles}/${totalFiles}`}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {errorMessages.length > 0 && (
              <React.Fragment>
                <div className="se-pb-md"></div>
                <Notification
                  onClose={() => dispatch({ type: Action.RESET_ERROR })}
                  type="negative"
                >
                  {errorMessages.map((m: string, index: number) => (
                    <p key={index}>{m}</p>
                  ))}
                </Notification>
              </React.Fragment>
            )}
          </div>
        </form>
      </div>

      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <div className="row">
          <div className="col-md-10 col-sm-12 col-xs-12">

            <VerticalMenuItem
              href="#"
              isActive
              label={<h6 className="se-mb-0">Dataset waiting for annotations</h6>}
            />

            <Table
              headers={[
                ['Description', 250],
                ['Solution', 250],
                ['Task', 150],
                ['Mill', 100],
                ['Owner', 100],
                ['Created At', 200],
                ['', 300],
              ]}
              content={datasets
                .sort((a, b) => b.id - a.id)
                .map((dataset) => {
                  return {
                    content: [
                      dataset.description,
                      dataset?.solution?.name,
                      dataset.task,
                      dataset.mill,
                      (dataset?.user?.email || '').split('@')[0],
                      // moment(dataset.created_at).fromNow(),
                      moment.utc(dataset.created_at).format("L LT"),
                      [
                        <>
                          <Button
                            type="primary"
                            onClick={async () => {
                              const resp = await CVPApi.cvatAnnotate(dataset.id);
                              window.open(resp['signed_url'], '_blank');
                            }}
                            key="1"
                          >
                            Annotate
                          </Button>
                          {
                            (CVPApi.email === dataset?.user?.email || CVPApi.isPlatformAdmin) && (
                              <Button
                                type="primary"
                                onClick={async () => {
                                  const resp = await CVPApi.updateDataset(dataset.id, {
                                    payload: {
                                      is_public: dataset.is_public ? false : true,
                                    }
                                  })
                                  fetchDatasets(DatasetStatus.WAITING_FOR_ANNOTATIONS, (ds: any) => setDatasets(ds))
                                }}
                                key="2"
                              >
                                {dataset.is_public ? 'Mark as Private' : 'Mark as Public'}
                              </Button>
                            )
                          }
                          {
                            (CVPApi.email === dataset?.user?.email || CVPApi.isPlatformAdmin) && (
                              <ConfirmationButton
                                onClick={async () => {
                                  const resp = await CVPApi.updateDataset(dataset.id, {
                                    payload: {
                                      status: "discarded",
                                    }
                                  })
                                  fetchDatasets(DatasetStatus.WAITING_FOR_ANNOTATIONS, (ds: any) => setDatasets(ds))
                                }}
                                size="sm"
                                actionText="Delete"
                                confirmationContent={
                                  <p>
                                    Click 'delete' to mark the dataset as deleted.
                                  </p>
                                }
                                type="negative"
                              >
                                Delete
                              </ConfirmationButton>
                            )
                          }
                        </>
                      ],
                    ],
                  }
                })}
            />
          </div>
        </div>
      </div>

      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <div className="row">
          <div className="col-md-10 col-sm-12 col-xs-12">

            <VerticalMenuItem
              href="#"
              isActive
              label={<h6 className="se-mb-0">Finished datasets</h6>}
            />

            <Table
              headers={[
                ['Description', 250],
                ['Solution', 250],
                ['Task', 150],
                ['Mill', 100],
                ['Owner', 100],
                ['Created At', 200],
                ['', 300],
              ]}
              content={finishedDatasets
                .filter(ds => ds.is_cvat)
                .sort((a, b) => b.id - a.id)
                .map((dataset) => {
                  return {
                    content: [
                      dataset.description,
                      dataset?.solution?.name,
                      dataset.task,
                      dataset.mill,
                      (dataset?.user?.email || '').split('@')[0],
                      // moment(dataset.created_at).fromNow(),
                      moment.utc(dataset.created_at).format("L LT"),
                      [
                        <>
                          <Button
                            type="primary"
                            onClick={async () => {
                              const resp = await CVPApi.cvatAnnotate(dataset.id);
                              window.open(resp['signed_url'], '_blank');
                            }}
                            key="1"
                          >
                            View
                          </Button>
                          {
                            (CVPApi.email === dataset?.user?.email || CVPApi.isPlatformAdmin) && (
                              <Button
                                type="primary"
                                onClick={async () => {
                                  const resp = await CVPApi.updateDataset(dataset.id, {
                                    payload: {
                                      is_public: dataset.is_public ? false : true,
                                    }
                                  })
                                  fetchDatasets(DatasetStatus.UPLOADED, (ds: any) => setFinishedDatasets(ds))
                                }}
                                key="2"
                              >
                                {dataset.is_public ? 'Mark as Private' : 'Mark as Public'}
                              </Button>
                            )
                          }
                          {
                            (CVPApi.email === dataset?.user?.email || CVPApi.isPlatformAdmin) && (
                              <ConfirmationButton
                                onClick={async () => {
                                  const resp = await CVPApi.updateDataset(dataset.id, {
                                    payload: {
                                      status: "discarded",
                                    }
                                  })
                                  fetchDatasets(DatasetStatus.UPLOADED, (ds: any) => setFinishedDatasets(ds))
                                }}
                                size="sm"
                                actionText="Delete"
                                confirmationContent={
                                  <p>
                                    Click 'delete' to mark the dataset as deleted.
                                  </p>
                                }
                                type="negative"
                              >
                                Delete
                              </ConfirmationButton>
                            )
                          }
                        </>
                      ],
                    ],
                  }
                })}
            />
          </div>
        </div>
      </div>
    </MainTabbedPage>
  )
}
