import React, { useEffect, useState } from 'react'
import {
  VerticalMenuItem,
  Card,
  Toggle,
  Tag,
  Input,
  Dropdown,
  Button,
} from '@storaensods/seeds-react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'

import { MainTabbedPage } from '../../components'

import CVPApi from '../../services/cvp'
import { Solution, SolutionStatus, SolutionType } from '../../types'

import styles from './Library.module.scss'
import { MILLS } from '../../config'

interface SearchData {
  search: string
  status: any
  type: any
  mill: any
}

export default () => {
  const history = useHistory()
  const { control, handleSubmit, formState, getValues, watch } = useForm<SearchData>()

  // cdm
  useEffect(() => {
    // CVPApi.fetchSolutions()
    //   .then((resp: Solution[]) => {
    //     setSolutions(resp)
    //   })
    //   .catch((error: Error) => {
    //     // console.error(error)
    //   })
    //   .finally(() => {})
  }, [])

  const onSubmit = async (data: SearchData) => {
    console.log("data", data)
  }

  return (
    <MainTabbedPage activeItem="CVAT">
      <div className="se-pl-md se-pr-md se-pt-lg se-pb-lg">
        <VerticalMenuItem
          href="#"
          isActive
          label={<h6 className="se-mb-0">Dataset details</h6>}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
        </form>
      </div>
    </MainTabbedPage>
  )
}
