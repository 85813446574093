import * as Utils from '../lib/utils'
import Storage from '../lib/storage'

// import AzureAd from './aad'

export class BaseApi {
  protected readonly endpoint: string

  constructor({ endpoint }: { endpoint: string }) {
    this.endpoint = endpoint
  }

  protected async _requestAsync(
    url: string,
    requestType = 'GET',
    requestOptions: {
      params?: object
      payload?: object
      formData?: boolean
    } = {},
    options: {
      retry: number
    } = {
      retry: 1,
    },
  ): Promise<any> {
    const { params, payload, formData } = requestOptions
    const query = Utils.parseParams(params || {})

    const token = this.fetchToken()
    // const ADToken = AzureAd.token;
    const headers = new Headers({
      // 'Accept': 'application/json,blob',
      ...(formData
        ? {}
        : {
            'content-type': 'application/json',
          }),
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
      // 'X-ZUMO-AUTH': ADToken?.authenticationToken || '',
    })
    const _toFormData = function (payload: { [key: string]: any }) {
      let formData = new FormData()
      Object.keys(payload).forEach((k: string) => {
        if (Array.isArray(payload[k])) {
          // is array type
          Array.from(payload[k]).forEach((item: any) => {
            formData.append(k, item)
          })
        } else {
          formData.append(k, payload[k])
        }
      })
      return formData
    }

    const bodyToSend = formData && payload ? _toFormData(payload) : JSON.stringify(payload)

    const response = await fetch(`${url}?${query}`, {
      body: bodyToSend,
      credentials: 'same-origin', // pass session id for authentication (for now)
      headers,
      method: requestType,
      mode: 'cors',
    })

    // retry refreshing token once if old token is invalid
    if ([401, 403].indexOf(response.status) >= 0 && options.retry > 0) {
      try {
        await this.refreshToken()
      } catch (error) {
        // not able to refresh new token, need to re-login
        this.removeToken()
        window.location.href = '/'
      }
      return await this._requestAsync(url, requestType, requestOptions, { retry: 0 })
    }

    // 401 redirect back to home page to relogin
    if (response.status === 401) {
      this.removeToken()
      window.location.href = '/'
    } else if (response.status === 403) {
      // showing error
      throw Error('Access denied.')
    }

    if (!response.ok) {
      const errorResponse = await response.json()
      const message = 'Request failed'
      if (errorResponse && errorResponse.error) {
        throw Error(errorResponse.error)
      } else {
        throw Error(message || response.statusText)
      }
    }
    const contentType = response.headers.get('Content-Type')
    const contentDiposition: string = response.headers.get('Content-Disposition') || ""
    const contentMatches: string[]|null = contentDiposition.match(/.*filename=(.+).*/) || []
    const filename: string|undefined = contentMatches.length > 0 ? contentMatches[1] : undefined;
    if (contentType && ["application/octet-stream", "application/zip"].indexOf(contentType) >= 0) {
      console.log('generating blob', filename)
      const blob = await response.blob()
      console.log('generated blob')
      return {
        blob,
        filename,
      }
    }

    const responseJson = await response.json()
    if (responseJson && !responseJson.error) {
      return responseJson
    } else {
      const message = 'Request failed'
      if (responseJson && responseJson.error) {
        if (typeof responseJson.error === 'object') {
          throw Error(responseJson.error)
        } else {
          throw Error(
            Object.keys(responseJson).reduce((concatedMessage, error) => {
              return `${concatedMessage}${error}: ${responseJson[error]}. `
            }, ''),
          )
        }
      } else {
        throw Error(message || response.statusText)
      }
    }
  }

  public refreshToken = () => {}

  public fetchToken = (): string => {
    return Storage.get('token')
  }

  protected setToken = (token: string, expires_in: number = 86400) => {
    return Storage.set('token', token, expires_in)
  }

  protected removeToken = () => {
    return Storage.remove('token')
  }
}
